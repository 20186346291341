import React from 'react';
import SocialIcons from '../SocialIcons';

const Header = () => {
  return (
    <div className="h-[68px] md:h-[56px] bg-white border-b-2 border-[#F0F3F7] flex justify-between items-center px-5 md:px-8 relative">
      <img
        alt="distributed-logo"
        className="w-[133px] h-6"
        src={require('../../../../assets/images/logo1.png')}
      />
    </div>
  );
};

export default Header;
