import React from 'react';

const Fade = ({ position = 'top' }: { position: 'top' | 'bottom' }) => {
  return (
    <span
      className={`w-full sticky min-h-[30px] from-white to-[transparent]  z-10 ${
        position === 'top'
          ? 'bg-gradient-to-b  top-0'
          : position === 'bottom'
          ? 'bg-gradient-to-t  bottom-0'
          : ''
      }`}
    />
  );
};

export default Fade;
