import { Select as MUISelect, SelectProps } from '@mui/material';
import classNames from 'classnames';
import { styled } from '@mui/material/styles';
import React from 'react';
import { SelectOptionProp } from './type';
import styles from './Select.styles';
import { theme } from 'styles/theme';

import MenuItem from '@mui/material/MenuItem';

const CustomMenuItem = styled(MenuItem)`
  color: ${theme.colors.DarkBlue};
  font-family: Poppins-Bold;
  font-size: 14px;
`;

export const Select: React.FunctionComponent<
  SelectProps & {
    options: SelectOptionProp[];
  }
> = ({ options, className, ...rest }) => {
  const selectStyles = classNames(className, styles.base);
  return (
    <MUISelect className={selectStyles} {...rest}>
      {options.map((option) => (
        <CustomMenuItem key={option.value} value={option.value} className="primary-blue">
          {option.label}
        </CustomMenuItem>
      ))}
    </MUISelect>
  );
};
