import React, { useLayoutEffect, useState, useRef } from 'react';
import { LongPinkIcon, PinkIcon } from 'assets';

interface FieldItemProps {
  icon?: any;
  title?: any;
  titleClasses?: string;
  containerClass?: string;
  children?: any;
}

const FieldItem = (props: FieldItemProps) => {
  const { icon, title, children, titleClasses, containerClass } = props;
  const targetRef: any = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, []);

  return (
    <div
      ref={targetRef}
      className={
        'min-w-[100% md:min-w-[380px] overflow-hidden shadow-lg shadow-[rgba(0, 11, 38, 0.07)] bg-white max-w-[774px] min-h-[179px] ] w-[100%] my-3 rounded-[8px] box-border ' +
          containerClass || ''
      }
    >
      <div className="h-[48px] bg-white w-100 flex flex-row items-center sm:px-7 border-b border-[#E6E6E6] rounded-t-[10px] px-4 md:px-6 relative">
        {icon && icon}
        {title && (
          <div
            className={
              'text-[#363D49] pl-4 font-poppins-bold text-[16px] ' +
                titleClasses || ''
            }
          >
            {title}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default FieldItem;
