import ElasticTeamTM from 'components/ElasticTeamTM';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import SocialIcons from '../SocialIcons';

const HeroContent = () => {
  return (
    <div className="flex flex-col h-full pt-8 md:pt-0 md:grow md:pb-0 md:w-1/2 md:justify-between px-5">
      <div className="text-center flex flex-col items-center my-auto mx-auto max-w-[333px] md:max-w-[372px] 2xl:max-w-[592px]">
        <p className="text-primary-blue font-extrabold text-[28px] leading-[32px] md:text-[36px] md:leading-[40px] 2xl:text-[47px] 2xl:leading-[50px]">
          Welcome to the <br /> <ElasticTeamTM tradeMarkStyle="font-normal" />
        </p>
        <p className="font-light text-primary-blue mt-3 md:hidden">
          Home of freelance careers with more benefits than permanent
          employment.
        </p>
        <div className="hidden md:block">
          <p className="mt-10 font-light text-primary-blue text-[22px] leading-[30px] 2xl:text-[35px] 2xl:leading-[46px]">
            Welcome to the world’s best freelance developer experience.
            Freelance tech careers powering enterprise businesses.
          </p>
          <p className="mt-10 text-primary-blue font-light text-[18px] leading-[28px]  2xl:text-[30px]  2xl:leading-[36px]">
            Ready to get started?
            <br />
            Sign in or apply now!
          </p>
        </div>
        <div className="mt-5 w-full justify-center items-center flex flex-col md:flex-row md:gap-x-8 lg:gap-x-12">
          <div className="flex flex-col items-center mt-10 w-full max-w-[312px]">
            <p className="text-primary-blue text-[17px] leading-[22px] md:text-[20px] md:leading-[22px] 2xl:text-[25px] 2xl:leading-[27px] ">
              <span>Join the{' '}</span>
              <ElasticTeamTM className="" tradeMarkStyle="text-[6px]" />
            </p>
            <Link to={'/apply'} className="w-full mt-4">
              <Button text="APPLY" variant="primary" className="w-full " />
            </Link>
          </div>
          {/* <div className="flex flex-col items-center mt-10 w-full max-w-[312px]">
            <p className="text-primary-blue text-[14px] leading-[22px] md:text-[10px] md:leading-[22px] 2xl:text-[18px] 2xl:leading-[27px]">
              <ElasticTeamTM className="" tradeMarkStyle="text-[6px]" /> members
            </p>
            <Link to={'/login'} className="w-full">
              <Button text="SIGN IN" variant="secondary" className="w-full " />
            </Link>
          </div> */}
        </div>
      </div>
      <div className="md:hidden fixed bg-white bottom-0 left-0 right-0 pt-2">
        <SocialIcons className="" />
        <div className="">
          <p className="text-center py-2 text-[12px] leading-[22px] text-blue-fade">
            Copyright &copy; {new Date().getFullYear()} Distributed Inc. All
            Rights Reserved.
          </p>
        </div>
      </div>
      <div className="hidden md:block">
        <p className="text-center py-2 text-[13px] leading-[19.5px] text-blue-fade">
          Copyright &copy; {new Date().getFullYear()} Distributed Inc. All
          Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default HeroContent;
