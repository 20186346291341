import React from 'react';
import {
  facebookImage,
  linkedInImage,
  shareImage,
  twitterImage,
} from '../consts';
import { SocialIconsProps } from './types';

const SocialIcons: React.FC<SocialIconsProps> = (props) => {
  const { className, ...rest } = props;
  return (
    <div
      className={`gap-x-10 px-5 flex w-full max-w-[333px] justify-between mx-auto md:mx-0 md:justify-center  md:w-auto md:max-w-auto md:gap-x-7 ${className}`}
      {...rest}
    >
      <a href="https://twitter.com/_distributed_">
        <img src={twitterImage} className="w-9 h-9" alt="Distributed Twitter" />
      </a>
      <a href="https://www.linkedin.com/company/dstbtd">
        <img
          src={linkedInImage}
          className="w-9 h-9"
          alt="Distributed Linkedin"
        />
      </a>
      <a href="https://www.facebook.com/DSTBTD">
        <img
          src={facebookImage}
          className="w-9 h-9"
          alt="Distributed Facebook"
        />
      </a>
    </div>
  );
};

export default SocialIcons;
