import React from 'react';
import { LandingHeroImage } from '../consts';
import ImageComponent from 'components/ImageComponent';

const HeroImage = () => {
  return (
    <ImageComponent
      className="md:h-full md:w-1/2 object-cover"
      src={LandingHeroImage}
      alt=""
    />
  );
};

export default HeroImage;
