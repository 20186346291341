import { useEffect, useMemo, useState } from 'react';
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import '../index.css';
import { TRoute } from './types';
import { routes } from './routes';
import { Auth, Hub } from 'aws-amplify';
import LoadingBar from 'components/LoadingBar';
import PageNotFound from 'pages/PageNotFound';
import { getProfile } from 'api/services/user';
import CookieConsent from 'pages/cookie-consent';
import { useAppDispatch } from '../store';
import { setAuthLoading } from 'redux/user/userReducer';

import {Analytics, setLogin} from "../Context/analytics";
import {Utm} from "../Context/utm";

const CustomRoute = ({
  route,
  children,
}: {
  route: TRoute;
  children: JSX.Element;
}) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  useEffect(() => {
    checkUser();
  }, [navigate]);

  const getUserProfile = async () => {
    try {
      return await dispatch(getProfile()).unwrap();
    } catch {
      return null;
    }
  }

  const checkUser = async (analytics?: boolean) => {
    dispatch(setAuthLoading({ loading: true }));
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        const profile = await getUserProfile();
        if (analytics) {
          const payload = user.signInUserSession.idToken.payload;
          const { sub } = payload;
          const groups = payload["cognito:groups"];
          setLogin(sub, groups);
        }
        if (!route.protected) {
          if (!!profile) {
            if (profile.personalEmail) {
              return navigate('/dashboard');
            }
          } else {
            navigate('/new-applicant');
          }
        }
      } else {
        if (route.protected) {
          return navigate('/signup');
        }
      }
    } catch (error) {
      if (route.protected) {
        return navigate('/signup');
      }
    } finally {
      setLoading(false);
      dispatch(setAuthLoading({ loading: false }));
    }
  };

  useEffect( () => {
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      switch (event) {
        case 'autoSignIn':
          checkUser(true);
          break;
        case 'signIn':
          checkUser(true);
          break;
        case 'signOut':
          navigate('/');
          break;
        case 'autoSignIn_failure':
          break;
      }
    });
    return unsubscribe;
  });

  if (loading) return <LoadingBar />;

  return route.element;
};
const ReactRouter = () => {
  return (
    <BrowserRouter>
      <Analytics />
      <CookieConsent />
      <Utm />
      <Routes>
        {routes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<CustomRoute route={route}>{route.element}</CustomRoute>}
            />
          );
        })}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default ReactRouter;
