import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import {
  Autocomplete,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Button } from 'components';
import { EnterIcon, SearchIcon } from 'assets';
import { theme } from 'styles/theme';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  background: 'white',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  marginLeft: 0,
  marginRight: 0,
  background: 0,
  borderRadius: 0,
}));

const searchIconStyle = {
  right: '0px',
  width: '37px',
  top: 0,
  height: '37px',
  borderLeft: '1px solid #B3B3B3',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const CustomAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-input {
    font-size: 14px;
    font-family: Poppins-Medium;
    color: ${theme.colors.DarkBlue}; 
  }
  & .MuiAutocomplete-input::placeholder {
    color: black; 
  }
`;

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#B3B3B3',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B3B3B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#B3B3B3',
    },
    '&:hover fieldset': {
      borderColor: '#B3B3B3',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B3B3B3',
    },
  },
});

const EnglishTestStatus = {
  NotStarted: 'NotStarted',
  InProgress: 'InProgress',
  ScorePending: 'ScorePending',
  Completed: 'Completed',
  NeedsReview: 'NeedsReview',
}
const TechnicalTestStatus = {
  InProgress: 'In Progress',
  Completed: 'Completed',
}

export default function AccordionItems(props) {
  const {
    item,
    profile,
    isLoading,
    englishResult,
    hasPassed,
    technicalResult,
    openDiscord,
    onSubmit,
    assessments,
    technicalAssessments,
    onOpenAgreement,
    onAppeal,
  } = props;
  const [checkItems, setCheckItems] = React.useState<any[]>([]);
  const [selectedItem, setSelectedItem] = React.useState(null)

  React.useEffect(() => {
    if (item.id === 1 && technicalAssessments) {
      const newItems = technicalAssessments.map(i => {
        return {
          label: i.name,
          link: i.link,
        }
      })
      setCheckItems(newItems)
    }
  }, [technicalAssessments]);

  const getTitle = () => {
    if (item.id === 2) {
      if (englishResult?.hasPassed || hasPassed) {
        return item['passTitle'];
      } else {
        if (englishResult?.status === EnglishTestStatus.Completed) {
          return item['failedTitle']
        }
        if (englishResult?.status === EnglishTestStatus.ScorePending || englishResult?.status === EnglishTestStatus?.InProgress || englishResult?.status === EnglishTestStatus.NotStarted) {
          return item['pendingTitle']
        }
      }
    } else if (item.id === 1) {
      if (technicalResult?.hasPassed || hasPassed) {
        return item['passTitle'];
      } else {
        if (technicalResult?.status === TechnicalTestStatus.Completed) {
          return item['failedTitle']
        }
        if (technicalResult?.status === TechnicalTestStatus.InProgress) {
          return item['pendingTitle']
        }
      }
    }
    return item['title'];
  }
  const getDescription = () => {
    if (item.id === 2) {
      if (englishResult?.hasPassed || hasPassed) {
        return item['passDescription'];
      } else {
        if (englishResult?.status === EnglishTestStatus.Completed) {
          return item['failedDescription'].replace('<Name>', `${profile?.firstName} ${profile?.lastName}`)
        }
        if (englishResult?.status === EnglishTestStatus.ScorePending || englishResult?.status === EnglishTestStatus.InProgress || englishResult?.status === EnglishTestStatus.NotStarted) {
          return item['pendingDescription']
        }
      }
    } else if (item.id === 1) {
      if (technicalResult?.hasPassed || hasPassed) {
        return item['passDescription'];
      } else {
        if (technicalResult?.status === TechnicalTestStatus.Completed) {
          return item['failedDescription'].replace('<Name>', `${profile?.firstName} ${profile?.lastName}`)
        }
        if (technicalResult?.status === TechnicalTestStatus.InProgress) {
          return item['pendingDescription']
        }
      }
    }
    return item['description']
  }

  const isAppeal = item.id === 1 ? (technicalResult?.status === TechnicalTestStatus.Completed || technicalResult?.status === TechnicalTestStatus.InProgress) && !hasPassed : (englishResult?.status === EnglishTestStatus.Completed || englishResult?.status === EnglishTestStatus.ScorePending || englishResult?.status === EnglishTestStatus.InProgress || englishResult?.status === EnglishTestStatus.NotStarted) && !hasPassed;

  const isNotStartedTest = item.id === 2 ? ((!englishResult) && !hasPassed) :
    (((technicalResult?.status !== TechnicalTestStatus.Completed && technicalResult?.status !== TechnicalTestStatus.InProgress) || !technicalResult)) && !hasPassed;
  return (
    <Accordion expanded={props.expanded === item.title} onChange={props.handleChange(item.title)}>
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <div className="flex justify-between w-[100%] items-center">
          <p className="font-poppins-bold text-left text-[14px] text-[#363D49]">
            {getTitle()}
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="bg-[#F7F7F7] rounded-lg space-y-4 p-4 md:p-6 mx-2 md:mx-8 md:ml-8">
          <p className="font-poppins-regular text-[14px] text-[#555E6D] text-left whitespace-pre-line">
            {getDescription()}
          </p>
          {item.id === 1 && isNotStartedTest ? (
            <div className='pt-4 pl-0'>
                  <div className='text-left'>
                    <label className='w-full font-poppins-bold text-[12px] text-label'>
                      SEARCH & SELECT YOUR TECHNICAL SKILL
                    </label>
                  </div>
                  <div className="relative max-w-[336px]">
                    <CustomAutocomplete
                      disablePortal={false}
                      options={checkItems}
                      onChange={(_, values: any) =>
                        setSelectedItem(values)
                      }
                      value={selectedItem}
                      size="small"
                      sx={{ height: 48 }}
                      popupIcon={<SearchIcon />}
                      forcePopupIcon={false}
                      clearIcon={null}
                      renderInput={(params) => <CustomTextField {...params} placeholder="Find your technical skill" />}
                      renderOption={(props, option: any) => (
                        <Box component="li" {...props} key={option.link} sx={{ fontSize: '14px', fontFamily: 'Poppins-Bold' }}>
                          {option.label}
                        </Box>
                      )}
                    />
                    <div className='absolute' style={searchIconStyle}>
                      <SearchIcon />
                    </div>
                  </div>
            </div>
          ) : null}
           {(item.id === 1 || item.id === 2) && isAppeal ? (
            <div className="flex justify-start md:space-x-4 space-y-4 md:space-y-0 flex-col md:flex-row">
              <Button
                text="Appeal"
                className="sm:w-40 min-h-[32px] font-poppins-regular text-[12px]"
                type="submit"
                onClick={() => onAppeal && onAppeal(item.id)}
              />
            </div>
          ) : null}
          {item.id === 0 ? (
            <div className="flex justify-start md:space-x-4 space-y-4 md:space-y-0 flex-col md:flex-row">
              <Button
                text="JOIN DISCORD"
                className="sm:w-40 min-h-[32px] font-poppins-regular text-[12px]"
                type="submit"
                disabled={item.id === 1 ? isLoading || !selectedItem : isLoading}
                onClick={openDiscord}
              />
            </div>
          ) : (
            <>
              {isNotStartedTest && item.id < 3 ? (
                <div className="flex justify-start md:space-x-4 space-y-4 md:space-y-0 flex-col md:flex-row">
                  <Button
                    text="TAKE TEST"
                    className="sm:w-40 min-h-[32px] font-poppins-regular text-[12px]"
                    type="submit"
                    id={`${item.id === 2 ? 'english-test-btn' : 'technical-test-btn'}`}
                    disabled={item.id === 1 ? isLoading || !selectedItem : isLoading}
                    onClick={() => onSubmit(item.id === 2 ? assessments[0] : selectedItem)}
                  />
                </div>
              ) : null}
            </>
          )}
          {item.id === 3 ? (
            <div className="flex justify-start md:space-x-4 space-y-4 md:space-y-0 flex-col md:flex-row">
              <Button
                text="View & Accept"
                className="sm:w-40 min-h-[32px] font-poppins-regular text-[12px]"
                type="submit"
                disabled={false}
                onClick={onOpenAgreement}
              />
            </div>
          ) : null}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
