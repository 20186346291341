import { createSlice } from '@reduxjs/toolkit';
import { AssessmentInitialState } from 'types';
import {
    getAssessments,
    getAssessmentResult,
    inviteAssessment,
    getTechnicalAssessmentResult,
    getTechnicalAssessments,
    inviteTechnicalAssessment,
} from 'api/services/assessment';

const initialState: AssessmentInitialState = {
  isGetAssessmentResultLoading: false,
  isTechnicalAssessmentResultLoading: false,
  isInviteAssessmentLoading: false,
  isAssessmentLoading: false,
  isTechnicalAssessmentLoading: false,
  error: '',
  testResult: null,
  testTechnicalResult: null,
  inviteAssessment: null,
  inviteTechnicAssessment: null,
  assessments: undefined,
  technicalAssessments: undefined,
};

export const assessmentReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.isAssessmentLoading = false;
      state.error = '';
      state.inviteAssessment = null;
      state.inviteTechnicAssessment = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssessments.pending, (state, action) => {
      state.isAssessmentLoading = true;
    });
    builder.addCase(getAssessments.fulfilled, (state, action) => {
      state.isAssessmentLoading = false;
      state.assessments = action.payload;
      state.error = '';
    });
    builder.addCase(getAssessments.rejected, (state, action: any) => {
      state.isAssessmentLoading = false;
      state.error = action?.error?.message;
    });
    builder.addCase(getTechnicalAssessments.pending, (state, action) => {
      state.isTechnicalAssessmentLoading = true;
    });
    builder.addCase(getTechnicalAssessments.fulfilled, (state, action) => {
      state.isTechnicalAssessmentLoading = false;
      state.technicalAssessments = action.payload;
      state.error = '';
    });
    builder.addCase(getTechnicalAssessments.rejected, (state, action: any) => {
      state.isTechnicalAssessmentLoading = false;
      state.error = action?.error?.message;
    });
    builder.addCase(getAssessmentResult.pending, (state, action) => {
      state.isGetAssessmentResultLoading = true;
    });
    builder.addCase(getAssessmentResult.fulfilled, (state, action) => {
      state.isGetAssessmentResultLoading = false;
      state.testResult = action.payload;
      state.error = '';
    });
    builder.addCase(getAssessmentResult.rejected, (state, action: any) => {
      state.isGetAssessmentResultLoading = false;
      state.error = action?.error?.message;
    });
    builder.addCase(getTechnicalAssessmentResult.pending, (state, action) => {
      state.isTechnicalAssessmentResultLoading = true;
    });
    builder.addCase(getTechnicalAssessmentResult.fulfilled, (state, action) => {
      state.isTechnicalAssessmentResultLoading = false;
      state.testTechnicalResult = action.payload;
      state.error = '';
    });
    builder.addCase(getTechnicalAssessmentResult.rejected, (state, action: any) => {
      state.isTechnicalAssessmentResultLoading = false;
      state.error = action?.error?.message;
    });
    builder.addCase(inviteAssessment.pending, (state, action) => {
      state.isInviteAssessmentLoading = true;
    });
    builder.addCase(inviteAssessment.fulfilled, (state, action) => {
      state.isInviteAssessmentLoading = false;
      state.inviteAssessment = action.payload;
      state.error = '';
    });
    builder.addCase(inviteAssessment.rejected, (state, action: any) => {
      state.isInviteAssessmentLoading = false;
      state.error = action?.error?.message;
    });
    builder.addCase(inviteTechnicalAssessment.pending, (state, action) => {
      state.isInviteAssessmentLoading = true;
    });
    builder.addCase(inviteTechnicalAssessment.fulfilled, (state, action) => {
      state.isInviteAssessmentLoading = false;
      state.inviteTechnicAssessment = action.payload;
      state.error = '';
    });
    builder.addCase(inviteTechnicalAssessment.rejected, (state, action: any) => {
      state.isInviteAssessmentLoading = false;
      state.error = action?.error?.message;
    });
  },
});

export const { resetStatus } = assessmentReducer.actions;

export default assessmentReducer.reducer;
