import { LogoIcon } from 'assets';
import { ElasticEngineerTM } from 'components/ElasticEngineerTM';
import { Header } from 'components/Header';
import React from 'react';
import {
  BodyContainer,
  CardContainer,
  LinkText,
  LogoImg,
  Text404,
  Wrapper,
} from './styles';

const PageNotFound = () => (
  <React.Fragment>
    <Header />
    <Wrapper>
      <CardContainer>
        <LogoImg alt="distributed-logo" src={LogoIcon} />
        <Text404>404</Text404>
        <BodyContainer>
          <p>
            Sorry the <ElasticEngineerTM /> App is not responding, please
            contact us to report your problem:
          </p>
          <LinkText href="mailto://contact@distributed.com">
            contact@distributed.com
          </LinkText>
          <p>Or, return to the Distributed Homepage:</p>
          <LinkText href="https://www.distributed.com">
            https://www.distributed.com/
          </LinkText>
        </BodyContainer>
      </CardContainer>
    </Wrapper>
  </React.Fragment>
);

export default PageNotFound;
