import DesignIcon from 'assets/icons/DesignIcon';
import EngineeringIcon from 'assets/icons/EngineeringIcon';
import OtherRoleIcon from 'assets/icons/OtherRoleIcon';
import ProductIcon from 'assets/icons/ProductIcon';

export const fieldOfWorkOptions = [
  {
    value: 0,
    title: 'Product',
    icon: <ProductIcon />,
  },
  {
    value: 1,
    title: 'Engineering',
    icon: <EngineeringIcon />,
  },
  {
    value: 2,
    title: 'Design',
    icon: <DesignIcon />,
  },
  {
    value: 3,
    title: 'Other',
    icon: <OtherRoleIcon />,
  },
];
