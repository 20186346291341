import React from 'react';

function GreyCloseIcon() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 5.93334L1.73334 9.2C1.61111 9.32222 1.45556 9.38334 1.26667 9.38334C1.07778 9.38334 0.922224 9.32222 0.800002 9.2C0.67778 9.07778 0.616669 8.92222 0.616669 8.73334C0.616669 8.54445 0.67778 8.38889 0.800002 8.26667L4.06667 5L0.800002 1.73334C0.67778 1.61111 0.616669 1.45556 0.616669 1.26667C0.616669 1.07778 0.67778 0.922224 0.800002 0.800002C0.922224 0.67778 1.07778 0.616669 1.26667 0.616669C1.45556 0.616669 1.61111 0.67778 1.73334 0.800002L5 4.06667L8.26667 0.800002C8.38889 0.67778 8.54445 0.616669 8.73334 0.616669C8.92222 0.616669 9.07778 0.67778 9.2 0.800002C9.32222 0.922224 9.38334 1.07778 9.38334 1.26667C9.38334 1.45556 9.32222 1.61111 9.2 1.73334L5.93334 5L9.2 8.26667C9.32222 8.38889 9.38334 8.54445 9.38334 8.73334C9.38334 8.92222 9.32222 9.07778 9.2 9.2C9.07778 9.32222 8.92222 9.38334 8.73334 9.38334C8.54445 9.38334 8.38889 9.32222 8.26667 9.2L5 5.93334Z" fill="#707A89" />
    </svg>
  );
}

export default GreyCloseIcon;
