import { Slider as MUISlider, SliderProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { styles } from './Slider.styles';

const CustomSlider = styled(MUISlider)(() => styles);

const Slider: React.FC<SliderProps> = (props) => {
  return <CustomSlider {...props} />;
};

export default Slider;
