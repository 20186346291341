import { ElasticText } from 'components/ElasticText';

const TermsConditions = (props) => {
  return (
    <div className="px-4 child:my-4 child:text-primary-blue">
      <p className="text-[15px] text-center"><span className="text-[15px]"><span><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[20px]"><span style={{ fontFamily: 'Poppins-Bold' }}><span className="text-primary-blue">Application End User License Agreement</span></span></span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>PLEASE READ THIS AGREEMENT CAREFULLY. YOU WILL BE DEEMED TO ACCEPT THIS AGREEMENT IF YOU: 1) DOWNLOAD, INSTALL OR OTHERWISE USE THE APP; OR 2) CLICK THE &quot;I ACCEPT&quot; OR SIMILAR BUTTON ASSOCIATED WITH THIS AGREEMENT. IF YOU DO NOT AGREE TO THIS AGREEMENT, DO NOT DOWNLOAD, INSTALL OR USE THE APP AND DELETE IT FROM YOUR DEVICE.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>Distributed is formally registered in the UK as DSTRBTD Ltd, with registration number 10552489.&nbsp; It is the licensor of the <span><ElasticText text="Elastic Engineer" /></span>.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>Distributed may make changes to this Agreement at its sole discretion at any time. We will communicate changes to you by posting the new version of the Agreement on our website at www.Distributed.com or as otherwise determined by Distributed in its sole discretion. Your acceptance of and/or continued access or use of the App after any changes to this Agreement will constitute your acceptance of those changes. If you do not accept the changes, you must discontinue using the App immediately and delete it from your device(s).</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>1. App License.</span></span></strong></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>a)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>License Grant</u>. Subject to the terms of this Agreement, Distributed grants to you a limited, non-exclusive, non-sublicensable and non-transferable (except as set forth in this Agreement) license to download, install, access and use the App during the term of this Agreement.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>b)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Ownership</u>. You acknowledge and agree that the App is provided under license and not sold to you. You do not acquire any ownership interest in the App under this Agreement or any rights other than to use the App in accordance with the license granted and subject to all terms and conditions of this Agreement. Distributed and its licensors retain their entire right, title and interest in and to the App, including all intellectual property and other proprietary rights worldwide, including patents, copyrights, trademarks, service marks, trade names, domain name rights, know-how, moral rights, trade secrets and all other intellectual or industrial property, including applications, registrations, renewals and extensions of such rights (&ldquo;</span></span><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>Intellectual Property Rights</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&rdquo;).</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>c)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Third-party Acknowledgements</u>. The App may use or include open-source and third-party software and other copyrighted material. Such software and your use of the App is subject to any applicable third-party licenses set forth within the App or that we otherwise make available to you. The terms and conditions of those third-party licenses will govern your use of that third-party or open-source software. Distributed represents that it has the right to use and distribute any open-source and third-party software used in the App. Distributed will maintain compliance with all applicable open-source and third-party software licenses during the term of this Agreement.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>d)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Updates</u>. Distributed may from time to time in its sole discretion develop and provide upgrades, bug fixes, patches, other error corrections to and/or new features of the App (&ldquo;</span></span><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>Updates</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&rdquo;). Updates may modify or delete in their entirety certain features and functionality. You agree that Distributed has no obligation to provide any Updates or to continue to provide or enable any features or functionality. Based on your device settings and when your device is connected to the internet, either:</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>i) The App will automatically download and install all available Updates; or</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>ii) You may receive notice of or be prompted to download and install available Updates.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>You shall promptly download and install all Updates and acknowledge and agree that the App or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the App and be subject to all terms of this Agreement.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>2. Use Restrictions</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>. You will not, except as expressly permitted by this Agreement:</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>a) Copy, reproduce, distribute, transfer, rent, lend, loan, lease or sublicense any portion of the App or otherwise make available the App, or any features or functionality of the App, to any third party for any reason;</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>b) Use or permit the App to be used to perform services for third parties, whether on a service bureau, SaaS, time sharing basis or otherwise;</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>c) Modify, translate, adapt or otherwise create derivative works or improvements based in whole or in part on the App;</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>d) Reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the App or any part thereof;</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>e) Remove, delete, alter or obscure any trademarks or other Intellectual Property Rights notices from the App, including any copy thereof;</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>f) Use access to the App to develop products, systems or services similar to or competitive with the App or develop methods to enable unauthorized parties to use, recreate or infringe upon the Intellectual Property Rights in and to the App;</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>g) Upload any files or content to the App that contain viruses or harmful computer code or violates any Intellectual Property Rights, proprietary rights or privacy rights of others;</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>h) Attempt to obtain access to any Distributed hardware, programs or data beyond the scope of the permitted access we grant you;</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>i) Use any automated or manual process to interfere with, modify or attempt to interfere with or modify the App; or</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>j) Contest or challenge the ownership or validity of, or adopt, apply for, register or otherwise seek to legally protect any of Distributed&rsquo;s Intellectual Property Rights related to the App, or aid or abet others in doing so, either during the term of this Agreement or after.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>3. Your Obligations and Representations</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>a)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Access</u>. You must provide at your expense, as applicable, all devices, services, equipment, software, current operating systems, Internet service, Wi-Fi or cellular service as necessary to use the App. You are responsible for ensuring that your devices, services, equipment and/or software do not disturb or interfere with Distributed&rsquo;s operations or the operations of other users of the App. If any modification to the App requires changes in your devices, services, equipment or software, you must effect these changes at your own expense. Unless explicitly stated otherwise, any new or additional features that augment or enhance the App, including all Updates, will be subject to the terms and conditions of this Agreement. DISTRIBUTED HAS NO LIABILITY FOR ANY WI-FI OR CONNECTIVITY FAILURES OR FAILURE TO PROVIDE ANY CONNECTIONS OR NOTIFICATIONS FOR ANY REASON.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>b)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Setup Details and Credentials</u>. To the extent credentials are required for your setup and use of the App, you must enter your own credentials. You agree to keep all setup and credential details private and to not share them with anyone else and you will implement reasonable safeguards to prevent unauthorized access to or unauthorized use of the App. You are solely responsible for all activities conducted under your credentials and to keep any personal data you enter accurate and up to date. DISTRIBUTED EXPLICITLY DISCLAIMS ALL LIABILITY FOR ANY LOSS OR THEFT OF INFORMATION YOU ENTER INTO THE APP TO THE FULLEST EXTENT PERMITTED BY LAW.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>c)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Representations</u>. You represent and warrant that (i) you own or have the right to use any information you enter into the App, including any personal information and to provide it to Distributed and grant Distributed the rights to use it in connection with our provision of the App to you; (ii) you have all the requisite power and authority to enter into this Agreement; (iii) you shall use the App only for lawful purposes, and will comply at all times with any and all applicable laws and regulations applicable to the use of the same; and iv) you shall use the App only for your personal business needs, and for no other commercial or third-party use.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>4.&nbsp;</span></span><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>Term and Termination</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>. The term of this Agreement will begin when you download and install or otherwise begin using the App and/or otherwise acknowledge your acceptance and will continue in effect until terminated. Distributed may, at its election and in its sole discretion, terminate this Agreement and your license, access or use of all or any portion of the App at any time at will and with or without cause. Distributed is further entitled to obtain injunctive relief if your use of the App is in violation of any restrictions set forth in this Agreement. You may terminate this Agreement by deleting the App and all copies from your device(s). Upon termination, you must cease using and accessing the App.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>5. DISCLAIMER OF WARRANTIES.</span><span style={{ fontFamily: 'Poppins-Regular' }}> DISTRIBUTED PROVIDES THE APP &ldquo;AS IS&rdquo; AND MAKES NO WARRANTIES REGARDING THE APP. NO ORAL INFORMATION OR ADVICE GIVEN BY DISTRIBUTED WILL CREATE ANY WARRANTY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAWS, DISTRIBUTED DISCLAIMS ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE. DISTRIBUTED DOES NOT WARRANT AGAINST ALL INTERFERENCE WITH YOUR ENJOYMENT OF THE APP, THAT THE FUNCTIONS CONTAINED IN THE APP WILL MEET YOUR NEEDS, THAT THE OPERATION OF THE APP WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DISTRIBUTED WILL CORRECT DEFECTS IN THE APP. THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE APP IS WITH YOU.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>6. App Store Disclaimer</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>. This Agreement is between you and Distributed, not any other entity such as Google, Inc., Apple, Inc., or Amazon.com, Inc., or any other third party through which you obtain any mobile application or the App (collectively and individually &ldquo;</span></span><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>Third-Party App Stores</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&rdquo;), and in no event will any Third-Party App Store be obligated to provide maintenance or support for the App; any warranty granted in this Agreement; or any indemnity for Intellectual Property Rights violations of the App. You are bound by any applicable terms set forth in relation to any such Third-Party App Stores. As to an app downloaded from Apple&#39;s app store, the license herein to the App is only to use the App to comply with any warranty as set forth herein. You must contact Distributed and not any Third-Party App Stores, regarding any claims that the App fails to conform to any applicable legal or regulatory requirement, or any claims arising under any consumer protection, privacy, or similar legislation. Third-Party App Stores and their subsidiaries will be third party beneficiaries to this Agreement and will have the right to enforce this Agreement against you.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>7. LIMITATION OF DISTRIBUTED LIABILITY.</span><br /><span className="font-poppins-regular"> TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAWS, IN NO EVENT WILL DISTRIBUTED BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTIAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY TYPE, INCLUDING WITHOUT LIMITATION ANY LOST PROFITS, LOST SAVINGS, LOST DATA, PROPERTY DAMAGE, UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, LOST REVENUE, BUSINESS INTERRUPTION, LOSS OF DATA ARISING OUT OF OR RELATING TO THIS AGREEMENT OR USE OF THE APP, EVEN IF DISTRIBUTED HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. LIABILITY FOR DIRECT LOSS SHALL BE LIMITED TO &pound;100.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>8. Indemnity</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>. You will, to the fullest extent permitted by law, indemnify Distributed, and its officers, directors, shareholders, employees and agents and their respective successors and assigns (collectively, the &ldquo;</span></span><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>Indemnified Parties</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&rdquo;) against and hold the Indemnified Parties harmless from any and all claims, losses, liabilities, damages, judgments, awards, settlements, costs and expenses, including reasonable legal fees in connection with investigating, defending or settling any claim relating to or arising out of any breach by you of this Agreement, your implementation or use of the App in a way that infringes or misappropriates any third party&rsquo;s Intellectual Property Rights or violates applicable law or any acts or omissions on the part of you which gives rise to claims against the Indemnified Parties.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>9. Export Regulation</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>. The App may be subject to export laws and regulations of the United Kingdom and other jurisdictions. You will not, directly or indirectly, export, re-export or release the App or make the App accessible from any jurisdiction or country to which export, re-export or release is prohibited by law or regulation. You will not access or use the App in any United States-embargoed country or in breach of any applicable export laws or regulations. Both Parties represent that they are not named on any government denied-party list or is owned by entities or individuals named to any government denied-party list.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>10. Choice of Law, Jurisdiction and Venue</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>a)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Choice of Law</u>. The laws of the England &amp; Wales will apply to this Agreement, without regard to any conflict of laws provisions. The United Nations Convention on Contracts for the International Sale of Goods, the application of which is expressly excluded, will not apply to this Agreement. Article 2 of the Uniform Commercial Code does not apply to the relationship created by this Agreement between Distributed and you.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>b)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Jurisdiction and Venue</u>.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>The parties agree to submit to the courts of England &amp; Wales.&nbsp; Any action will be conducted in England.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>c)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Injunctive Relief</u>. Distributed may institute an action in a court of proper jurisdiction for injunctive relief at any time.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>11. Privacy.</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;Information collected about you is generally governed by Distributed&rsquo;s Privacy Policy, which can be viewed seen on this site. Distributed may use and disclose your information in special instances when Distributed has reason to believe disclosing this information is necessary to investigate, identify, contact or bring legal action against someone who may be causing injury to or interfering with Distributed&#39;s rights or property, other App users or anyone else. Distributed may disclose information when subpoenaed; if ordered or otherwise required by a court of law, arbitrator or other similar proceeding or the rules governing such a proceeding; for government investigations with government agencies if required by law; to exercise, establish or defend Distributed&#39;s rights; to protect your vital interests or those of any other third party; and when Distributed otherwise believes in good faith that any applicable law requires it.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>12. General Provisions</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>a)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Optional Feedback</u>. You may provide Distributed suggestions, recommendations or feedback (&ldquo;</span></span><strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Bold' }}>Feedback</span></span></strong><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&rdquo;) about the App. You warrant to Distributed that Feedback does not contain and/or infringe any third-party rights. If you provide Feedback to us, we may use it without restriction and you irrevocably assign to Distributed all right, title and interest in and to that Feedback.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>b)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Entire Agreement</u>. This Agreement together with the information referred to or linked to by this Agreement, all of which are incorporated herein, sets forth the entire agreement and understanding between Distributed and you regarding the subject matter hereof and supersedes any prior representations, advertisements, statements, proposals, negotiations, discussions, understandings, or agreements regarding the same subject matter.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>c)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Waiver</u>. Either party&rsquo;s waiver of a breach of any provision of this Agreement will not operate as or be construed as a waiver of any further or subsequent breach.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>d)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Force Majeure</u>. Neither Party will be liable for damages for any delay or failure in performance or delivery arising out of causes beyond its reasonable control, including but not limited to, labour strikes, acts of God, epidemic or pandemic as declared by the World Health Organization, acts of civil or military authority, fires, riots, wars, embargoes, Internet disruptions or electrical or communications failures.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>e)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Survival</u>. Provisions of this Agreement which by their nature are to be performed or enforced following any termination of this Agreement will survive termination.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>f)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Independence</u>. The parties are independent from each other. This Agreement does not create a partnership, an agency, employment, fiduciary or joint venture relationship between us for any purpose and neither Party may make commitments on the other&rsquo;s behalf</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>g)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Assignment</u>. Distributed may assign this Agreement to an Affiliate or in connection with a merger or the sale of substantially all our assets.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>h)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Binding Effect</u>. This Agreement is binding on and inures to the benefit of the Parties, their successors and permitted assigns.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>i)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Unenforceability and Severability</u>. If any part of this Agreement is for any reason held to be unenforceable, the rest of the Agreement remains fully enforceable.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>j)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Translations</u>. If this Agreement is translated into languages other than English, only the English version will control.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>k)</span></span></u><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>&nbsp;<u>Headings</u>. The headings are for convenience only and do not affect the interpretation of this Agreement.</span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}>Contact Information for any questions, complaints or claims:&nbsp; Legal &amp; Commercial Director, Distributed Limited,&nbsp; Email:&nbsp; <a href="mailto:legal@distributed.com" className="text-light-blue">legal@distributed.com</a>.&nbsp; </span></span></span></span></p>

      <p><span className="text-[15px]"><span style={{ fontFamily: 'Calibri,sans-serif' }}><span className="text-[16px]"><span style={{ fontFamily: 'Poppins-Regular' }}><a href="https://www.distributed.com" className="text-light-blue" target="_blank">www.distributed.com</a>.</span></span></span></span></p>
    </div>
  );
};

export default TermsConditions;
