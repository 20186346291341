import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getUserSession } from '../utils';

const requestHandler = (request: AxiosRequestConfig) => request;
const responseHandler = (response: AxiosResponse) => response;
const requestErrorHandler = (error: any) => Promise.reject(error);
const responseErrorHandler = (error: any) => Promise.reject(error);

const getToken = async () => {
  const userSession = await getUserSession();
  return userSession ? userSession.getIdToken().getJwtToken() : '';
};

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    config = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestErrorHandler(error)
);

apiClient.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => responseErrorHandler(error)
);

export const errorHandler = (reason: any) => {
  if (axios.isCancel(reason)) return 'Request Cancelled';
  if (reason.code === 'ECONNABORTED') return 'A timeout occurred';
  if (reason.response?.status === 404) return 'Resource Not Found';
  if (reason.response?.status === 400 || reason.response?.status === 500)
    return reason.response.data.error_message;

  return 'An unexpected error has occured';
};
