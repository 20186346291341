export const skillMarks: {
  value: number;
  label?: string;
}[] = [
  {
    value: 20,
  },
  {
    value: 40,
  },
  {
    value: 60,
  },

  {
    value: 80,
  },
  {
    value: 100,
  },
];
