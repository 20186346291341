import React from 'react';

export const CommunityIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 16C0.716667 16 0.479333 15.904 0.288 15.712C0.096 15.5207 0 15.2833 0 15V13.2C0 12.6333 0.146 12.1123 0.438 11.637C0.729333 11.1623 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64567 4.75 9.387C5.81667 9.129 6.9 9 8 9C9.1 9 10.1833 9.129 11.25 9.387C12.3167 9.64567 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2707 11.1623 15.562 11.637C15.854 12.1123 16 12.6333 16 13.2V15C16 15.2833 15.904 15.5207 15.712 15.712C15.5207 15.904 15.2833 16 15 16H1ZM17.525 16C17.675 15.8833 17.7917 15.7373 17.875 15.562C17.9583 15.3873 18 15.1917 18 14.975V13C18 12.2667 17.796 11.5623 17.388 10.887C16.9793 10.2123 16.4 9.63333 15.65 9.15C16.5 9.25 17.3 9.42067 18.05 9.662C18.8 9.904 19.5 10.2 20.15 10.55C20.75 10.8833 21.2083 11.254 21.525 11.662C21.8417 12.0707 22 12.5167 22 13V15C22 15.2833 21.904 15.5207 21.712 15.712C21.5207 15.904 21.2833 16 21 16H17.525ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM18 4C18 5.1 17.6083 6.04167 16.825 6.825C16.0417 7.60833 15.1 8 14 8C13.8167 8 13.5833 7.97933 13.3 7.938C13.0167 7.896 12.7833 7.85 12.6 7.8C13.05 7.26667 13.3957 6.675 13.637 6.025C13.879 5.375 14 4.7 14 4C14 3.3 13.879 2.625 13.637 1.975C13.3957 1.325 13.05 0.733333 12.6 0.2C12.8333 0.116666 13.0667 0.0623335 13.3 0.0370002C13.5333 0.0123335 13.7667 0 14 0C15.1 0 16.0417 0.391667 16.825 1.175C17.6083 1.95833 18 2.9 18 4Z" fill="#FF007A"/>
    </svg>
  );
};
