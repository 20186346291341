import SimpleDialog from 'components/SimpleDialog';
import { ElasticText } from 'components/ElasticText';
import { LogoIcon1 } from 'assets';
import Styled from 'pages/code-test/code-test.styles';

export interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

const title = 'Contact us';

const ContactUS = (props: ModalProps) => {
  const { open, handleClose } = props;
  return (
    <SimpleDialog open={open} onClose={handleClose}>
      <Styled.DialogContainer>
        <Styled.DialogLogo src={LogoIcon1} alt="logo" />
        <Styled.DialogBody>
          <Styled.DialogTitle>{title}</Styled.DialogTitle>
          <div className='text-center text-primary-blue text-[15px] font-poppins-regular '>
            For Help with the
            <ElasticText
              supStyle={{ fontSize: '10px', fontFamily: 'Poppins-Regular' }}
              style={{ margin: '0 5px'}}
              text={'Elastic Engineer'}
            />
            App, <br />
            Please contact us at <a href="mailto://contact@distributed.com" className="text-light-blue">contact@distributed.com</a>
            <br />
            <br />
            To read our General Data Protection Regulation policies (GDPR) please click this link: <a href="mailto://gdpr@distributed.com" className="text-light-blue">gdpr@distributed.com</a>
          </div>
        </Styled.DialogBody>
      </Styled.DialogContainer>
    </SimpleDialog>
  );
};
export default ContactUS;
