import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { ElasticText } from '../../components/ElasticText';
import { Header } from '../../components/Header';
import { Button } from 'components';
import FormItem from '../../components/FormItem';
import { Footer } from '../../components/Footer';
import { CommunityView } from '../../components/CommunityView';
import { Divider } from '../../components/Divider';
import CheckboxItem from 'components/CheckBox';
import { useAppSelector } from '../../store';
import {
  email_regex,
  password_regex,
  fillIn,
  invalidEmail,
  invalidPassword,
  wrongPasswordInput,
} from '../../constant';
import { GoogleIcon } from '../../assets';
import OtpModal from './OtpModal';
import TermsModal from './TermsModal';
import Styled from './signup.styles';
import './styles.css';

const gauth_enabled = false;

function Signup() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [modalError, setModalError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [termsOpen, setTermsOpen] = React.useState(false);
  const [verified, setVerified] = React.useState(false);
  const [agreement, setAgreement] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyLoading, setVerifyLoading] = useState(false);
  const isAuthLoading = useAppSelector((state) => state.user?.isAuthLoading);
  const isEngineer = useAppSelector((state) => state.user?.isEngineer);

  const isSignUpBtnDisabled = !agreement || !fullName || !email || !password || !confirmPassword;

  const handleClose = () => {
    if (otp && verified) setOpen(false);
  };
  const handleTermsModalClose = () => {
    setTermsOpen(false);
  };

  const onSignup = () => {
    setError('');
    if (!email || !password || !confirmPassword) {
      return setError(fillIn);
    }
    if (email_regex.test(email) === false) {
      return setError(invalidEmail);
    }
    if (password_regex.test(password) === false) {
      return setError(invalidPassword);
    }
    if (password !== confirmPassword) {
      return setError(wrongPasswordInput);
    }
    signUp();
  };

  const onHandleError = (error) => {
    const errorTxt =
      error.toString().indexOf(':') > -1
        ? error.toString().split(':')[1].toString()
        : error.toString();
    setError(errorTxt);
  };

  const onHandleModalError = (error) => {
    const errorTxt =
      error.toString().indexOf(':') > -1
        ? error.toString().split(':')[1].toString()
        : error.toString();
    setModalError(errorTxt);
  };

  async function onVerify() {
    setVerifyLoading(true);
    setModalError('');
    try {
      await Auth.confirmSignUp(email, otp);
      setOpen(false);
      setVerified(true);
    } catch (error) {
      onHandleModalError(error);
      setVerified(false);
    } finally {
      setVerifyLoading(false);
    }
  }

  async function signUp() {
    setIsLoading(true);
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          name: fullName,
          profile: isEngineer ? 'isEngineer' : 'NotEngineer',
        },
        autoSignIn: {
          enabled: true,
        },
      });
      setOpen(true);
    } catch (error: any) {
      onHandleError(error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000)
    }
  }

  const onGoogleSignUp = () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };
  return (
    <>
      <Header />
      <div className="signup-container">
        <CommunityView />
        <div className="signup-form">
          <Styled.HeaderTitle style={{ marginTop: '2rem' }}>
            Join the <ElasticText />
          </Styled.HeaderTitle>
          <div className="sign-form">
            <FormItem
              label="FULL NAME"
              placeholder="What's your full name?"
              value={fullName}
              onChange={(e) => setFullName(e)}
            />
            <FormItem
              label="EMAIL ADDRESS"
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e)}
            />
            <FormItem
              type="password"
              label="CREATE A PASSWORD"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e)}
            />
            <FormItem
              type="password"
              label="CONFIRM YOUR PASSWORD"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e)}
            />
            {error && <Styled.ErrorText>{error}</Styled.ErrorText>}
            <CheckboxItem value={agreement} onChange={() => setAgreement(!agreement)} label={(
              <Styled.PrivacyPolicy>
                I agree to the   <p
                  className={'inline text-light-blue cursor-pointer'}
                  onClick={(e) => {
                    e.preventDefault();
                    setTermsOpen(true)
                  }}
                >
                  terms & conditions
                </p> and &nbsp;
                <a
                  className={'inline text-light-blue'}
                  href="https://www.distributed.com/privacy-policy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  privacy policy
                </a>
              </Styled.PrivacyPolicy>
            )}/>
            
            <Button
              className="signup-btn"
              disabled={isSignUpBtnDisabled}
              onClick={onSignup}
              text={'SIGN UP'}
              isLoading={isLoading || isAuthLoading}
            />
            {gauth_enabled && (
              <>
                <Divider />
                <Button className="signup-google" disabled={!agreement} onClick={onGoogleSignUp}>
                  <Styled.GoogleLogoIcon src={GoogleIcon} alt="Google" />
                  SIGN UP WITH GOOGLE
                </Button>
              </>
            )}
          
          </div>
        </div>
        
      </div>
      <OtpModal
        open={open}
        handleClose={handleClose}
        value={otp}
        error={modalError}
        isLoading={isVerifyLoading}
        onSubmit={onVerify}
        onChange={(otp: string) => setOtp(otp)}
      />
      <TermsModal
        open={termsOpen}
        handleClose={handleTermsModalClose}
      />
      <Footer />
    </>
  );
}

export default Signup;
