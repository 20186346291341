import { Skeleton } from '@mui/material';
import React from 'react';

export const RadioCardSkeleton = () => {
  return (
    <div>
      <Skeleton variant="text" className="max-w-[200px]" />
      <Skeleton className="max-w-[400px]" height={40} />
      <div className="flex gap-x-10 flex-wrap -mt-3">
        <Skeleton variant="text" className="w-[200px]" />
        <Skeleton variant="text" className="w-[200px]" height={80} />
        <Skeleton
          variant="text"
          className="w-[200px] hidden md:block"
          height={80}
        />
      </div>
    </div>
  );
};

export const DropdownSkeleton = () => {
  return (
    <div>
      <Skeleton variant="text" className="max-w-[200px]" />
      <Skeleton className="max-w-[600px]" height={40} />
    </div>
  );
};

const RolesFormLoadingSkeleton = () => {
  return (
    <div className=" flex flex-col">
      <RadioCardSkeleton />
      <DropdownSkeleton />
      <DropdownSkeleton />
      <RadioCardSkeleton />
    </div>
  );
};

export default RolesFormLoadingSkeleton;
