import useMediaQuery from '@mui/material/useMediaQuery';
import styled from 'styled-components';
import {
  HamburgerIcon,
  LogoIcon,
  FaceBookIcon,
  LinkedinIcon,
  TwitterIcon,
  ShareIcon,
} from '../assets';

interface TextProps {
  style?: object;
  text?: string;
}

const SocialIcon = styled.a`
  height: 27px;
  & img {
    height: 27px;
  }
  @media (max-width: 720px) {
    height: 35px;
    & img {
      height: 35px;
    }
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem;
`;

export const Footer = (props: TextProps) => {
  const { style, text } = props;
  const isMobile = useMediaQuery('(max-width:1024px)');
  if (isMobile) {
    return (
      <FooterContainer>
        <SocialIcon href="https://twitter.com/_distributed_" target={'_blank'}>
          <img src={TwitterIcon} alt="Distributed Twitter" />
        </SocialIcon>
        <SocialIcon
          href="https://www.linkedin.com/company/dstbtd"
          target={'_blank'}
        >
          <img
            src={LinkedinIcon}
            className="social-icon"
            alt="Distributed Linkedin"
          />
        </SocialIcon>
        <SocialIcon href="https://www.facebook.com/DSTBTD" target={'_blank'}>
          <img
            src={FaceBookIcon}
            className="social-icon"
            alt="Distributed Facebook"
          />
        </SocialIcon>
        {/* <SocialIcon href="" target={'_blank'}>
          <img
            src={ShareIcon}
            className="social-icon"
            alt="Distributed Share"
          />
        </SocialIcon> */}
      </FooterContainer>
    );
  }
  return null;
};
