import styled from 'styled-components';

const LeftLineSpace = styled.span`
  height: 1px;
  background: rgb(155, 159, 161);
  width: 45%;
  position: absolute;
  left: 0;
  top: 6px;
`;

const RightLineSpace = styled.span`
  height: 1px;
  background: rgb(155, 159, 161);
  width: 45%;
  position: absolute;
  right: 0;
  top: 6px;
`;

const SeparatorContainer = styled.div`
  display: flex;
  position: relative;
`;

const SeparatorTitle = styled.p`
  color: #080078;
  font-size: 13px;
  margin: auto;
`;

export const Divider = () => {
  return (
    <SeparatorContainer>
      <LeftLineSpace />
      <SeparatorTitle>Or</SeparatorTitle>
      <RightLineSpace />
    </SeparatorContainer>
  );
};
