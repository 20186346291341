import React, { useEffect, useState } from 'react';
import { Button } from 'components';
import { Auth } from 'aws-amplify';
import { ElasticText } from '../../components/ElasticText';
import { Header } from '../../components/Header';
import { CommunityView } from '../../components/CommunityView';
import FormItem from '../../components/FormItem';
import { Footer } from '../../components/Footer';
import { Divider } from '../../components/Divider';
import TermsModal from '../signup/TermsModal';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { email_regex, password_regex } from '../../constant';
import { LogoIcon, GoogleIcon } from '../../assets';
import AccountRecovery from 'pages/account-recovery';
import Styled from '../signup/signup.styles';
import './styles.css';

const gauth_enabled = false;

function Signin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showAccountPopUp, setShowAccountPopUp] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleTermsModalClose = () => {
    setTermsOpen(false);
  };

  const onHandleError = (error) => {
    const errorTxt =
      error.toString().indexOf(':') > -1
        ? error.toString().split(':')[1].toString()
        : error.toString();
    setError(errorTxt);
  };

  const onSignin = () => {
    setError('');
    if (!email || !password) {
      return setError('*Required fields');
    }
    if (email_regex.test(email) === false) {
      return setError('Invalid email address');
    }
    if (password_regex.test(password) === false) {
      return setError('Invalid password');
    }
    signIn();
  };

  const onAccountRecovery = () => {
    setShowAccountPopUp(true);
  };

  async function signIn() {
    setIsLoading(true);
    try {
      const { user } = await Auth.signIn(email, password);
    } catch (error) {
      onHandleError(error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000)
    }
  }

  const onGoogleLogin = () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  
  return (
    <>
      <Header signIn={'false'} />
      <div className="signup-container">
        <CommunityView />
        <div className="signup-form">
          <Styled.HeaderTitle>
            Welcome to the{'\n'}
            <ElasticText />
          </Styled.HeaderTitle>
          <div className="sign-form">
            <FormItem
              label="EMAIL"
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e)}
            />
            <FormItem
              type="password"
              label="PASSWORD"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e)}
            />
            <div>
              {error && <Styled.ErrorText>{error}</Styled.ErrorText>}
              <Button className="signup-btn" onClick={onSignin} text={'SIGN IN'} isLoading={isLoading} />
            </div>
            {gauth_enabled && (
              <>
                <Divider />
                <Button className="signup-google" onClick={onGoogleLogin}>
                  <Styled.GoogleLogoIcon src={GoogleIcon} alt="Google" />
                  SIGN IN WITH GOOGLE
                </Button>
              </>
            )}
            <p
              className="text-light-blue font-poppins-regular text-[13px] text-center cursor-pointer"
              onClick={onAccountRecovery}
            >
              Account Recovery
            </p>
            <Styled.PrivacyPolicy>
                By signing up, I agree to the Distributed's  <p
                  className={'inline text-light-blue cursor-pointer'}
                  onClick={(e) => {
                    e.preventDefault();
                    setTermsOpen(true)
                  }}
                >
                  terms & conditions
                </p> and &nbsp;
                <a
                  className={'inline text-light-blue'}
                  href="https://www.distributed.com/privacy-policy/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  privacy policy
                </a>
              </Styled.PrivacyPolicy>
          </div>
        </div>
      </div>
      <AccountRecovery open={showAccountPopUp} onClose={() => setShowAccountPopUp(false)}/>
      <Footer />
      <TermsModal
        open={termsOpen}
        handleClose={handleTermsModalClose}
      />
    </>
  );
}

export default Signin;
