import React from 'react';

const CookieIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.502" height="22.502" viewBox="0 0 22.502 22.502">
    <path id="Path_488" data-name="Path 488" d="M12.07-19.706a.582.582,0,0,0-.483-.5,4.967,4.967,0,0,0-3.019.534L5.1-17.817a4.97,4.97,0,0,0-2.128,2.208l-1.72,3.544A4.973,4.973,0,0,0,.825-9.023L1.5-5.142A4.976,4.976,0,0,0,2.944-2.428L5.77.309A4.933,4.933,0,0,0,8.522,1.655l3.891.548a4.967,4.967,0,0,0,3.019-.534L18.9-.183A4.957,4.957,0,0,0,21.028-2.4l1.72-3.539a4.973,4.973,0,0,0,.427-3.042.588.588,0,0,0-.5-.473,6.013,6.013,0,0,1-4.9-4.566.9.9,0,0,0-.736-.684,6.01,6.01,0,0,1-4.978-5.006ZM9.75-11.25a1.5,1.5,0,0,1-1.5-1.5,1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5A1.5,1.5,0,0,1,9.75-11.25Zm0,6a1.5,1.5,0,0,1-1.5,1.5,1.5,1.5,0,0,1-1.5-1.5,1.5,1.5,0,0,1,1.5-1.5A1.5,1.5,0,0,1,9.75-5.25Zm7.5,0a1.5,1.5,0,0,1-1.5-1.5,1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5A1.5,1.5,0,0,1,17.25-5.25Z" transform="translate(-0.749 20.251)" fill="#ff007a"/>
  </svg>
  );
};

export default CookieIcon;
