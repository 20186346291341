import React from 'react';

export const PasswordWrongIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.265" height="15.267" viewBox="0 0 15.265 15.267">
        <g id="Group_1237" data-name="Group 1237" transform="translate(-1205.737 305.03) rotate(-45)">
            <path id="Path_492" data-name="Path 492" d="M-6394.521,17467.3h17.587" transform="translate(7454 -16819.607)" fill="none" stroke="#FF007A" stroke-linecap="round" stroke-width="2"/>
            <path id="Path_493" data-name="Path 493" d="M0,0H17.586" transform="translate(1068.273 656.482) rotate(-90)" fill="none" stroke="#FF007A" stroke-linecap="round" stroke-width="2"/>
        </g>
    </svg>
  );
};
