import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../client';
import { API_URL } from '../endpoint';
import { AssessmentBody } from '../../types';

export const getAssessments = createAsyncThunk(
  'users/get_assessments',
  async () => {
    const response = await client.get(API_URL + 'assessments');
    return response;
  }
);

export const getAssessmentResult = createAsyncThunk(
  'user/getAssessmentResult',
  async (userId: string) => {
    const response = await client.get(API_URL + 'user-language-test/' + userId);
    return response;
  }
);

export const inviteAssessment = createAsyncThunk(
  'user/inviteAssessment',
  async (body: AssessmentBody) => {
    const response = await client.post(API_URL + 'assessments/invite', body);
    return response;
  }
);

export const getTechnicalAssessments = createAsyncThunk(
  'users/get_technical_assessments',
  async () => {
    const response = await client.get(API_URL + 'technical-test');
    return response;
  }
);

export const getTechnicalAssessmentResult = createAsyncThunk(
  'user/getTechnicalAssessmentResult',
  async (userId: string) => {
    const response = await client.get(API_URL + 'technical-test/' + userId);
    return response;
  }
);

export const inviteTechnicalAssessment = createAsyncThunk(
  'user/Technical/inviteAssessment',
  async (body: AssessmentBody) => {
    const response = await client.post(API_URL + 'assessments/technical/invite', body);
    return response;
  }
);