import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
`;

const LogoIcon = styled.img`
  height: 71px;
`;
const LogoIcon1 = styled.img`
  height: 53px;
  object-fit: contain;
`;
const GoogleLogoIcon = styled.img`
  height: 20px;
  object-fit: contain;
  margin-right: 1rem;
`;

const SignupForm = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const HeaderTitle = styled.div`
  color: #080078;
  font-size: 32px;
  font-family: Poppins-ExtraBold;
  margin: 0;
  white-space: pre-line;
  @media (max-width: 720px) {
    font-size: 28px;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 15px;
  font-family: Poppins-Regular;
  text-align: center;
  margin: 0.5rem 0;
  padding-top: 0;
`;

const SubmitButton = styled.button`
  background: #144fe7;
  color: white;
  border: 0;
  border-radius: 50px;
  height: 38.57px;
  margin-top: 2rem;
  width: 100%;
  &:disabled {
    opacity: 0.5;
  }
`;

const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DialogContainer = styled.div`
  padding: 1.5rem;
  max-width: 372px;
  min-height: 469px;
  text-align: center;
  box-sizing: border-box;
`;
const DialogBody = styled.div`
  padding: 1rem;
`;
const DialogLogo = styled.img`
  margin-top: 1rem;
  object-fit: contain;
  width: 312px;
`;

const DialogTitle = styled.p`
  color: #080078;
  font-weight: 600;
  font-size: 20px;
`;

const DialogDescription = styled.p`
  font-weight: 500;
  text-align: left;
  font-size: 15px;
  margin-top: 1rem;
  line-height: 1.4rem;
  padding: 0 1.5rem;
  color: #080078;
`;

const DialogBanner = styled.img`
  height: 32px;
  margin-top: 1rem;
  object-fit: contain;
`;

const PrivacyPolicy = styled.p`
  color: #080078;
  font-size: 13px;
  text-align: center;
  font-family: Poppins-Regular;
`;

const Styled = {
  Container,
  LogoIcon,
  LogoIcon1,
  GoogleLogoIcon,
  SignupForm,
  HeaderTitle,
  SubmitButton,
  OtpContainer,
  DialogContainer,
  DialogBody,
  DialogLogo,
  DialogTitle,
  DialogDescription,
  ErrorText,
  DialogBanner,
  PrivacyPolicy,
  FlexContainer,
};

export default Styled;
