import * as React from 'react';

export const DocIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 117.8 126.7"
    >
      <g>
        <path
          d="M97.7,45.1v52.6c0,6.1-5,11.1-11.1,11.1v-5.5c3.1,0,5.5-2.5,5.5-5.5V45.1H81.1c-4.6,0-8.3-3.7-8.3-8.3V25.7
           H42.3c-3.1,0-5.5,2.5-5.5,5.5V81h-5.5V31.2c0-6.1,5-11.1,11.1-11.1h30.4L97.7,45.1z M54.3,95.8v2.9c0,1.4-0.2,2.6-0.6,3.5
           c-0.4,0.9-1,1.6-1.8,2.1c-0.8,0.5-1.7,0.7-2.6,0.7c-0.9,0-1.8-0.2-2.6-0.7c-0.8-0.5-1.4-1.3-1.8-2.1c-0.5-1.1-0.7-2.3-0.6-3.6v-2.9
           c0-1.4,0.2-2.6,0.6-3.5c0.4-0.9,1-1.6,1.8-2.1c0.8-0.5,1.7-0.7,2.6-0.7c1,0,1.8,0.2,2.6,0.7c0.8,0.5,1.4,1.3,1.8,2.1
           C54.1,93.2,54.3,94.4,54.3,95.8z M58.7,98.7v-2.8c0-2.1-0.4-3.9-1.1-5.4c-0.7-1.5-1.8-2.7-3.3-3.5c-1.4-0.8-3.1-1.2-5.1-1.2
           c-2,0-3.7,0.4-5.1,1.2c-1.4,0.8-2.6,2-3.3,3.5c-0.8,1.5-1.1,3.3-1.1,5.4v2.8c0,2.1,0.4,3.9,1.1,5.4c0.8,1.5,1.8,2.7,3.3,3.5
           c1.4,0.8,3.1,1.2,5.1,1.2c2,0,3.7-0.4,5.1-1.2c1.4-0.8,2.5-2,3.3-3.5C58.4,102.5,58.7,100.7,58.7,98.7z M20.2,86.1v22.1h8.1
           c2.2,0,4.1-0.4,5.5-1.3c1.5-0.9,2.6-2.2,3.3-3.8c0.7-1.7,1.1-3.7,1.1-6c0-2.3-0.4-4.3-1.1-5.9c-0.7-1.6-1.8-2.9-3.3-3.8
           c-1.5-0.9-3.3-1.3-5.6-1.3H20.2z M24.6,89.7h3.1c1.4,0,2.5,0.3,3.4,0.8c0.9,0.6,1.6,1.5,2,2.5c0.4,1.1,0.7,2.5,0.7,4.2
           c0,1.1-0.1,2.2-0.4,3.3c-0.2,0.8-0.6,1.6-1.1,2.3c-0.5,0.6-1.1,1.1-1.8,1.4c-0.9,0.3-1.8,0.5-2.7,0.5h-3.1L24.6,89.7L24.6,89.7z
            M65.7,92.4c-0.4,1.1-0.6,2.3-0.6,3.5v2.7c0,1.4,0.2,2.5,0.6,3.5c0.3,0.9,0.9,1.6,1.6,2.2c0.8,0.5,1.7,0.8,2.6,0.7
           c0.8,0,1.5-0.1,2.2-0.5c0.6-0.3,1.1-0.8,1.5-1.4c0.4-0.6,0.6-1.3,0.6-2h4.2v0.4c-0.1,1.3-0.5,2.6-1.3,3.7c-0.8,1.1-1.8,1.9-3,2.5
           c-1.4,0.6-2.9,0.9-4.3,0.9c-2,0-3.7-0.4-5.1-1.2c-1.4-0.8-2.5-2-3.2-3.5c-0.7-1.5-1.1-3.3-1.1-5.4v-2.8c0-2.1,0.4-3.9,1.1-5.4
           c0.7-1.5,1.8-2.7,3.2-3.5c1.4-0.8,3.1-1.2,5-1.2c1.2,0,2.3,0.2,3.4,0.5c1,0.3,1.9,0.8,2.7,1.5c1.6,1.3,2.5,3.3,2.6,5.3v0.4h-4.2
           c-0.1-0.7-0.3-1.5-0.7-2.1c-0.4-0.6-0.9-1.1-1.5-1.4c-0.7-0.4-1.4-0.6-2.2-0.5c-0.9,0-1.9,0.2-2.6,0.8C66.6,90.7,66,91.5,65.7,92.4
           z"
          fill="#144FE7"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
