export const noticePeriodLists = [
  {
    value: 101,
    title: '1 week',
  },
  {
    value: 102,
    title: '2 weeks',
  },
  {
    value: 104,
    title: '1 month',
  },
  {
    value: 201,
    title: '3 months',
  },
  {
    value: 203,
    title: 'Greater than 3 months',
  },
];
