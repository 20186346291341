import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root ': {
    borderRadius: '15px !important',
    margin: '1rem',
    maxWidth: '90%'
  },
}));

export interface SimpleDialogProps {
  open: boolean;
  children: any;
  onClose?: () => void;
  maxWidth?: any;
  fullScreen?: boolean;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, children, ...rest } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
      {...rest}
    >
      {children}
    </BootstrapDialog>
  );
}

export default SimpleDialog;
