interface TextProps {
  style?: object;
  supStyle?: object;
  text?: string;
  sup?: string;
}
export const ElasticText = (props: TextProps) => {
  const { style, text, supStyle, sup='true' } = props;
  const defaultStyle = {
    fontFamily: 'inherit',
    fontSize: 'inherit',
  };
  return (
    <p
      className="text-primary-pink"
      style={{ display: 'inline', ...(style || defaultStyle) }}
    >
      {text || 'Elastic Team'}
    </p>
  );
};
