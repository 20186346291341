import React from 'react';
import { HeadingTextProps } from './type';

const HeadingText: React.FC<HeadingTextProps> = ({ text, infoText }) => (
  <div>
    <p className="font-poppins-medium text-[16px] text-primary-blue sm:text-[20px] leading-[30px]">
      {text}
    </p>
  </div>
);

export default HeadingText;
