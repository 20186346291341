import React from 'react';

const OpenIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.33333 14C2.96667 14 2.65267 13.8696 2.39133 13.6087C2.13044 13.3473 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13044 2.65267 2.39133 2.39133C2.65267 2.13044 2.96667 2 3.33333 2H7.33333C7.52222 2 7.68067 2.06378 7.80867 2.19133C7.93622 2.31933 8 2.47778 8 2.66667C8 2.85556 7.93622 3.01378 7.80867 3.14133C7.68067 3.26933 7.52222 3.33333 7.33333 3.33333H3.33333V12.6667H12.6667V8.66667C12.6667 8.47778 12.7307 8.31933 12.8587 8.19133C12.9862 8.06378 13.1444 8 13.3333 8C13.5222 8 13.6804 8.06378 13.808 8.19133C13.936 8.31933 14 8.47778 14 8.66667V12.6667C14 13.0333 13.8696 13.3473 13.6087 13.6087C13.3473 13.8696 13.0333 14 12.6667 14H3.33333ZM6 10C5.87778 9.87778 5.81667 9.72222 5.81667 9.53333C5.81667 9.34444 5.87778 9.18889 6 9.06667L11.7333 3.33333H10C9.81111 3.33333 9.65289 3.26933 9.52533 3.14133C9.39733 3.01378 9.33333 2.85556 9.33333 2.66667C9.33333 2.47778 9.39733 2.31933 9.52533 2.19133C9.65289 2.06378 9.81111 2 10 2H13.3333C13.5222 2 13.6804 2.06378 13.808 2.19133C13.936 2.31933 14 2.47778 14 2.66667V6C14 6.18889 13.936 6.34711 13.808 6.47467C13.6804 6.60267 13.5222 6.66667 13.3333 6.66667C13.1444 6.66667 12.9862 6.60267 12.8587 6.47467C12.7307 6.34711 12.6667 6.18889 12.6667 6V4.26667L6.91667 10.0167C6.79444 10.1389 6.64444 10.2 6.46667 10.2C6.28889 10.2 6.13333 10.1333 6 10Z" fill="#707A89"/>
    </svg>
  );
};

export default OpenIcon;
