import { ModalCloseIcon, AcceptIcon } from 'assets';
import Button from 'components/Button';

export interface ConfirmAgreementProps {
    onClose: () => void;
}

export default function(props: ConfirmAgreementProps) {
    const { onClose } = props;
    return (
        <div className='flex flex-col gap-4'>
            <div className='flex flex-row gap-6 justify-between'>
                <div className='flex flex-row items-center gap-2'>
                    <AcceptIcon />
                    <p className="text-cool-900 font-normal leading-6 font-poppins text-[18px]">You have confirmed acceptance</p>
                </div>
                <span className='-mt-2 -mr-2 cursor-pointer' onClick={onClose}><ModalCloseIcon /></span>
            </div>
            <p className="text-cool-700 font-normal leading-5 font-poppins text-[14px]">
                Thank you for your continued participation in the Distributed Community. We greatly appreciate your feedback and continued engagement as we work together to create a positive and supportive environment for all members.
            </p>
            <div className='flex flex-row justify-end gap-4 items-center pt-4'>
                <Button
                    label="Close"
                    className='!text-[14px] !px-6 !py-2 !h-10'
                    type="submit"
                    onClick={onClose}
                />
            </div>
        </div>
    )
  }