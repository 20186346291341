import React, { useEffect } from "react";
import { scriptExists } from "utils";

export default function ZohoChatWidget() {
    const widgetUrl = 'https://salesiq.zohopublic.eu/widget';
    useEffect(() => {
        if (!scriptExists(widgetUrl)) {
            insertZohoWidget();
        }
    }, [])

    const insertZohoWidget = () => {
        window['$zoho'] = window['$zoho'] || {};
        window['$zoho'].salesiq = window['$zoho'].salesiq || {
            widgetcode: process.env.REACT_APP_ZOHO_WIDGET_CODE,
            values: {},
            ready: function () {},
        };
        const d = document;
        let s;
        s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = widgetUrl;
        let t;
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
    }
    
    return (
        <React.Fragment>
            <div id='zsiqchat'></div>
        </React.Fragment>
    );
}
