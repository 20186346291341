import React, { useEffect } from 'react';
import { Controller, FieldArrayWithId } from 'react-hook-form';
import HeadingText from '../HeadingText';
import { fieldOfWorkOptions } from 'pages/skills/consts/field-of-works';
import { Button, Chip, RadioCard, Select } from 'components';
import { apiClient } from 'services/apiClient';
import SectionLabel from '../SectionLabel';
import { noticePeriodLists } from 'pages/skills/consts/notice-period-list';
import { availabilityLists } from 'pages/skills/consts/availability';
import Slider from 'components/Slider';
import { contractLengthList } from 'pages/skills/consts/contract-length-list';
import { currencyList } from 'pages/skills/consts/currency-list';
import { useAppDispatch, useAppSelector } from 'store';
import { Navigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Input from 'components/Input';
import moment from 'moment';
import {
  Autocomplete,
  SelectChangeEvent,
  TextField,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getProfile } from 'api/services/user';
import { theme } from 'styles/theme';
import { skillMarks } from 'pages/skills/consts/skill-marks';
import { useRolesSkillsCreateForm } from 'pages/skills/hooks/useRolesSkillsCreateForm';
import ErrorValidationText from 'components/ErrorValidationText';
import RolesFormLoadingSkeleton from '../LoadingSkeleton';
import { RolesFormType } from './type';
import { CloseIcon1 } from 'assets';

const CustomAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-tag {
    color: ${theme.colors.ChipLabel};
    background: ${theme.colors.BlueDark};
  }
  & .MuiChip-deleteIcon {
    color: ${theme.colors.ChipIconColor};
  }
`;


const RolesForms = () => {
  const {
    roleOptions,
    onRoleChange,
    onPreferredStartDateChange,
    skillOptions,
    handleSubmit,
    onSubmit,
    register,
    control,
    setValue,
    formLoading,
    formSubmitting,
    formSubmitted,
    onChangeField,
    onSkillsListChange,
    formState: { errors },
    fields,
    certificationsArray,
    remove,
    getValues,
    rolesSubmitError,
    skillsSubmitError,
    applicantSubmitError,
  } = useRolesSkillsCreateForm();
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state?.user.profile);
  const { fields: certificationFields, append, remove: removeCertification } = certificationsArray;

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (profile) {
      profile.preferredRate && setValue('preferredRate', profile.preferredRate);
      profile.preferredRateCurrency && setValue('preferredRateCurrency', profile.preferredRateCurrency);
      profile.preferredHoursPerWeek && setValue('preferredHoursPerWeek', profile.preferredHoursPerWeek);
      profile.preferredStartDate && setValue('preferredStartDate', profile.preferredStartDate);
      profile.jobRole && setValue('recentRole', profile?.jobRole?.roleId);
      if (profile.userSkills) {
        const existingSkills = getValues('skills'); 
        const newSkills = profile.userSkills.map((skills) => skills.skillId)
                            .filter(i => !existingSkills.includes(i));
        onSkillsListChange([...existingSkills, ...newSkills]);
        const skillProficiencies = getValues('skillLevel');
        skillProficiencies.map((item, index) => {
          const existingSkillProficiency = profile?.userSkills?.find(i => i.skillId === item.skillId);
          if (existingSkillProficiency) {
            setValue(
              `skillLevel.${index}.proficiency`,
              existingSkillProficiency.proficiency as number
            );
          }
        })
      }
    }
  }, [profile, roleOptions, skillOptions])

  const preferredRateCurrency = getValues('preferredRateCurrency');
  const currencyUnit = currencyList.find(i => i.value === preferredRateCurrency)?.unit || "£";

  const removeSkillProficiency = async (id: number) => {
    try {
      await apiClient.delete(`/skill-proficiency/${id}`);
    } catch (error) {}
    finally {
      await dispatch(getProfile());
    }
  }

  const onAddCert = () => {
    append({
      certificate_name: '',
      certificate_number: '',
      certificate_acquired_date: '',
      certificate_expire_date: '',
    })
  }

  const onRemoveCert = (certficationIndex: number) => {
    removeCertification(certficationIndex);
  }

  const handleRemoveSkill = (
    item: FieldArrayWithId<RolesFormType, 'skillLevel', 'id'>,
    index: number
  ) => {
    remove(index);
    const newSkills = getValues('skills').filter(
      (skillId) => Number(item.skillId) !== skillId
    );
    const skillProficiencyId = profile?.userSkills?.find(i => i.skillId === item.skillId)?.id;
    if (skillProficiencyId) {
      removeSkillProficiency(skillProficiencyId)
    }
    onSkillsListChange(newSkills);
  };

  if (formSubmitted) {
    return <Navigate to="/dashboard" replace />;
  }
  if (formLoading) {
    return <RolesFormLoadingSkeleton />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-y-7 -mt-3"
    >
      <div>
        <SectionLabel title="Field of work" />
        <HeadingText text={`What's your field of work?`} />
        <ErrorValidationText text={errors.preferredFieldOfWork?.message} />
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
          {fieldOfWorkOptions.map((option) => (
            <RadioCard
              key={option.value}
              icon={option.icon}
              title={option.title}
              value={option.value}
              register={register}
              name="preferredFieldOfWork"
              className="w-full"
              defaultChecked={profile?.preferredFieldOfWork === option.value || false}
            />
          ))}
        </div>
      </div>
      <div className="">
        <SectionLabel title="Select your most recent role" />
        <ErrorValidationText text={errors.recentRole?.message} />
        <Controller
          name="recentRole"
          control={control}
          render={({ field: { ref, ...props } }) => (
            <Select
              {...props}
              className="w-full"
              id="recent-role-select"
              options={roleOptions}
              onChange={(value: SelectChangeEvent<unknown>) =>
                onRoleChange(value.target.value as number)
              }
            />
          )}
        />
      </div>
      <div>
        <SectionLabel title="LIST A FEW OF YOUR SKILLS FROM YOUR ROLE ABOVE" />
        <ErrorValidationText text={errors.skills?.message} />
        <Controller
          name="skills"
          control={control}
          render={({ field: { ref } }) => (
            <CustomAutocomplete
              onChange={(_, values: any, action, details: any) => {
                if (action === 'removeOption') {
                  const optionIndex = fields.findIndex(i => i.skillId === details?.option?.value);
                  handleRemoveSkill(fields[optionIndex], optionIndex)
                  remove(optionIndex)
                }
                onSkillsListChange(values.map((skills) => skills.value))
              }}
              multiple
              size="small"
              options={skillOptions}
              value={skillOptions.filter((skillOption) =>
                getValues('skills').some((skill) => skillOption.value === skill)
              )}
              getOptionLabel={(option: any) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="" placeholder="Skills..." />
              )}
              renderOption={(props, option: any) => (
                <Box
                  component="li"
                  {...props} sx={{ color: theme.colors.DarkBlue, fontSize: '14px', fontFamily: 'Poppins-Bold'}}
                >
                  {option.label}
                </Box>
              )}
            />
          )}
        />
      </div>
      {fields.length > 0 && (
        <div>
          <SectionLabel title="Skill Level" />
          <HeadingText
            text={`What is your skill level for each of these skills?`}
          />
          <div className="mt-5 flex flex-col gap-5">
            {fields.map((item, index) => (
              <div
                key={item.id}
                className="flex flex-col md:grid md:grid-cols-4 justify-center md:items-center border-gray-1 border rounded p-2 pr-10"
              >
                <div>
                  <Chip
                    label={
                      skillOptions.filter(
                        (skill) => Number(skill.value) === Number(item.skillId)
                      )?.[0]?.label ?? ''
                    }
                    className="w-auto"
                    onCloseClick={() => handleRemoveSkill(item, index)}
                  />
                </div>
                <div className="col-span-3">
                  <Controller
                    key={item.skillId}
                    control={control}
                    name={`skillLevel.${index}`}
                    defaultValue={getValues(`skillLevel.${index}`)}
                    render={({ field: { ref, onChange, ...rest } }) => (
                      <Slider
                        onChangeCapture={(e) => console.log(e)}
                        onChange={(e, val) => {
                          setValue(
                            `skillLevel.${index}.proficiency`,
                            val as number
                          )
                        }}
                        defaultValue={rest.value.proficiency}
                        className="flex-1 "
                        min={1}
                        max={100}
                        marks={skillMarks}
                      />
                    )}
                  />
                  <div className="flex justify-between text-xs -mt-2">
                    <p className="font-poppins-regular text-[15px] text-primary-blue">Beginner</p>
                    <p className="font-poppins-regular text-[15px] text-primary-blue">Intermediate</p>
                    <p className="font-poppins-regular text-[15px] text-primary-blue">Expert</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {process.env.REACT_APP_CERTIFIED_ENABLED === 'true' ? (
        <div>
          <SectionLabel title="certified skills" />
          <HeadingText text="Please provide details of any certifications or qualifications relevant to your role" />
          <ErrorValidationText text={errors.preferredStartDate?.message} />
          <div className='flex flex-col'>
            {certificationFields.map((field, index) => {
              return (
                <div className='flex flex-col gap-4 mt-4 relative' key={field.id}>
                  {index > 0 ? (
                    <span className='absolute right-0 top-0 cursor-pointer'
                      onClick={() => onRemoveCert(index)}
                    >
                      <CloseIcon1 />
                    </span>
                  ) : null}
                  <Input
                    label='Certificate Name'
                    name={`certifications.${index}.certificate_name`}
                    register={register}
                  />
                  <div className='flex flex-col md:flex-row gap-4'>
                    <Input
                      label='Certificate Number'
                      name={`certifications.${index}.certificate_number`}
                      register={register}
                      type={'number'}
                    />
                    <div className='flex flex-row gap-4 min-w-[67%]'>
                      <Input
                        label='Acquired date'
                        errors={errors}
                        name={`certifications.${index}.certificate_acquired_date`}
                        register={register}
                        placeholder='DD/MM/YYYY'
                      />
                      <div className='flex flex-col gap-1 min-w-[50%]'>
                        <div className='flex flex-row'>
                          <SectionLabel title="expiration date" />
                          <p className="text-[12px] leading-[18px] font-normal text-gray-2">(Optional)</p>
                        </div>
                        <Input
                          name={`certifications.${index}.certificate_expiration_date`}
                          register={register}
                          placeholder='DD/MM/YYYY'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <p className='font-poppins font-medium text-[14px] leading-5 text-marine-500 mt-5 cursor-pointer inline-block' onClick={onAddCert}>Add More</p>
        </div>
      ) : null}
      <div>
        <SectionLabel title="availability" />
        <HeadingText text="How soon can you start?" />
        <ErrorValidationText text={errors.preferredStartDate?.message} />
        <div className="grid grid-cols-2 md:flex gap-4 mt-4">
          {availabilityLists.map((option) => (
            <RadioCard
              key={option.value}
              title={option.title}
              name="preferredStartDate"
              value={option.value}
              register={register}
              className="w-full min-h-[56px] flex items-center"
              defaultChecked={moment(option.value, 'YYYY-MM-DD').isSame(moment(profile?.preferredStartDate, 'YYYY-MM-DD'))}
            />
          ))}
           <Controller
            control={control}
            name="preferredStartDate"
            render={({ field: { ref, ...props } }) => (
              <div onClick={() => { !open && setOpen(!open) }} className='relative cursor-pointer w-full'>
                <DatePicker
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  value={profile?.preferredStartDate ? moment(profile?.preferredStartDate) : null}
                  format='DD MMMM YYYY'
                  onChange={(newValue: any) => onPreferredStartDateChange(newValue)}
                  slotProps={{
                    textField: { placeholder: 'Pick a date', disabled: true}
                  }}
                  sx={{
                    width: '100%',
                    '.MuiInputBase-root': {borderRadius: '8px', width: '150px', minWidth: '100%', height: '56px'},
                    '.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {borderColor: '#144FE7 !important'},
                    '.MuiInputBase-input': {fontSize: '14px', color: '#080078', fontFamily: 'Poppins-Medium'},
                    '.MuiInputBase-input::placeholder': {fontSize: '14px', opacity: 1, color: '#080078 !important', fontFamily: 'Poppins-Medium'},
                    '.MuiInputBase-input.Mui-disabled': {fontSize: '14px', opacity: 1, '-webkit-text-fill-color': '#080078', fontFamily: 'Poppins-Medium'},
                    '.MuiInputAdornment-root': {display: 'none'},
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
      <div>
        <SectionLabel title="notice period" />
        <HeadingText text="How long is your notice period?" />
        <ErrorValidationText text={errors.preferredNoticePeriod?.message} />
        <div className="grid grid-cols-2 md:flex gap-4 mt-4">
          {noticePeriodLists.map((option) => (
            <RadioCard
              key={option.value}
              title={option.title}
              name="preferredNoticePeriod"
              value={option.value}
              register={register}
              className="w-full min-h-[56px] w-auto flex items-center overflow-hidden"
              defaultChecked={profile?.preferredNoticePeriod === option.value || false}
            />
          ))}
        </div>
      </div>
      <div>
        <SectionLabel title="HOURS PER WEEK" />
        <HeadingText text="How many hours per week would you be looking for?" />
        <div className="w-full mt-4 px-2 h-[108px] flex justify-center items-center gap-4 border-gray-1 border rounded">
          <p className="font-poppins-regular text-[15px] text-primary-blue">8 hrs</p>
          <Controller
            control={control}
            name="preferredHoursPerWeek"
            defaultValue={8}
            render={({ field: { ref, ...props } }) => (
              <Slider
                {...props}
                className="flex-1"
                min={8}
                max={40}
                valueLabelDisplay="on"
                valueLabelFormat={(number) => <p>{number} hrs</p>}
              />
            )}
          />
          <p className="font-poppins-regular text-[15px] text-primary-blue">40 hrs</p>
        </div>
      </div>
      <div>
        <SectionLabel title="LENGTH OF CONTRACT" />
        <HeadingText text="What length of contract best suits you?" />
        <ErrorValidationText text={errors.preferredContractLength?.message} />
        <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-4">
          {contractLengthList.map((field) => (
            <RadioCard
              key={field.value}
              title={field.title}
              value={field.value}
              register={register}
              name="preferredContractLength"
              className="w-full min-h-[56px] flex items-center"
              defaultChecked={profile?.preferredContractLength === field.value || false}
            />
          ))}
        </div>
      </div>
      <div>
        <SectionLabel title="expected rate" />
        <HeadingText text="What is your expected day rate?" />
        <div className="w-full mt-4 px-2 md:px-10 min-h-[108px] p-6 flex flex-col justify-center items-center gap-2 border-gray-1 border rounded">
          <div className="flex justify-center items-center mt-4 w-full">
            <p className="font-poppins-regular text-[15px] text-primary-blue">{currencyUnit}100</p>
            <div className="flex-1 mx-1">
              <Controller
                control={control}
                name="preferredRate"
                defaultValue={100}
                render={({ field: { ref, ...props } }) => (
                  <Slider
                    {...props}
                    className="flex-1"
                    min={100}
                    max={1500}
                    valueLabelDisplay="on"
                    valueLabelFormat={(number) => <p>{currencyUnit}{number}</p>}
                  />
                )}
              />
            </div>
            <p className="font-poppins-regular text-[15px] text-primary-blue">{currencyUnit}1500</p>
          </div>
          <div className='flex flex-row jusitfy-center gap-4 items-center'>
              {currencyList.map((field) => (
                <RadioCard
                  key={field.value}
                  title={field.title}
                  value={field.value}
                  name="preferredRateCurrency"
                  onChange={event => {
                    onChangeField(event.target.name, field.value)
                }}
                  className="w-full min-h-[56px] min-w-[72px] flex items-center"
                  defaultChecked={profile?.preferredRateCurrency === field.value || false}
                />
              ))}
            <ErrorValidationText text={errors.preferredRateCurrency?.message} />
          </div>
        </div>
      </div>
      <div>
        <ErrorValidationText
          text={rolesSubmitError || skillsSubmitError || applicantSubmitError}
        />
      </div>
      <Button
        text="Save & Continue"
        className="mx-auto"
        type="submit"
        // disabled={formSubmitting}
        isLoading={formSubmitting}
      />
    </form>
  );
};

export default RolesForms;
