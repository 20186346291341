import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';
import CookieIcon from 'assets/icons/CookieIcon';

function CookieConsentComponent() {
    const [cookies, setCookie] = useCookies(['CookieConsent']);
    const [open, setOpen] = React.useState(cookies.CookieConsent !== 'true' && cookies.CookieConsent !== 'false');

    const handleClose = () => {
      setOpen(false);
    };

    const onAccept = () => {
        var expireDate = new Date();
        expireDate.setDate((new Date()).getDate() + 365);
        setCookie('CookieConsent', 'true', { path: '/', expires: expireDate });
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY
        }
        TagManager.initialize(tagManagerArgs);

        handleClose();
    }
    const onDisagree = () => {
        handleClose();
        setCookie('CookieConsent', 'false', { path: '/' });
    }

    return (
        <>
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <div className="w-screen min-h-[144px] fixed left-0 bottom-0 p-4 max-w-[100%] fixed left-0 bottom-0 bg-white opacity-1 flex-col lg:flex-row z-10000 flex justify-around items-center">
                    <div className="max-w-[100%] p-4">
                        <div className="flex flex-row items-center gap-4">
                            <CookieIcon />
                            <p className="font-poppins-bold text-[20px] sm:text-[24px] text-primary-blue">We value your privacy</p>
                        </div>
                        <p className="font-poppins-regular mt-4 text-[14px] text-primary-blue whitespace-pre-line">We use cookies to enhance your browsing experience, serve personalised ads or content, and analyse our traffic.{'\n'}By clicking "Opt-In", you are consenting to our use of cookies</p>
                    </div>
                    <div className="flex gap-8">
                        <button className="font-poppins-bold text-[16px] text-light-blue bg-white border-2 rounded-full w-[104px] md:w-[153px] h-[48px] border-light-blue" onClick={onDisagree}>OPT-OUT</button>
                        <button className="font-poppins-bold text-[16px] text-white bg-light-blue border-2 rounded-full w-[104px] md:w-[153px] h-[48px] border-light-blue" onClick={onAccept}>OPT-IN</button>
                    </div>
                </div>
            </Backdrop>
        </>
    );
}

export default CookieConsentComponent;
