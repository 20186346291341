import { AxiosInstance } from 'axios';
import { CreateInstance } from './axios';

export default class SphereApiInvoker {
  private readonly http: AxiosInstance;

  constructor() {
    this.http = CreateInstance(process.env.REACT_APP_SPHERE_API_BASE_URL);
  }
  
  async Fetch(path: string) {
    try {
      const result = await this.http.get(path);
      return result.data;
    } catch (error: any) {
      console.log(error);
      return error.response.data;
    }
  }
  
  public async Post(path: string, payload: any) {
    try {
      const result = await this.http.post(path, payload);
      return result.data;
    } catch (error: any) {
      console.log(error);
      return error.response.data;
    }
  }

  public async Put(path: string, payload: any) {
    return await this.http.put(path, payload);
  }
  
  public async PostFormData(path: string, formData: any) {
      const result = await this.http.post(path, formData, { headers: { 'content-type': 'multipart/form-data' } });
      return result.data;
  }
}