import Button from 'components/Button';
import { ModalCloseIcon, DownloadIcon } from 'assets';
import OpenIcon from 'assets/icons/OpenIcon';
import CommunityAgreement from 'pages/dashboard/CommunityAgreement';
import { PDFFile } from 'assets';

export interface InitialAgreementProps {
    onClose: () => void;
    onAccept: () => void;
    onDecline: () => void;
}

export default function(props: InitialAgreementProps) {
    const { onClose, onDecline, onAccept } = props;


    return (
        <div className='flex flex-col gap-4'>
            <div className='flex flex-row gap-6 justify-between'>
                <p className="text-cool-900 font-normal leading-6 font-poppins text-[18px]">Updated Community Member Agreement</p>
                <span className='-mt-2 -mr-2 cursor-pointer' onClick={onClose}><ModalCloseIcon /></span>
            </div>
            <p className="text-cool-700 font-normal leading-5 font-poppins text-[14px]">
                We have recently updated our Distributed Community Member Agreement (CMA) that outlines the terms and conditions for participation in the Distributed Elastic Team Online Community. This agreement provides a framework for creating a positive and respectful environment for all members.
            </p>

            <div className='w-full h-[312px] p-6 overflow-auto border border-grey-400 rounded-lg bg-grey-50 outline-0'>
                <CommunityAgreement />
            </div>
            <div className='flex flex-col md:flex-row gap-x-6 gap-y-4'>
                <a href="/user/community_agreement" target='_blank' className='flex flex-row gap-2 items-center cursor-pointer'>
                    <OpenIcon />
                    <p className="text-marine-400 font-normal leading-5 font-poppins text-[14px]">Open in new browser window</p></a>
       
                <a className='flex flex-row gap-2 items-center cursor-pointer' href={PDFFile}
                    download="Distributed - Member Agreement"
                    target="_blank"
                    rel="noreferrer"><DownloadIcon /><p className='text-marine-400 font-normal leading-5 font-poppins text-[14px]'>Download PDF</p></a>
            </div>
            <p className="text-cool-700 font-normal leading-5 font-poppins text-[14px] pt-2">
                If you have any questions about the new Community Member Agreement, please don't hesitate to contact us at <a href="mailto:community@distributed.com" className="underline underline-offset-1">community@distributed.com</a>. We are here to help and want to ensure you feel comfortable about the new agreement.
            </p>
            <div className='flex flex-col md:flex-row justify-end gap-4 items-center pt-4'>
                <Button
                    label="Decline"
                    className='w-full md:w-auto !bg-error-500 hover:!bg-error-600 active:!bg-error-800 !text-[white] !text-[14px] !px-6 !py-2 !h-10'
                    type="submit"
                    onClick={onDecline}
                />
                <Button
                    label="Confirm Acceptance"
                    className='w-full md:w-auto !bg-forest-500 hover:!bg-forest-600 active:!bg-forest-800 !text-[white] !text-[14px] !px-6 !py-2 !h-10'
                    type="submit"
                    onClick={onAccept}
                />
            </div>
        </div>
    )
  }