export const contractLengthList = [
  {
    value: 1,
    title: '1 Month',
  },
  {
    value: 3,
    title: '3 Month',
  },
  {
    value: 6,
    title: '6 Month',
  },
  {
    value: 12,
    title: '12 Month',
  },
  {
    value: 0,
    title: 'Any Length',
  },
];
