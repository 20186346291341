import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
  '& .MuiCircularProgress-circle': {
    color: '#FF007A !important',
  },
}));

function LoadingBar() {
  return (
    <div className="h-screen w-screen fixed z-[100] left-0 top-0 bg-[#0000004d] flex justify-center items-center min-h-[100%]">
      <CustomCircularProgress color="primary" />
    </div>
  );
}

export default LoadingBar;