import * as React from 'react';
import LinearProgress, { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 20,
  width: 310,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#1B1464',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 20,
    backgroundColor: '#FF007A',
  },
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, error?: string }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
      <Box>
        <BorderLinearProgress variant="determinate" {...props} value={props.error ? 100 : props.value} />
      </Box>
      <div style={{ position: 'absolute', left: props.error ? 110 : (props.value === 100 ? 83 : 130), width: 180}} className='flex flex-between'>
        <p className='text-white font-poppoins-light text-[10px] whitespace-nowrap'>{!!props.error ? 'Oops! CV upload failed.' : (props.value === 100 ? 'Success, CV upload complete!' : 'Uploading CV')}</p>
        {(props.value === 100 || props.error) ? null : <p className='text-white font-poppoins-light text-[10px] pl-[80px]'>{`${Math.round(
          props.value,
        )}%`}</p>}
      </div>
    </Box>
  );
}

const ProgressBar = (props) => {
  const [progress, setProgress] = React.useState(10);
  const [timerId, setTimerId] = React.useState<any>(null)

  React.useEffect(() => {
    if (!!props.finished) {
      setProgress(100);
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [props.finished]);

  React.useEffect(() => {
    if (!!props.error) {
      setProgress(0);
      clearInterval(timerId)
    }
  }, [props.error]);

  React.useEffect(() => {
    setProgress(10);
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress == 99 ? prevProgress : prevProgress + 1));
    }, 150);
    setTimerId(timer)
    return () => {
      clearInterval(timer);
    };
  }, [props.uploadedFiles]);
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} error={props.error} />
    </Box>
  );
}

export default ProgressBar