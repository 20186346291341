const className = {
  base: `p-2 pr-10 inline-block md:p-4 md:pr-4 border border-gray-1 rounded-lg`,
  variants: {
    primary: `text-gray-2
        peer-checked:border-light-blue
        peer-checked:bg-blue-overlay
        peer-checked:child:text-light-blue
        peer-checked:text-light-blue`,
  },
};

export default className;
