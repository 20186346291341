export const theme = {
  screens: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1920px',
  },
  colors: {
    Pink: '#FF007A',
    Blue: '#144FE7',
    DarkGrey: '#9B9FA1',
    BlueDark: "rgba(20, 79, 231, 0.08)",
    DarkBlue: '#080078',
    ChipLabel: '#555E6D',
    ChipIconColor: '#707A89',
    Grey: '#EAEAEA',
    Navy: '#26003F',
    Yellow: '#FFDE6B',
    Red: '#FF0000',
    Green: '#00916C',
    White: '#FFFFFF',
    Black: '#000000',
  },
};
