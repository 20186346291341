import { Link } from 'react-router-dom';
import { LogoIcon } from '../assets';

export const Header = (props) => {
  const { signIn='true'} = props;
  return (
    <div className="h-[68px] md:h-[56px] bg-white border-b-2 border-[#F0F3F7] flex justify-between items-center px-5 md:px-8 relative">
      <img
        alt="distributed-logo"
        className="w-[133px] h-6"
        src={LogoIcon}
      />
      {signIn === 'true' && (
        <Link to={'/login'}>
          <button className='font-poppins-regular font-semibold text-[12px] text-primary-pink px-2 py-1 border rounded-full border-primary-pink'>
            SIGN IN
          </button>
        </Link>
      )}
    </div>
  );
};
