import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  text-align: center;
  // overflow: hidden;
`;

const Title = styled.p`
  font-size: 24px;
  font-family: Poppins-ExtraBold;
  color: #080078;
  margin-top: 4rem;
  margin-bottom: 3rem;
  @media (max-width: 1400px) {
    margin-top: 9rem;
  }
  @media (max-width: 1024px) {
    margin-top: 5rem;
  }
  @media (max-width: 720px) {
    font-size: 28px;
    margin-bottom: 1rem;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-family: Poppins-Light;
  color: #080078;
  & p {
    font-size: 16px;
    font-family: Poppins-Light;
    color: #080078;
  }
  & img {
    margin-left: 5px;
  }
  @media (max-width: 720px) {
    flex-direction: row-reverse;
    color: #080078;
    font-size: 14px;
    font-family: Poppins-Bold;
    & p {
      color: #080078;
      font-size: 14px;
      font-family: Poppins-Bold;
    }
    & img {
      margin-right: 5px;
    }
  }
`;

const DropContainer = styled.div`
  width: auto;
  height: 107px;
  min-width: 107px;
  min-height: 107px;
  border: 1px solid #e3e4e4;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-family: Poppins-Bold;
  color: #818688;
  cursor: pointer;
  align-items: center;
  padding: 0.5rem;
  margin-top: 2rem;
  & img {
    margin-top: 0.5rem;
  }
`;

const DragDropDescription = styled.p`
  font-size: 14px;
  font-family: Poppins-Medium;
  color: #818688;
  margin: 1rem 0;
`;

const ContinueButton = styled.button`
  width: 140px;
  height: 42px;
  color: white;
  background: #144fe7;
  border-radius: 20px;
  border: 0;
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 13px;
  font-family: Poppins-Bold;
  &:disabled {
    opacity: 0.6;
  }
  @media (max-width: 720px) {
    font-size: 16px;
    width: 336px;
    max-width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 730px;
  max-width: 100%;
  padding: 1rem;
  row-gap: 1rem;
  box-sizing: border-box;
  column-gap: 1rem;
`;

const Error = styled.p`
  color: #ff007a;
  font-size: 14px;
  font-family: Poppins-Medium;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 15px;
  font-family: Poppins-Regular;
  text-align: center;
  width: 100%;
`;

const InformationImage = styled.img`
  cursor: pointer;
  width: 20.17px;
  height: 20.17px;
  object-fit: contain;
  @media (max-width: 720px) {
    width: 16.17px;
    height: 16.17px;
  }
`;

const Styled = {
  Title,
  Description,
  DropContainer,
  Container,
  DragDropDescription,
  ContinueButton,
  Form,
  Error,
  ErrorText,
  InformationImage,
};

export default Styled;
