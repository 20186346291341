import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { createGlobalState } from 'react-hooks-global-state';

interface GlobalState {
  utmSource?: string | undefined;
  utmId?: string | undefined;
}

export const { useGlobalState } = createGlobalState({
  utmSource: undefined,
  utmId: undefined,
} as GlobalState);

export function Utm(): JSX.Element {
  let [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();

  const [utmSource, setUtmSource] = useGlobalState("utmSource");
  const [utmId, setUtmId] = useGlobalState("utmId");

  useEffect(() => {
    setUtmSource(searchParams.get('utm_source') ?? utmSource);
    setUtmId(searchParams.get('utm_id') ?? utmId);
  }, [location]);

  return <></>;
}
