import React from 'react';

interface ErrorValidationTextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  text?: string;
}

const ErrorValidationText: React.FC<ErrorValidationTextProps> = (props) => {
  return <p className="text-red text-xs">{props.text ?? ''}</p>;
};

export default ErrorValidationText;
