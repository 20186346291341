import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UserInitialState, CodeQuiz } from '../../types';
import {
  getUserCV,
  saveProfile,
  getBlockStatus,
  getQuizLanguages,
  submitAnswer,
  getRandomQuiz,
  getProfile,
  updateProfile,
} from '../../api/services/user';

const initialState: UserInitialState = {
  isAuthLoading: false,
  isEngineer: true,
  loading: false,
  isGetProfileLoading: false,
  isCVLoading: false,
  isGetProfileSucceed: false,
  verifiedUser: false,
  isSaveProfileSucceed: false,
  isUpdateProfileSucceed: false,
  quizLanguageLoading: false,
  quizLoading: false,
  error: '',
  cvData: null,
  profile: undefined,
  isBlocked: false,
  testResult: undefined,
  quizLanguages: [],
  selectedQuiz: undefined,
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEngineerStatus: (state, action) => {
      state.isEngineer = action.payload.isEngineer;
    },
    resetStatus: (state) => {
      state.isGetProfileSucceed = false;
      state.isSaveProfileSucceed = false;
      state.isUpdateProfileSucceed = false;
      state.verifiedUser = false;
      state.error = '';
    },
    setAuthLoading: (state, action) => {
      state.isAuthLoading = action.payload.loading
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserCV.pending, (state, action) => {
      state.isCVLoading = true;
    });
    builder.addCase(getUserCV.fulfilled, (state, action) => {
      state.isCVLoading = false;
      state.cvData = action.payload;
      state.error = '';
    });
    builder.addCase(getUserCV.rejected, (state, action: any) => {
      state.isCVLoading = false;
      state.error = action?.error?.message;
    });
    builder.addCase(saveProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.isSaveProfileSucceed = true;
      state.profile = action.payload;
      state.error = '';
    });
    builder.addCase(saveProfile.rejected, (state, action: any) => {
      state.loading = false;
      state.isSaveProfileSucceed = false;
      state.error = action?.error?.message;
    });
    builder.addCase(updateProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.isUpdateProfileSucceed = true;
      state.profile = action.payload;
      state.error = '';
    });
    builder.addCase(updateProfile.rejected, (state, action: any) => {
      state.loading = false;
      state.isUpdateProfileSucceed = false;
      state.error = action?.error?.message;
    });
    builder.addCase(getProfile.pending, (state, action) => {
      state.isGetProfileLoading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.isGetProfileLoading = false;
      state.isGetProfileSucceed = true;
      state.verifiedUser = true;
      state.profile = action.payload;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.isGetProfileSucceed = false;
      state.verifiedUser = true;
      state.isGetProfileLoading = false;
      state.profile = undefined;
    });
    builder.addCase(getBlockStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBlockStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.isBlocked = !!action.payload.blockedUntil;
    });
    builder.addCase(getBlockStatus.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getQuizLanguages.pending, (state, action) => {
      state.quizLanguageLoading = true;
    });
    builder.addCase(getQuizLanguages.fulfilled, (state, action) => {
      state.quizLanguageLoading = false;
      state.quizLanguages = action.payload;
    });
    builder.addCase(getQuizLanguages.rejected, (state, action) => {
      state.quizLanguageLoading = false;
    });
    builder.addCase(getRandomQuiz.pending, (state, action) => {
      state.quizLoading = true;
    });
    builder.addCase(getRandomQuiz.fulfilled, (state, action) => {
      state.quizLoading = false;
      state.selectedQuiz = action.payload;
    });
    builder.addCase(getRandomQuiz.rejected, (state, action) => {
      state.quizLoading = false;
    });
    builder.addCase(submitAnswer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(submitAnswer.fulfilled, (state, action) => {
      state.loading = false;
      state.testResult = action.payload;
    });
    builder.addCase(submitAnswer.rejected, (state, action) => {
      state.testResult = {
        message: 'Candidate not allowed',
        blockedUntil: 'blocked'
      }
      state.loading = false;
    });
  },
});

export const { resetStatus, setAuthLoading, setEngineerStatus } = userReducer.actions;

export default userReducer.reducer;
