import React, { useState, useEffect } from 'react';
import { Button } from 'components';
import { publicIpv4 } from 'public-ip';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store';
import {
  submitAnswer,
  getBlockStatus,
  getRandomQuiz,
  getQuizLanguages,
} from '../../api/services/user';
import { setEngineerStatus } from 'redux/user/userReducer';
import { Header } from '../../components/Header';
import Select from './Select';
import Styled from './code-test.styles';
import { GoBackIcon, tickIcon, closeIcon, backgroundImage } from '../../assets';
import ImageComponent from 'components/ImageComponent';
import ByeModal from './ByeModal';
import { Footer } from '../../components/Footer';
import './styles.css';
import QuizLoading from './partials/QuizLoading';
import ElasticTeamTM from 'components/ElasticTeamTM';

function CodeTest() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const codeQuizLanguages = useAppSelector(
    (state) => state.user.quizLanguages || []
  );
  const selectedCodeQuiz = useAppSelector((state) => state.user.selectedQuiz);
  const isBlockedUser = useAppSelector((state) => state.user.isBlocked);
  const testResult = useAppSelector((state) => state.user.testResult);
  const isLoading = useAppSelector((state) => state.user.loading);

  const [isEngineer, setIsEngineer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [finalAnswer, setFinalAnswer] = useState('');
  const [open, setOpen] = React.useState(false);
  const [language, changeLanguage] = useState(undefined);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [deviceId, setDeviceId] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [quizLanguages, setQuizLanguages] = useState([]);
  const [isQuizLanguageLoading, setQuizLanguageLoading] = useState(true);
  const [isRandomQuizLoading, setRandomQuizLoading] = useState(true);

  const answers = selectedCodeQuiz ? selectedCodeQuiz.answers : [];

  const getIpAddress = async () => {
    const ip = await publicIpv4();
    if (ip) {
      setIpAddress(ip);
    }
  };

  const initialize = () => {
    getIpAddress();
    setQuizLanguageLoading(true);
    dispatch(getQuizLanguages())
      .unwrap()
      .finally(() => {
        setQuizLanguageLoading(false);
      })
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (isBlockedUser) {
      setOpen(true);
    }
  }, [isBlockedUser]);

  useEffect(() => {
    if (codeQuizLanguages[0]) {
      setRandomQuizLoading(true);
      dispatch(getRandomQuiz(codeQuizLanguages[0]?.id))
        .unwrap()
        .finally(() => {
          setRandomQuizLoading(false);
        });
    }
  }, [codeQuizLanguages]);

  useEffect(() => {
    if (ipAddress) {
      dispatch(getBlockStatus(ipAddress));
    }
  }, [ipAddress]);

  useEffect(() => {
    if (testResult) {
      if (testResult?.passed) {
        setTimeout(() => {
          navigate('/signup');
        }, 1000);
      }
      if (testResult?.blockedUntil) {
        setOpen(true);
      }
    }
  }, [isLoading, testResult]);

  const isNotEngineer = () => {
    dispatch(setEngineerStatus({ isEngineer }));
    navigate('/signup');
  };

  const onChangeLanguage = (e) => {
    changeLanguage(e.target.value);
    setRandomQuizLoading(true);
    dispatch(getRandomQuiz(e.target.value))
      .unwrap()
      .finally(() => {
        setRandomQuizLoading(false);
      });
  };

  const onSubmit = () => {
    if (selectedCodeQuiz) {
      const answer = {
        codeQuizId: selectedCodeQuiz.id,
        answer: selectedAnswer,
        deviceId: deviceId,
        ipAddress: ipAddress,
      };
      dispatch(submitAnswer(answer));
    }
  };

  const onSelectAnswer = (answer: string) => {
    if (finalAnswer || canContinue()) return;
    setIsEngineer(true);
    setSelectedAnswer(answer);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onContinue = () => {
    setIsSubmitted(true);
    onSubmit();
  };

  const canContinue = () => !isBlockedUser && isSubmitted;

  const getAnswerIcon = () => {
    if (!testResult) return null;
    if (testResult.passed) {
      return (
        <img
          src={tickIcon}
          width="16px"
          height="16px"
          className='ml-1 max-w-4 max-h-4'
        />
      );
    } else {
      return (
        <img
          src={closeIcon}
          width="16px"
          height="16px"
          className='ml-2 max-w-4 max-h-4'
        />
      );
    }
  };

  const onGoBack = () => {
    dispatch(getBlockStatus(ipAddress));
    setFinalAnswer('');
    setOpen(false);
    setSelectedAnswer('');
    setIsSubmitted(false);
    setIsEngineer(false);
  };
  const isSelectedAnswer = (answer) => selectedAnswer === answer;
  return (
    <Styled.Wrapper>
      <Header signIn={'false'}/>
      <div className="test-container">
        <div className="bg-container">
          <ImageComponent
            src={backgroundImage}
            style={{
              width: '100%',
              minHeight: '100%',
              height: 'calc(100vh - 56px)',
              objectFit: 'cover',
            }}
          />
        </div>
        <div className="code-container">
          {(isQuizLanguageLoading || isRandomQuizLoading) ? (
            <QuizLoading />
          ) : (
            <>
              <Styled.HeaderTextContainer>
                <Styled.HeaderTitle>
                  Before you join the{' '}
                  <ElasticTeamTM tradeMarkStyle="font-normal" />, can you solve
                  this puzzle?
                </Styled.HeaderTitle>
              </Styled.HeaderTextContainer>
              <pre className='container'>
                <Select
                  style={{ marginBottom: '1rem' }}
                  value={language}
                  onChange={onChangeLanguage}
                  options={codeQuizLanguages.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                />
                  <SyntaxHighlighter
                    showLineNumbers
                    wrapLines 

                    language={
                      codeQuizLanguages.find((item) => item.id === language)
                        ?.name?.toLowerCase() || 'javascript'
                    }
                    customStyle={{ background: 'black', height: '228px', overflow: 'auto'}}
                    style={darcula}>
                      
                      
                  {selectedCodeQuiz?.codeBlock.replace('What', '// What') || ''}
                </SyntaxHighlighter>
              </pre>
              <div style={{ marginTop: '2rem', width: '100%' }}>
                <Styled.AnswerTitle>Choose your answer</Styled.AnswerTitle>
                <div className="answer-container">
                  {answers.map((answer, index) => (
                    <Button
                      className={
                        isSelectedAnswer(answer)
                          ? 'answer-btn selected-answer items-center'
                          : 'answer-btn items-center'
                      }
                      key={index}
                      onClick={() => onSelectAnswer(answer)}
                    >
                      {answer}{' '}
                      {isSubmitted &&
                        isSelectedAnswer(answer) &&
                        getAnswerIcon()}
                    </Button>
                  ))}
                </div>
                {!isSubmitted && (
                  <div className='p-4 flex justify-center items-center'>
                    {isEngineer ? (
                      <Button
                        className="continue-btn"
                        onClick={onContinue}
                        disabled={isBlockedUser}
                      >
                        CONTINUE
                      </Button>
                    ) : (
                      <a className="not-engineer-btn" onClick={isNotEngineer}>
                        I'm not an engineer
                      </a>
                    )}
                  </div>
                )}
                {isSubmitted && (
                  <Styled.GoBackButton onClick={onGoBack}>
                    <img
                      src={GoBackIcon}
                      height="42px"
                      style={{ marginRight: '1rem' }}
                    />
                    Go back
                  </Styled.GoBackButton>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      <ByeModal open={open} handleClose={handleClose} />
    </Styled.Wrapper>
  );
}

export default CodeTest;
