import React from 'react';

const DesignIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        fill="currentColor"
        d="M.425 17.593v-3.7L4.95 9.368.725 5.143A1.342 1.342 0 01.4 4.631a1.6 1.6 0 010-1.113c.07-.189.181-.36.325-.5l2.05-2.05A1.547 1.547 0 013.85.518c.189.001.376.04.55.112.188.078.358.193.5.338l4.25 4.25L13.625.743a.7.7 0 01.288-.213 1.014 1.014 0 01.7 0 .706.706 0 01.287.213l2.4 2.4a.96.96 0 01.213.3.844.844 0 01.062.325c.002.124-.02.247-.062.363a.706.706 0 01-.213.287l-4.45 4.475 4.225 4.25a1.548 1.548 0 01.45 1.075c-.001.189-.04.376-.112.55a1.522 1.522 0 01-.338.5l-2.05 2.05c-.14.144-.311.255-.5.325a1.599 1.599 0 01-1.112 0 1.342 1.342 0 01-.513-.325l-4.25-4.25-4.525 4.525h-3.7zm5.6-9.275l2.05-2.05-1.55-1.575L5.35 5.868l-1.075-1.05L5.45 3.643 3.825 2.018l-2.05 2.05 4.25 4.25zm7.925 7.925l2.075-2.05-1.65-1.625-1.175 1.175-1.05-1.05 1.175-1.2-1.575-1.55-2.05 2.075 4.25 4.225zm-12.025-.15H3.5l9.675-9.65-1.575-1.6-9.675 9.7v1.55zm12.3-10.725l1.6-1.6-1.575-1.55-1.575 1.575 1.55 1.575z"
      ></path>
    </svg>
  );
};

export default DesignIcon;
