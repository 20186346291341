import React, {ReactElement} from 'react'
interface FormItemProps {
  label?: string;
  labelClassName?: string;
  inputClassName?: string;
  icon?: ReactElement | null;
  placeholder?: string;
  type?: string;
  value: string;
  onChange: (value: string) => void;
}

const FormItem = (props: FormItemProps) => {
  const { label, placeholder, icon, type, onChange, value, labelClassName, inputClassName} = props;
  return (
    <div className="form-element">
      {label && <label className={labelClassName}>{label}</label>}
      <div className="relative">
        <input
          className={inputClassName}
          type={type || 'text'}
          placeholder={placeholder || ''}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <span className="absolute top-4 right-4">
          {icon ? icon : null}
        </span>
      </div>
    </div>
  );
};

export default FormItem;
