import {createAsyncThunk} from '@reduxjs/toolkit';
import {client} from '../client';
import {API_URL} from '../endpoint';
import {QuizAnswer, User} from '../../types';
import {EventType, logEvent} from "../../Context/analytics";
import { MembersService } from './members'

interface CVRequest {
  userId: string;
  cv: File;
}

export const getUserCV = createAsyncThunk(
  'users/cv_parse',
  async (data: CVRequest) => {
    const { userId, cv} = data;
    const response = await MembersService.UploadCv(userId, cv)
    logEvent(EventType.cv_uploaded);
    return response;
  }
);

export const saveProfile = createAsyncThunk(
  'user/save_profile',
  async (profile: User) => {
    const response = await client.post(API_URL + 'user/new-applicant', profile);
    logEvent(EventType.save_profile);
    return response;
  }
);

export const updateProfile = createAsyncThunk(
  'user/update_profile',
  async (profile: User) => {
    const response = await client.put(API_URL + 'user/new-applicant', profile);
    logEvent(EventType.update_profile);
    return response;
  }
);

export const getProfile = createAsyncThunk('user/get_profile', async () => {
  const response = await client.get(API_URL + 'user/new-applicant');
  return response;
});

export const submitAnswer = createAsyncThunk(
  'quiz/submit',
  async (quizAnswer: QuizAnswer) => {
    const response = await client.post(API_URL + 'quiz-attempt', quizAnswer);
    return response;
  }
);

export const checkProfile = async () => {
  try {
    const response = await client.get(API_URL + 'user/new-applicant');
    return response;
  } catch (error) {
    return false;
  }
};

export const getBlockStatus = createAsyncThunk(
  'quiz-attempt/status',
  async (identifier: string) => {
    const response = await client.get(
      API_URL + `quiz-attempt/${identifier}/allow`
    );
    return response;
  }
);

export const getRandomQuiz = createAsyncThunk(
  'quiz/get',
  async (languageId: number) => {
    const response = await client.get(
      API_URL + `code-quiz-language/${languageId}/return-random-test`
    );
    return response;
  }
);

export const getQuizLanguages = createAsyncThunk(
  'quiz-language-get',
  async () => {
    const response = await client.get(
      API_URL + `quiz-languages?page=0&limit=10`
    );
    return response.items;
  }
);

export const getQuizById = createAsyncThunk(
  'quiz-get-by-id',
  async (quizId: number) => {
    const response = await client.get(API_URL + `quiz/${quizId}`);
    return response.items;
  }
);
