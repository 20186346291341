import classNames from 'classnames';
import React from 'react';
import { RadioCardProps } from './types';
import styles from './RadioCard.styles';

const RadioCard: React.FC<RadioCardProps> = (props) => {
  const { variant = 'primary', className, register, ...rest } = props;
  const radioCardStyles = classNames(
    styles.base,
    styles.variants[variant],
    className
  );
  return (
    <label className="group relative cursor-pointer" id={`field-${props.title?.toLowerCase().replace(' ', '-')}`}>
      <input
        type="radio"
        className="group peer absolute opacity-0"
        name={props.name}
        {...rest}
        {...register?.(props?.name ?? '')}
      />
      <div className={radioCardStyles}>
        {props.icon && <div className='mb-1'>{props.icon}</div>}
        <p className="text-primary-blue select-none text-[14px] whitespace-nowrap font-poppins-medium">{props.title}</p>
      </div>
    </label>
  );
};

export default RadioCard;
