import React from 'react';

const ProductIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      fill="none"
      viewBox="0 0 20 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5.7 8.843h3.5v3.5H5.7v-3.5zm0-5.1h3.5v3.5H5.7v-3.5zm5.1 5.1h3.5v3.5h-3.5v-3.5zm0-5.1h3.5v3.5h-3.5v-3.5zm-8.5 11.8a1.736 1.736 0 01-1.275-.525A1.737 1.737 0 01.5 13.743v-11.4a1.736 1.736 0 01.525-1.275A1.736 1.736 0 012.3.543h15.4a1.736 1.736 0 011.275.525 1.735 1.735 0 01.525 1.275v11.4a1.794 1.794 0 01-1.8 1.8H2.3zm0-1.5h15.4a.354.354 0 00.3-.3v-11.4a.288.288 0 00-.1-.2.289.289 0 00-.2-.1H2.3a.288.288 0 00-.2.1.288.288 0 00-.1.2v11.4a.288.288 0 00.1.2.288.288 0 00.2.1z"
      ></path>
    </svg>
  );
};

export default ProductIcon;
