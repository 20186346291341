const className = {
  base: 'px-2 rounded-3xl min-w-[144px] min-h-[42px] font-poppins-bold text-[12px] leading-[19.5px] flex items-center justify-center gap-2 transition-colors duration-500',
  variants: {
    primary: `bg-light-blue text-white`,
    secondary: `bg-primary-pink text-white`,
  },
  disabled: 'opacity-70',
};

export default className;
