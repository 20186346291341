import { CheckIcon } from '../assets';

interface CheckItemProps {
  text?: string;
}

const CheckItem = (props: CheckItemProps) => {
  const { text } = props;
  return (
    <div className="community-check-item">
      <img src={CheckIcon} />
      <p>{text}</p>
    </div>
  );
};

export default CheckItem;
