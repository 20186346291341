import SimpleDialog from '../../components/SimpleDialog';
import TermsConditions from 'pages/terms-conditions';
import { CircleCloseIcon, LogoIcon1 } from 'assets';
import Styled from './signup.styles';
export interface ModalProps {
  open: boolean;
  handleClose: (agree?: boolean) => void;
}

const TermsModal = (props: ModalProps) => {
  const { open, handleClose } = props;
  return (
    <SimpleDialog open={open} onClose={handleClose}>
      <div className='max-w-[372px] min-h-[469px] mt-4 text-center box-border p-4'>
        <Styled.DialogLogo src={LogoIcon1} alt="logo" style={{ margin: 0}}/>
        <Styled.DialogTitle style={{ marginTop: '1rem', marginBottom: '1rem'}}>Terms & Conditions</Styled.DialogTitle>
        <div className='text-left max-h-[300px] overflow-auto'>
            <TermsConditions handleClose={handleClose}/>
        </div>
      
      </div>
    </SimpleDialog>
  );
};
export default TermsModal;
