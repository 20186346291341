import React from 'react';
import { SectionTitleProps } from './type';

const SectionLabel: React.FC<SectionTitleProps> = ({ title }) => (
  <p className="uppercase text-[12px] leading-[18px] whitespace-nowrap font-bold text-gray-2">
    {title}
  </p>
);

export default SectionLabel;
