import React, { useState } from 'react';
import classnames from 'classnames';
import {
    GreyLogoIcon,
    GreyFacebookIcon,
    GreyTwitterIcon,
    GreyLinkedinIcon,
    GreyShareIcon,
} from 'assets';
import { Link } from 'react-router-dom';
import ContactUS from './ContactUS';
import TermsModal from '../pages/signup/TermsModal';

interface TextProps {
  style?: object;
  text?: string;
}

const CompanyFooter = () => {
  return (
    <div className="flex flex-row justify-between flex-wrap-reverse">
      <div className="mt-4">
        <GreyLogoIcon />
        <p className="font-poppins-regular text-[14px] text-blue-fade w-[186px] text-left mt-2">
          WSM, Connect House 133-137 Alexandra Road Wimbledon, London United
          Kingdom SW19 7JY
        </p>
      </div>
      <div className="flex flex-column sm:flex-row justify-between flex-wrap mt-4">
        <div className="mx-0 sm:mx-8">
            <Link to='/dashboard'>
            <p className="font-poppins-semibold text-[14px] text-blue-fade text-left mt-2">
                MY DASHBOARD
            </p>
          </Link>
          <Link to='/dashboard'>
            <p className="font-poppins-regular text-[14px] text-blue-fade text-left mt-2">
                My dashboard
            </p>
          </Link>
          <Link to='/new-applicant'>
            <p className="font-poppins-regular text-[14px] text-blue-fade text-left">
                Profile
            </p>
          </Link>
        </div>
        <div className="hidden md:block">
          <p className="font-poppins-semibold text-[14px] text-blue-fade text-left mt-2">
            DISTRIBUTED LINKS
          </p>
          <div className="flex flex-col">
            <a className="font-poppins-regular text-[14px] text-blue-fade text-left mt-2" href='https://www.distributed.com/' target={'_blank'}>
                Distributed.com
            </a>
            <a className="font-poppins-regular text-[14px] text-blue-fade text-left" href='https://careers.distributed.com/' target={'_blank'}>
                Open Roles
            </a>
            <a className="font-poppins-regular text-[14px] text-blue-fade text-left" href='https://www.distributed.com/privacy-policy/' target={'_blank'}>
                Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const SocialIcons = () => {
  return (
    <div className="flex flex-row justify-around pr-4 md:space-x-4 w-[100%] md:max-w-[200px] mb-4 md:mb-0">
      <a
        href="https://twitter.com/_distributed_"
        target="_blank"
        rel="noreferrer"
      >
        <GreyTwitterIcon />
      </a>
      <a
        href="https://www.linkedin.com/company/dstbtd"
        target="_blank"
        rel="noreferrer"
      >
        <GreyLinkedinIcon />
      </a>
      <a href="https://www.facebook.com/DSTBTD" target="_blank" rel="noreferrer">
        <GreyFacebookIcon />
      </a>
      <a href="" target="_blank">
        <GreyShareIcon />
      </a>
    </div>
  )
}

export const FooterNew = (props: TextProps) => {
    const dashboardElement = document.querySelector('.dashboard-screen');
    const [contactUs, setContactUs] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);
    const [isScrollable, setIsScrollable] = useState(dashboardElement ? dashboardElement.scrollHeight > dashboardElement.clientHeight : false);

    React.useEffect(() => {
      const handleWindowResize = () => {
        const hasScrollableContent = dashboardElement ? dashboardElement.scrollHeight > dashboardElement.clientHeight : false;
        setIsScrollable(hasScrollableContent)
      }
      window.addEventListener("resize", handleWindowResize);
      handleWindowResize();
      return () => window.removeEventListener("resize", handleWindowResize);
    }, [props]);

    return (
      <div className={`${classnames({
        'absolute': !isScrollable,
      })} flex items-center left-0 bottom-0 right-0 flex-col max-w-[100%] justify-center w-[100vw] mx-auto`}>

        <div className="flex flex-col gap-y-4 items-center md:flex-row justify-between md:min-w-[774px] px-4 lg:px-0 xl:min-w-[1174px] md:mt-8 mb-8 md:mt-0">
          <p className="font-poppins-regular text-center text-[12px] text-blue-fade text-left ">
            Copyright © {new Date().getFullYear()} Distributed, Inc. All Rights
            Reserved.
          </p>
          <div className="flex flex-row gap-x-6 gap-y-4 flex-wrap justify-center md:justify-end items-center mb-4 md:mb-0">
            <p className="font-poppins-regular hover:underline active:text-[#000000] text-[12px] text-blue-fade text-left cursor-pointer" onClick={() => setContactUs(true)}>
              Contact us
            </p>
            <a className="font-poppins-regular hover:underline active:text-[#000000] text-[12px] text-blue-fade text-left" href='https://www.distributed.com/privacy-policy/' target={'_blank'}>
              Privacy Policy
            </a>
            <p className="font-poppins-regular hover:underline active:text-[#000000] text-[12px] text-blue-fade text-left cursor-pointer" onClick={() => setTermsOpen(true)}>
              Terms & Conditions
            </p>
            <a className="font-poppins-regular hover:underline active:text-[#000000] text-[12px] text-blue-fade text-left" href='https://distributed.notion.site/Elastic-Team-Handbook-1b002bf73adf417db826f4ed07b3a01a' target={'_blank'}>
              Handbook
            </a>
          </div>
        </div>
        <TermsModal
          open={termsOpen}
          handleClose={() => setTermsOpen(false)}
        />
        <ContactUS
          open={contactUs}
          handleClose={() => setContactUs(false)}
        />
      </div>
    );
}
