import { Auth } from 'aws-amplify';
import { countryList } from './countryList';

export const getUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (error) {
    return false;
  }
};

export const getUserSession = async () => {
  try {
    const user = await Auth.currentSession();
    return user;
  } catch (error) {
    return false;
  }
};

export const getNationalityList = () => {
  return countryList.reduce(
    (p, c) => Object.assign(p, { [c.cca2]: c.demonyms.eng.f }),
    {}
  );
};

export const getCountryList = () => {
  return countryList
    .sort((a, b) => a.demonyms.eng.f.localeCompare(b.demonyms.eng.f))
    .map((country) => country.cca2);
}

export const scriptExists = (url: string) => {
  var scripts = document.getElementsByTagName('script');
  for (var i = scripts.length; i--;) {
    if (scripts[i].src.indexOf(url) > -1) return true;
  }
  return false;
}
