import * as React from 'react';

export const HamburgerIcon = ({
  color = 'white',
  width = '172.692',
  height = '31.088',
}) => (
  <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="6" width="24" height="2" rx="1" fill="#080078"/>
    <rect width="24" height="2" rx="1" fill="#080078"/>
    <rect y="12" width="24" height="2" rx="1" fill="#080078"/>
  </svg>
);
