import React from 'react';
import { CircularProgress } from '@mui/material';

export interface ButtonProps extends React.ButtonHTMLAttributes<any> {
    label: string;
    className?: string;
    isLoading?: boolean;
    loadingBarSize?: number;
    icon?: React.ReactElement;
    onClick?: (event: any) => void;
}

export default function (props: ButtonProps) {
    const { className, isLoading, label, onClick, loadingBarSize, ...buttonProps } = props;

    const buttonClasses = `${className} bg-marine-500 justify-center flex disabled:!bg-grey-100
     disabled:!text-grey-400 items-center gap-2 text-white rounded-full font-inter
     font-semibold text-[14px] leading-5 tracking-[.01em] min-w-[80px] px-4 py-2
    `;

    return (
        <button className={buttonClasses} onClick={props.onClick} {...buttonProps}>
            {props.icon && props.icon}
            {label}
            {isLoading && <CircularProgress color="inherit" size={loadingBarSize || 18} />}
        </button>
    );
}
