import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import InitialAgreement from './components/cma/InitialAgreement';
import ConfirmAgreement from './components/cma/ConfirmAgreement';
import DeclineAgreement from './components/cma/DeclineAgreement';
import CloseAgreement from './components/cma/CloseAgreement';
import { useState } from 'react';
import { MembersService } from 'api/services/members';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  outline: 'none',
  maxHeight: '90%',
  overflow: 'auto',
  p: 3,
};

export interface AgreementModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const agreementStatus = {
  'init': 1,
  'confirmed': 2,
  'declined': 3,
  'close': 4,
}

export default function AgreementModal(props: AgreementModalProps) {
  const { isOpen } = props;
  const [status, setStatus] = useState(agreementStatus.init);
  const matches = useMediaQuery('(max-width:600px)');

  const onClose = () => {
    props.onClose();
    setTimeout(() => {
        setStatus(agreementStatus.init);
    }, 300)
  }

  const onAccept = async () => {
    setStatus(agreementStatus.confirmed);
    try {
        await MembersService.UpdateCandidateAgreement({acceptedCommunityMemberAgreement : true});
    } catch (error) {
        console.error(error)
    }
  }

  const onDecline = () => {
    setStatus(agreementStatus.declined);
  }

  const onDeclineClose = async () => {
    setStatus(agreementStatus.close);
    try {
        await MembersService.UpdateCandidateAgreement({acceptedCommunityMemberAgreement : false});
    } catch (error) {
        console.error(error)
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      classes={{ root: '!rounded-lg'}} 
    >
      <Box sx={modalStyle} style={{ width: status === agreementStatus.init ? 'auto' : '520px', borderRadius: matches ? 0 : '8px'}}>
        {status === agreementStatus.init ? <InitialAgreement onClose={onClose} onAccept={onAccept} onDecline={onDecline} /> : null}
        {status === agreementStatus.confirmed ? <ConfirmAgreement onClose={onClose} /> : null}
        {status === agreementStatus.declined ? <DeclineAgreement onClose={onClose} onDecline={onDeclineClose} /> : null}
        {status === agreementStatus.close ? <CloseAgreement onClose={onClose} /> : null}
      </Box>
    </Modal>
  )
}