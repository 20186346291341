const ElasticTeamTM: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > & {
    tradeMarkStyle?: string;
  }
> = ({ className, tradeMarkStyle, ...rest }) => (
  <span className={`text-primary-pink ${className}`} {...rest}>
    Elastic Team
  </span>
);

export default ElasticTeamTM;
