import {
  AnswersContainer,
  AnswersSectionContainer,
  LoadingAnswer,
  LoadingQuiz,
  LoadingQuizContainer,
  LoadingText,
  LoadingTitle,
} from './styles';
import { Skeleton } from '@mui/material';
const QuizLoading = () => {
  return (
    <LoadingQuizContainer>
      <div className='flex flex-col items-center gap-y-2'>
        <LoadingText variant="rounded" animation="wave" width={400} height={20}/>
        <LoadingText variant="rounded" animation="wave" width={470} height={20}/>
        <LoadingText variant="rounded" animation="wave" width={380} height={20}/>
      </div>
      {/* <LoadingQuiz variant="rectangular" animation="wave" /> */}
      <div className="flex flex-col w-[80%] mt-4 pl-8">
        <div className='flex flex-row gap-x-4 items-center w-[100%]'>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '60%' }} />
        </div>
        <div className='flex flex-col w-[100%] ml-10'>
          <Skeleton variant="text" sx={{ fontSize: '1rem', }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem', }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem', }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem', }} />
        </div>
        <div className='flex flex-row gap-x-4 items-center w-[100%]'>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '60%' }} />
        </div>
        <div className='flex flex-col w-[100%] ml-10'>
          <Skeleton variant="text" sx={{ fontSize: '1rem', }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem', }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem', }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem', }} />
        </div>
      </div>
      <AnswersSectionContainer>
        <LoadingText variant="text" animation="wave" width={250} height={38} />
        <span className='h-84'></span>
        <AnswersContainer>
          <LoadingAnswer variant="rectangular" animation="wave" />
          <LoadingAnswer variant="rectangular" animation="wave" />
          <LoadingAnswer variant="rectangular" animation="wave" />
        </AnswersContainer>
        <LoadingAnswer variant="rectangular" animation="wave" />
        <span className='h-4'></span>
        <LoadingText variant="text" animation="wave" width={140} height={30} />
      </AnswersSectionContainer>
    </LoadingQuizContainer>
  );
};

export default QuizLoading;
