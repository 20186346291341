import classNames from 'classnames';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ButtonProps } from './types';
import styles from './Button.styles';

const Button: React.FC<ButtonProps> = (props) => {
  const { variant = 'primary', className, text, isLoading, ...rest } = props;
  const buttonStyles = classNames(
    styles.base,
    styles.variants[variant],
    props.disabled && styles.disabled,
    className
  );
  return (
    <button
      className={buttonStyles}
      {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      <div className='flex justify-center' style={{ paddingLeft: isLoading ? '28px' : 0}}>
        {text ?? props.children}
        {isLoading && <CircularProgress color="inherit" size={18} sx={{ marginLeft: '0.5rem', marginRight: '0.5rem'}} />}
      </div>
    </button>
  );
};

export default Button;
