import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from 'components/Button';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from 'react';
import { convertToRaw, ContentState } from 'draft-js';
import { EditorState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  outline: 'none',
  maxHeight: '90%',
  overflow: 'auto',
  p: 3,
};

export interface AppealModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAppeal: (html: string) => Promise<any>;
}

const defaultTemplate = `
  <p>Dear Sir/Madam,</p>
  <p>I am writing to appeal the decision to reject my request. I believe that the decision was made in error and I would like to request a review of my application.</p>
`;


export default function AppealModal(props: AppealModalProps) {
  const { isOpen, onAppeal } = props;
  const [html, setHtml] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(defaultTemplate)
      )
    ));

  const matches = useMediaQuery('(max-width:600px)');

  const onClose = () => {
    props.onClose();
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      classes={{ root: '!rounded-lg'}} 
    >
      <Box sx={modalStyle} style={{ borderRadius: matches ? 0 : '8px', height: "500px", width: "620px"}}> 
        <p className="text-cool-900 font-normal leading-6 font-poppins text-[18px] pb-2">Write a message for your request</p>
        <Editor
            editorState={html}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="appealEditor"
            onEditorStateChange={setHtml}
        />
        <div className='flex justify-end'>
            <Button
                label="Submit"
                disabled={html.getCurrentContent().hasText() === false}
                className='w-full md:w-auto !bg-forest-500 hover:!bg-forest-600 active:!bg-forest-800 !text-[white] !text-[14px] !px-6 !py-2 !h-10'
                type="submit"
                onClick={() => onAppeal(draftToHtml(convertToRaw(html.getCurrentContent())))}
            />
        </div>
      </Box>
    </Modal>
  )
}