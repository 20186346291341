import CommunityAgreement from "pages/dashboard/CommunityAgreement";
import {
  LogoIcon,
} from 'assets';

const CommunityAgreementScreen = () => {
  return (
    <div className="px-4 py-8 gap-8 flex flex-col justify-center items-center w-full">
      <div className='flex justify-start w-full max-w-[832px]'>
        <img
          src={LogoIcon}
          alt="distributed-logo"
          className="w-[133px] h-6"
        />
      </div>
      <CommunityAgreement />
    </div>
  );
};

export default CommunityAgreementScreen;
