import React from 'react';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { store } from './store';
import './App.css';
import ReactRouter from 'router';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';
import { scriptExists } from 'utils';
import ZohoChatWidget from 'components/ZohoChatWidget';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY
}

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      redirectSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGNOUT,
      scope: ['email', 'openid'],
      responseType: 'code',
      federationTarget: 'COGNITO_USER_POOLS',
    },
  },
});

const App = () => {
  const [cookies] = useCookies(['CookieConsent']);
  const src = 'https://www.googletagmanager.com/gtm.js?id=' + process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY;

  if (cookies.CookieConsent === 'true' && !scriptExists(src)) {
    TagManager.initialize(tagManagerArgs);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <Provider store={store}>
            <ReactRouter />
          </Provider>
        </CookiesProvider>
        <ZohoChatWidget />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
