import React from 'react';
import ErrorText from './ErrorText';

export interface InputProps extends React.InputHTMLAttributes<any> {
    label: string;
    className?: string;
    labelClassName?: string;
}

export default function (props: any) {
  const { register, errors, required, pattern, rules={}, validate, className, ...inputProps } = props;
  const inputClasses = `outline-0 pl-4 py-[10px] h-10 min-w-[120px] w-full
    ${(errors && errors[props.name]?.message) ? 'bg-[#FEEFEF] !border-[#EE6C6C]' : ''}
    bg-white border border-[#D9D9D9] 
    hover:border-[#B3B3B3]
    disabled:bg-[#F7F7F7] 
    placeholder:text-[#707A89] 
    focus:border-[#3168F4] 
    rounded text-cool-900 font-poppins font-normal text-[14px] leading-5 ${className}`;
  return (
    <div className='flex flex-col w-full gap-1'>
        {props.label && <label className={`uppercase whitespace-nowrap text-[12px] leading-[18px] font-bold text-gray-2 ${props.labelClassName}`}>{props.label}</label>}
        <input
            className={inputClasses}
            type='text'
            {...register?.(props?.name ?? '', {required: required ? `${props.label} is required` : false,
              validate: (value: any) => validate ? validate(value) : true,
              pattern: pattern ? pattern : false,
              ...rules
            })}
            {...inputProps}
            placeholder={props.placeholder || (props.label ?? '')}
        />
         {(errors && errors[props.name]?.message) ? <ErrorText className="mt-2" text={errors[props.name]?.message} /> : null}
    </div>
  );
}
