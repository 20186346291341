import React from 'react';

export const CloseIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.22168" y="0.807617" width="24" height="2" rx="1" transform="rotate(45 2.22168 0.807617)" fill="#080078"/>
      <rect x="19.1924" y="2.2218" width="24" height="2" rx="1" transform="rotate(135 19.1924 2.2218)" fill="#080078"/>
    </svg>
  );
};
