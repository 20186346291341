import { TRoute } from './types';
import Home from '../pages/home';
import CodeTest from '../pages/code-test';
import Signup from '../pages/signup';
import Signin from '../pages/signin';
import NewApplicant from '../pages/new-applicant';
import Dashboard from '../pages/dashboard';
import Skills from 'pages/skills';
import TermsConditions from 'pages/terms-conditions';
import CommunityAgreementScreen from 'pages/community-agreement';

export const routes: TRoute[] = [
  {
    path: '/',
    element: <Home />,
    protected: false,
  },
  {
    path: '/apply',
    element: <CodeTest />,
    protected: false,
  },
  {
    path: '/skills',
    element: <Skills />,
    protected: true,
  },
  {
    path: '/login',
    element: <Signin />,
    protected: false,
  },
  {
    path: '/signup',
    element: <Signup />,
    protected: false,
  },
  {
    path: '/new-applicant',
    element: <NewApplicant />,
    protected: true,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    protected: true,
  },
  {
    path: '/user/community_agreement',
    element: <CommunityAgreementScreen />,
    protected: true,
  },
];
