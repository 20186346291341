import React from 'react';
import { HeroContent, HeroImage } from './partials';
import { Header } from 'components/Header';

const HomeNew = () => {
  return (
    <div>
      <Header />
      <div className="flex flex-col gap-y-2 items-center md:flex-row md:h-screen md:-mt-[56px] md:pt-[56px]">
        <HeroImage />
        <HeroContent />
      </div>
    </div>
  );
};

export default HomeNew;
