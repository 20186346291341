import * as React from 'react';

export const SearchIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.619" height="22.121" viewBox="0 0 21.619 22.121">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_164" data-name="Rectangle 164" width="21.619" height="22.121" transform="translate(0 0)" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_1070" data-name="Group 1070" transform="translate(0 0)">
      <g id="Group_1069" data-name="Group 1069" clipPath="url(#clip-path)">
        <path id="Path_482" data-name="Path 482" d="M21.6,20.843l-6.894-6.894a8.379,8.379,0,1,0-1.3,1.2l6.945,6.945c1.52.24,1.252-1.252,1.252-1.252M1.284,8.4A7.117,7.117,0,1,1,8.4,15.518,7.117,7.117,0,0,1,1.284,8.4" fill="#B3B3B3"/>
      </g>
    </g>
  </svg>
);
