import SimpleDialog from '../../components/SimpleDialog';
import { ElasticText } from '../../components/ElasticText';
import { LogoIcon1, ByeIcon } from '../../assets';
import Styled from './code-test.styles';

export interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

const title = 'Sorry...';

const ByeModal = (props: ModalProps) => {
  const { open, handleClose } = props;
  return (
    <SimpleDialog open={open}>
      <Styled.DialogContainer>
        <Styled.DialogLogo src={LogoIcon1} alt="logo" />
        <Styled.DialogBody>
          <Styled.DialogTitle>{title}</Styled.DialogTitle>
          <Styled.DialogDescription>
            We don’t think your a good fit for the{' '}
            <ElasticText
              supStyle={{ fontSize: '10px', fontFamily: 'Poppins-Regular' }}
            />
            .
          </Styled.DialogDescription>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '7rem',
              paddingLeft: '1rem',
            }}
          >
            <span style={{transform: 'rotateY(0deg) rotate(-45deg)'}}>
              <ByeIcon />
            </span>
            <Styled.DialogDescription>
              But, thank you...
            </Styled.DialogDescription>
          </div>
        </Styled.DialogBody>
      </Styled.DialogContainer>
    </SimpleDialog>
  );
};
export default ByeModal;
