import React from 'react';

interface ErrorValidationTextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  text?: string;
}

const ErrorText: React.FC<ErrorValidationTextProps> = (props) => {
  return <p className={"text-[#E31515] text-[12px] font-notosans whitespace-nowrap font-normal mt-2 " + props.className}>{props.text ?? ''}</p>;
};

export default ErrorText;
