import React from 'react';

const OtherRoleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2 15.668a1.566 1.566 0 01-1.15-.475 1.63 1.63 0 010-2.3A1.565 1.565 0 012 12.418a1.566 1.566 0 011.15.475 1.63 1.63 0 010 2.3 1.567 1.567 0 01-1.15.475zm6 0a1.625 1.625 0 11.004-3.25A1.625 1.625 0 018 15.668zm6 0a1.622 1.622 0 111.15-.475 1.567 1.567 0 01-1.15.475zm-12-6a1.566 1.566 0 01-1.15-.475 1.63 1.63 0 010-2.3A1.566 1.566 0 012 6.418a1.566 1.566 0 011.15.475 1.63 1.63 0 010 2.3A1.566 1.566 0 012 9.668zm6 0a1.622 1.622 0 01-1.625-1.625A1.622 1.622 0 018 6.418a1.622 1.622 0 011.502 2.247A1.622 1.622 0 018 9.668zm6 0a1.622 1.622 0 01-1.432-2.39 1.625 1.625 0 112.582 1.915 1.566 1.566 0 01-1.15.475zm-12-6a1.566 1.566 0 01-1.15-.475 1.566 1.566 0 01-.475-1.15A1.566 1.566 0 01.85.893 1.566 1.566 0 012 .418a1.566 1.566 0 011.15.475 1.566 1.566 0 01.475 1.15 1.566 1.566 0 01-.475 1.15A1.566 1.566 0 012 3.668zm6 0a1.566 1.566 0 01-1.15-.475 1.566 1.566 0 01-.475-1.15A1.566 1.566 0 016.85.893a1.63 1.63 0 012.3 0 1.566 1.566 0 01.475 1.15 1.566 1.566 0 01-.475 1.15A1.566 1.566 0 018 3.668zm6 0a1.566 1.566 0 01-1.15-.475 1.565 1.565 0 01-.475-1.15 1.566 1.566 0 01.475-1.15 1.63 1.63 0 012.3 0 1.567 1.567 0 01.475 1.15 1.566 1.566 0 01-.475 1.15 1.566 1.566 0 01-1.15.475z"
      ></path>
    </svg>
  );
};

export default OtherRoleIcon;
