import { ElasticText } from 'components/ElasticText';

export const email_regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const password_regex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_])(?=.{8,})/;
export const checkStrings = [
  'Be part of a global community of top talent',
  'Opt-in to exciting and varied projects',
  'Jobs that match your skills and interests',
  'Paid on time - guaranteed!',
];
export const fillIn = '*Required fields';
export const invalidEmail = 'Invalid email!';
export const invalidPassword =
  'Your password must have greater than 8 characters and contain at least one number, at least one capital letter and at least one special character';
export const wrongPasswordInput = 'Confirm password must be same!';

export const NewsItems = [
  {
    label: 'What are Elastic Teams?',
    link: 'https://www.distributed.com/elastic-teams'
  },
  {
    label: 'How our process works?',
    link: 'https://www.distributed.com/our-process'
  },
  {
    label: 'Case studies',
    link: 'https://blog.distributed.com/case-studies/'
  },
];

export const TestStatus = {
  pending: 'pending',
  failed: 'failed',
  pass: 'pass'
};

export const AcademyItems = [
  {
    id: 0,
    title: 'Welcome to the Elastic Academy!',
    description: `Congratulations and welcome to the Elastic Academy!

    Be sure to join our community on Discord!`,
    buttonTitle: 'JOIN DISCORD'
  },
  {
    id: 1,
    title: 'Take your first technical test',
    passTitle: 'Well done, you passed the Technical test',
    pendingTitle: 'Awaiting technical test results',
    failedTitle: 'Unsuccessful technical test results',
    type: 'Technical Coderbyte tests',
    subject: 'Technical Test',
    pendingDescription: 'We are awaiting the results of your technical test. ',
    description: `
    We’d like to invite you to demonstrate your technical skills to proceed to the next stage of joining the Elastic Team.
    
    Please select one of the tests below and complete it in one sitting. 
    
    Once you have clicked ‘take test’ it must be completed with 7 days. 
    
    Ready to take the test?
    `,
    passDescription: `Well done! you passed the Technical test`,
    failedDescription: `Dear <Name>
  
      Thank you for taking the time to complete the Technical test assessment.
      
      We have now had the chance to review your assessment, unfortunately, we regret to inform you that, at this time, we have decided not to proceed with your application. This has been a tough decision as the applicant standards have been to a higher score than the one you had.
      
      We recommend regularly checking our career page for the opportunity to be considered for other opportunities within Distributed in the near future when more vacancies become available.
      
      Thank you once again for your interest in Distributed and we wish you all the best in your job search and career.`,
  },
  {
    id: 2,
    title: 'Take English language test',
    passTitle: 'Well done, you passed the English test',
    pendingTitle: 'Awaiting English test results',
    failedTitle: 'Unsuccessful English language test results',
    passDescription: `Well done! you passed the English test`,
    type: 'English Language test',
    subject: 'English Test',
    buttonTitle: 'JOIN DISCORD',
    pendingDescription: 'We are awaiting the results of your English test.',
    description: `You’re invited to complete an Emmersion English Language Test for Distributed. This test is required to proceed to the next stage of joining the Elastic Team. 
    
    The link below is valid for 7 days, and can only be used once. 
    
    Thank you for your time in advance.
    
    Ready to take the test?`,
    failedDescription: `Dear <Name>
  
      Thank you for taking the time to complete the English Speaking assessment.
      
      We have now had the chance to review your assessment, unfortunately, we regret to inform you that, at this time, we have decided not to proceed with your application. This has been a tough decision as the applicant standards have been to a higher score than the one you had.
      
      We recommend regularly checking our career page for the opportunity to be considered for other opportunities within Distributed in the near future when more vacancies become available.
      
      Thank you once again for your interest in Distributed and we wish you all the best in your job search and career.`,
  },
  {
    id: 3,
    title: 'Opt-in to the Updated Community Member Agreement',
    description: <p>Our latest Distributed Community Member Agreement (CMA) is available to view and accept. This agreement outlines the terms and conditions for participation in the Distributed Elastic Team Online Community and provides a framework for creating a positive and respectful environment for all members.
      <br /><br />
    If you have any questions about the new Community Member Agreement, please don't hesitate to contact us at <a href="mailto:community@distributed.com" className="underline underline-offset-1">community@distributed.com</a>. We are here to help and want to ensure you feel comfortable about the new agreement.
    </p>,
  },
];

export const CVUploadError = `Oops! It seems like we're having trouble reading your CV. We kindly request you to check whether your CV is in one of the following formats: .DOC, .DOCX, .RTF, or .PDF. Also, please note that all CVs in PDF format need to be text-based.

Please give it another try, and we hope it works this time. If you're still facing difficulties uploading your CV, please don't hesitate to contact us via the Feedback button below or by emailing our friendly support team at : support@distributedsupport.zendesk.com.

We're always here to help!`

export const AccountRecoveryTitles = {
  'reset': 'Account recovery',
  'password': 'Reset your password',
  'verify': 'Account recovery',
  'final': 'Congratulations!',
}

export const AccountRecoveryDescription = {
  'reset': 'Please enter the email address you used when you registered with Distributed',
  'password': 'Please also enter the 6 digit verification that was emailed to you, to complete your email verification and account recovery',
  'verify':  'Welcome [email], to continue, please create a new password. *More than 8 letters and numbers with at least one special character',
  'final':  <div>You have successfully verified your user email and reset your password, you can now log into your <ElasticText text={'Elastic Engineer'}/> account</div>
}

export const DISCORD_URL = 'https://discord.gg/bMMSmeJykH';
export const COMMUNITY_URL = 'https://community.distributed.com/';