import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import { Auth } from 'aws-amplify';
import { Header } from '../../components/UserHeader';
import { useAppSelector, useAppDispatch } from '../../store';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormItem from './FormItem';
import { Button } from 'components';
import { CVUploadError } from 'constant';
import Notification from 'components/Notification';
import {
  getUserCV,
  saveProfile,
  updateProfile,
} from '../../api/services/user';
import PhoneInput from 'react-phone-input-2';
import { get } from 'lodash';
import Autocomplete from 'react-google-autocomplete';
import { useDropzone } from 'react-dropzone';
import 'react-phone-input-2/lib/style.css';
import Styled from './styles';
import { phone } from 'phone';

import {
  UploadIcon,
  DocIcon,
  DocxIcon,
  RtfIcon,
  InformationIcon,
  PDFIcon,
} from '../../assets';
import { getNationalityList, getCountryList } from 'utils';
import { Tooltip } from 'components';
import ProgressBar from 'components/ProgressBar';
import { useGlobalState } from "../../Context/utm";

function NewApplicant() {
  const [utmSource] = useGlobalState("utmSource");
  const [utmId] = useGlobalState("utmId");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [uploadedFiles, setUploadedFiles] = useState();
  const [userId, setUserId] = useState('');
  const isMobile = useMediaQuery('(max-width:720px)');
  const [error, setError] = useState('');
  const [cvError, setCVError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const profile = useAppSelector((state) => state.user?.profile);
  const cvData = useAppSelector((state) => state.user?.cvData);

  const isCVLoading = useAppSelector((state) => state.user?.isCVLoading);
  const errorMsg = useAppSelector((state) => state.user?.error);

  const [notification, setNotification] = useState({
    message: '',
    severity: '',
    open: false
  });

  const onClose = () => {
    setNotification({
      message: '',
      severity: '',
      open: false
    })
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    const acceptedFileTypes = ['pdf', 'doc', 'docx', 'rtf'];
    setCVError('')
    setError('')
    if (
      acceptedFiles.length > 0 &&
      !acceptedFileTypes.includes(acceptedFiles[0].name.split('.')[1])
    ) {
      setError('You can only upload pdf and doc files');
      return;
    }
    setUploadedFiles(acceptedFiles);
    setIsLoading(true);
    try {
      const res = await dispatch(getUserCV({
        userId: userId || await populateUserId(),
        cv: acceptedFiles[0]
      })).unwrap();
      if (!res || (Array.isArray(res) && res.length === 0)) {
        setCVError(CVUploadError)
        setNotification({
          severity: 'error',
          open: true,
          message: CVUploadError
        });
      }
    } catch (error) {
      setCVError(CVUploadError)
      setNotification({
        severity: 'error',
        open: true,
        message: CVUploadError
      });
    }
  }, []);

  const { getRootProps, getInputProps, rootRef, inputRef } = useDropzone({
    onDrop,
    accept: {
      'application/msword': ['.pdf', '.doc', '.docx', '.rtf'],
    },
    multiple: false,
  });

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [address, setAddress] = useState<any>('');
  const [cityName, setCityName] = useState();
  const [countryName, setCountryName] = useState();
  const [countryISO, setCountryIso] = useState();
  const [nationality, setNationality] = useState('');
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isEngineer, setIsEngineer] = useState(true);

  useEffect(() => {
    if (profile) {
      setFirstName(profile?.firstName || '');
      setLastName(profile?.lastName || '');
      setEmail(profile?.personalEmail || '');
      setAddress(profile?.city || '');
      if (profile?.nationality) {
        const nationalityList = getNationalityList();
        const nationality = Object.keys(nationalityList).reduce((prev: string, current: string) => {
          return nationalityList[current] === profile?.nationality ? current : prev;
        }, '')
        setNationality(nationality);
      } else setNationality('')
      setTelephone(profile?.telephone || '');
    } else {
      populateUserFromCognito();
    }
  }, []);

  useEffect(() => {
    if (errorMsg) {
      setError(errorMsg);
    }
  }, [errorMsg]);

  const populateUserId = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      let userId = get(user, 'signInUserSession.idToken.payload.sub');
      if (!userId) {
        userId = get(user, 'attributes.sub');
      }
      if (userId) {
        setUserId(userId);
      }
      return userId;
    } catch (error) {
      return false;
    }
  }

  const populateUserFromCognito = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      let userEmail = get(user, 'signInUserSession.idToken.payload.email');
      let userName = get(user, 'signInUserSession.idToken.payload.name');
      let isEngineer = get(user, 'signInUserSession.idToken.payload.profile');
      if (!userEmail) {
        userEmail = get(user, 'attributes.email');
      }
      if (!userName) {
        userName = get(user, 'attributes.name');
      }
      if (!isEngineer) {
        isEngineer = get(user, 'attributes.profile');
      }
      if (userEmail) {
        setEmail(userEmail);
      }
      if (isEngineer) {
        const engineerStatus = isEngineer ? (isEngineer.indexOf('isEngineer') > -1 ? true : false) : true;
        setIsEngineer(engineerStatus);
      }
      if (userName) {
        const fullName = userName.split(' ');
        if (fullName.length > 1) {
          setFirstName(fullName[0]);
          setLastName(fullName[1]);
        } else {
          setFirstName(userName)
        }
      }
    } catch (error) {
      return false;
    }
  };

  const onSave = () => {
    if (isSaveLoading) return;
    const nationalityList = getNationalityList();
    if (!phone(`+${telephone}`).isValid) {
      return setError('Invalid phone number');
    }
    setIsSaveLoading(true);
    const newProfile = {
      firstName,
      lastName,
      personalEmail: email,
      telephone: `+${telephone.replace('+', '')}`,
      city: address,
      cityName,
      countryName,
      countryISO,
      nationality: nationalityList[nationality],
      isEngineer: profile ? profile.isEngineer : !!isEngineer,
      source: utmSource,
      sourcer: utmId,
    };
    dispatch(profile ? updateProfile(newProfile) : saveProfile(newProfile))
      .unwrap()
      .then(() => {
        navigate('/skills');
      })
      .catch((error) => {
        setError('Error: ' + error?.message)
      })
      .finally(() => {
        setIsSaveLoading(false);
      });
  };

  const getFileIcon = () => {
    if (uploadedFiles && uploadedFiles[0]['name']) {
      const fileName: string = uploadedFiles[0]['name'] || '';
      const fileType = fileName.split('.')[1];
      if (fileType.indexOf('pdf') > -1) {
        return <PDFIcon />;
      }
      if (fileType.indexOf('docx') > -1) {
        return <DocxIcon />;
      }
      if (fileType.indexOf('doc') > -1) {
        return <DocIcon />;
      }
      if (fileType.indexOf('rtf') > -1) {
        return <RtfIcon />;
      }
    }
    return <PDFIcon />;
  };
  const title = uploadedFiles ? uploadedFiles[0]['name'] : 'UPLOAD YOUR';
  const title1 = uploadedFiles ? '' : 'CV HERE';
  const canContinue = !!(
    !firstName! ||
    !lastName ||
    !email ||
    !phone ||
    !address ||
    cvError ||
    !nationality ||
    (!cvData && !profile) ||
    (uploadedFiles && cvData && (!cvData || (Array.isArray(cvData) && cvData.length === 0)))
  );

  const populateAddress = (place) => {
    setAddress(place.formatted_address);

    const cityComponent = place.address_components.filter(x => x.types.includes('locality'));
    if (cityComponent && cityComponent.length > 0) {
      setCityName(cityComponent[0].long_name);
    }

    const countryComponent = place.address_components.filter(x => x.types.includes('country'));
    if (countryComponent && countryComponent.length > 0) {
      setCountryName(countryComponent[0].long_name);
      setCountryIso(countryComponent[0].short_name);
    }
  };

  return (
    <Styled.Container>
      <Header />
      <Styled.Title>Account Details</Styled.Title>
      <Styled.Description>
        <p>
          Upload your CV here to pre-populate{' '}
          {isMobile ? '\n' : 'your application'}{' '}
        </p>
        <Tooltip
          title={
            'Upload your CV to bootstrap building your Elastic Profile.  We’ll extract skills and roles ready for you to showcase.'
          }
        >
          <Styled.InformationImage src={InformationIcon} />
        </Tooltip>
      </Styled.Description>
      {isMobile && <Styled.Description>your application</Styled.Description>}
      <Styled.DropContainer
        {...getRootProps({ refKey: 'innerRef', className: 'dropzone' })}
      >
        {title}
        <br />
        {title1}
        {uploadedFiles ? (
          getFileIcon()
        ) : (
          <img
            src={UploadIcon}
            style={{ width: '23.29px', objectFit: 'contain' }}
          />
        )}

        <input {...getInputProps()} />
      </Styled.DropContainer>
      {isLoading ? (
        <div className="flex justify-center mt-2">
          <ProgressBar finished={((!!cvData && cvData.length > 0) || cvError)} error={cvError} uploadedFiles={uploadedFiles} />
        </div>
      ) : null}
      <Styled.DragDropDescription>
        Drag & Drop your CV here *(Formats are accepted: .doc, .docx, .rtf,
        .pdf)
      </Styled.DragDropDescription>

      <Styled.Form>
        <FormItem
          label="FIRST NAME"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e)}
        />
        <FormItem
          label="LAST NAME"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e)}
        />
        <FormItem
          label="EMAIL ADDRESS"
          placeholder="Email Address"
          value={email}
          disabled={true}
        />
        <FormItem label="TELEPHONE NUMBER" placeholder="123456789">
          <PhoneInput
            country={'gb'}
            value={telephone}
            onChange={(phone: string) => setTelephone(phone)}
            autoFormat
            inputStyle={{
              width: '336px',
              height: '48px',
              maxWidth: '100%',
              fontSize: '14px',
              fontFamily: 'Poppins-Medium',
              color: '#080078',
              border: '1px solid #9B9FA1',
            }}
            dropdownStyle={{ textAlign: 'left', border: '1px solid #9B9FA1' }}
          />
        </FormItem>
        <FormItem
          label="CITY AND COUNTRY RESIDENCE"
          placeholder="London United Kingdom"
        >
          <Autocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
            onPlaceSelected={populateAddress}
            defaultValue={address}
            style={{
              width: '336px',
              height: '48px',
              maxWidth: '100%',
              fontSize: '14px',
              fontFamily: 'Poppins-Medium',
              color: '#080078',
              border: '1px solid #9B9FA1',
            }}
          />
        </FormItem>
        <FormItem label="Nationality">
          <ReactFlagsSelect
            id="react-flag-select"
            selected={nationality}
            countries={getCountryList()}
            customLabels={getNationalityList()}
            onSelect={setNationality}
            placeholder="Nationality"
            searchable
            searchPlaceholder="Search"
            className="form-custom-item"
          />
        </FormItem>
        {error && <Styled.ErrorText>{error}</Styled.ErrorText>}
        <Button
          text="Save & Continue"
          className="mx-auto mt-12"
          style={{ marginTop: (cvError || error) ? '1rem' : '3rem' }}
          onClick={onSave}
          type="submit"
          disabled={canContinue || isCVLoading}
          isLoading={isSaveLoading}
        />
      </Styled.Form>
      <Notification {...notification} onClose={onClose} />
    </Styled.Container>
  );
}

export default NewApplicant;
