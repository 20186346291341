import React from 'react';

export const CircleCloseIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
        <g id="Group_1047" data-name="Group 1047" transform="translate(-1111 -287)">
        <g id="Ellipse_77" data-name="Ellipse 77" transform="translate(1111 287)" fill="#fff" stroke="#707070" strokeWidth="0.5" opacity="0.603">
            <circle cx="9.5" cy="9.5" r="9.5" stroke="none"/>
            <circle cx="9.5" cy="9.5" r="9.25" fill="none"/>
        </g>
        <g id="Group_1046" data-name="Group 1046" transform="translate(550.218 -684.081) rotate(45)" opacity="0.602">
            <line id="Line_99" data-name="Line 99" x2="10.251" transform="translate(1091.5 290.126)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1"/>
            <line id="Line_100" data-name="Line 100" x2="10.251" transform="translate(1096.626 285) rotate(90)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1"/>
        </g>
        </g>
  </svg>
  );
};
