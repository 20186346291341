import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { getUserSession } from '../utils';
import { ApiOptions } from '../types';

export async function client(
  endpoint,
  { body, token, formData, ...customConfig }: ApiOptions = {}
) {
  const headers: Headers = new Headers({ 'content-type': 'application/json' });
  const userSession = await getUserSession();
  if (userSession) {
    const token = userSession.getIdToken().getJwtToken();
    headers['Authorization'] = `Bearer ${token}`;
  }

  const config: any = {
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.data = body;
  }
  if (formData) {
    config.data = formData;
  }
  config.url = endpoint;
  let data;
  try {
    const response = await axios(config);
    data = await response.data;
    return data;
  } catch (err: any) {
    if (err.response.data) {
      throw new Error(err?.response?.data?.message || 'Something went wrong');
    }
    throw new Error(err?.response?.message);
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' });
};

client.put = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'PUT' });
};

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'POST' });
};
