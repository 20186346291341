import { AxiosInstance, AxiosRequestConfig, default as http } from 'axios';
import { Auth } from 'aws-amplify';

export const CreateInstance = (baseUrl?: string) : AxiosInstance => {
  if (!baseUrl) {
    throw new Error('Base Url not supplied for HTTP Instance');    
  }
  
  const instance = http.create({ baseURL: baseUrl, headers: { 'Content-Type': 'application/json' } });
  
  instance.interceptors.request.use(
    requestInterceptors,
    requestInterceptorErrors
  );

  instance.interceptors.response.use(
    responseInterceptors,
    responseInterceptorErrors
  );

  return instance;
}

const requestInterceptors = async (config: AxiosRequestConfig) => {
  return await Auth.currentSession()
      .then((session) => {
        if (!config.headers) {
          config.headers = {};
        }

        config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
        return config;
      })
      .catch(() => {
        return config;
      });
  };

const requestInterceptorErrors = (error) => {
  return Promise.reject(error);
};

const responseInterceptors = (response) => response;
const responseInterceptorErrors = (error) => {  
  return Promise.reject(error);
};
