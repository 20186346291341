import React from 'react';

const EngineeringIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      fill="none"
      viewBox="0 0 20 12"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6 11.693L.35 6.043 6 .393l1.075 1.075-4.6 4.6 4.575 4.575L6 11.693zm8 0l-1.075-1.075 4.6-4.6-4.575-4.575L14 .393l5.65 5.65-5.65 5.65z"
      ></path>
    </svg>
  );
};

export default EngineeringIcon;
