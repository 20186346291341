import styled from 'styled-components';

interface FormItemProps1 {
  label?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  onChange?: (value: string) => void;
  children?: any;
  ref?: any;
  disabled?: boolean;
}

const FormElement = styled.div`
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;

  & > input {
    height: 48px;
    width: 336px;
    max-width: 100%;
    border-radius: 10px;
    border: 1px solid #9b9fa1;
    outline: none;
    padding-left: 1rem;
    font-size: 14px;
    font-family: Poppins-Medium;
    color: #080078;
    box-sizing: border-box;
  }
`;

const FormLabel = styled.label`
  font-size: 12px;
  color: #818688;
  font-family: Poppins-Bold;
  text-align: left;
`;

const FormItem = (props: FormItemProps1) => {
  const { label, placeholder, type, onChange, value, children, ref, disabled } =
    props;
  return (
    <FormElement>
      {label && <FormLabel>{label}</FormLabel>}
      {children ? (
        children
      ) : (
        <input
          ref={ref}
          type={type || 'text'}
          placeholder={placeholder || ''}
          value={value}
          onChange={(e) => onChange && onChange(e.target.value)}
          disabled={disabled}
        />
      )}
    </FormElement>
  );
};

export default FormItem;
