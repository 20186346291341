export default function (props) {
  return (
    <div
      className="inline-block relative w-64 mb-2"
      style={{ marginBottom: '1rem' }}
    >
      <select
        {...props}
        style={{
          background: 'transparent',
          border: 0,
          color: 'green',
          fontSize: '15px',
          outline: 'none',
          textAlign: 'center',
        }}
        value={props.value}
      >
        {props.options}
      </select>
    </div>
  );
}
