import styled from 'styled-components';
import { devices } from 'styles/screens';
import { theme } from 'styles/theme';

const Wrapper = styled.div`
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${devices.md} {
    margin-top: 7%;
  }
`;

const CardContainer = styled.div`
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1.75rem;
  align-items: center;
  max-width: 300px;
  color: ${theme.colors.DarkBlue};
`;

const LogoImg = styled.img`
  margin-top: 2.5rem;
  height: 2rem;
`;
const Text404 = styled.p`
  font-family: Poppins-Black;
  font-size: 2.5rem;
  line-height: 2rem;
  margin-top: 1rem;
`;

const BodyContainer = styled.div`
  text-align: center;
  margin-top: 0.75rem;
  font-size: 1rem;
  font-family: Poppins-Regular;
  line-height: 1.5rem;
`;

const LinkText = styled.a`
  color: ${theme.colors.Blue};
`;

export { Wrapper, CardContainer, LogoImg, Text404, BodyContainer, LinkText };
