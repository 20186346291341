import GreyCloseIcon from 'assets/icons/GreyCloseIcon';
import React from 'react';

interface ChipProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  label: string;
  onCloseClick?: Function;
}

const Chip: React.FC<ChipProps> = (props) => {
  const { label, onCloseClick, className, ...rest } = props;
  return (
    <span
      className={`px-2 py-[2px] bg-blue-overlay inline-flex justify-between overflow-hidden gap-x-1 items-center rounded-lg ${className}`}
      {...rest}
    >
      <p className=" text-gray-3 select-none text-[14px] font-poppins-regular overflow-hidden text-ellipsis max-w-[100px]">{label}</p>
      <span className="cursor-pointer pr-1" onClick={() => onCloseClick?.()}>
        <GreyCloseIcon />
      </span>
    </span>
  );
};

export default Chip;
