import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'store';
import moment from 'moment';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { apiClient } from 'services/apiClient';
import { ResumeSkillType, ResumeType } from 'types';
import { RoleProps, RolesResponseType } from 'types/roles';
import { SkillProps, SkillsResponseType } from 'types/skills';
import yup from 'utils/yup';
import { RolesFormType, UserRoleProps } from '../components/RolesForm/type';
import { MembersService } from 'api/services/members';

const rolesFormSchema = yup
  .object({
    preferredFieldOfWork: yup
      .string()
      .required('Field of work is required!')
      .nullable(),
    recentRole: yup.string().required('Most recent role is required!'),
    skills: yup
      .array()
      .test('skills', 'You should at least select one skill', (skills) => {
        return skills !== undefined && skills?.length > 0;
      }),
    preferredNoticePeriod: yup
      .string()
      .required('Preferred notice period is required!')
      .nullable(),
    certificates: yup
      .array()
      .nullable(),
    preferredRateCurrency: yup
      .string()
      .required('Preferred rate currency is required!')
      .nullable(),
    preferredStartDate : yup
      .string()
      .required('Preferred start date is required!')
      .nullable(),
    preferredContractLength: yup
      .string()
      .required('Contract Length is required!')
      .nullable(),
  })
  .required();

const initialValues: Partial<RolesFormType> = {
  skills: [],
  preferredHoursPerWeek: 20,
  preferredRate: 200,
  skillLevel: [],
  certifications: [
    {
      certificate_name: '',
      certificate_number: '',
      certificate_acquired_date: '',
      certificate_expire_date: '',
    }
  ]
};

export const useRolesSkillsCreateForm = () => {
  const [roles, setRoles] = useState<RoleProps[]>([]);
  const [skills, setSkills] = useState<SkillProps[]>([]);

  const [rolesLoading, setRolesLoading] = useState(true);
  const [skillsLoading, setSkillsLoading] = useState(true);

  const [rolesSubmitting, setRolesSubmitting] = useState(false);
  const [skillsSubmitting, setSkillsSubmitting] = useState(false);
  const [applicantUpdateSubmitting, setApplicantUpdateSubmitting] =
    useState(false);

  const [rolesSubmitted, setRolesSubmitted] = useState(false);
  const [skillsSubmitted, setSkillsSubmitted] = useState(false);
  const [userDataSubmitted, setUserDataSubmitted] = useState(false);

  const [rolesSubmitError, setRolesSubmitError] = useState('');
  const [skillsSubmitError, setSkillsSubmitError] = useState('');
  const [applicantSubmitError, setApplicantSubmitError] = useState('');

  const cvData = useAppSelector((state) => state.user?.cvData);
  const profile = useAppSelector((state) => state.user?.profile);

  const cleanSkillName = (skillName: string) =>
    skillName
      ? skillName.replace(/\s/g, '').replace('.', '').toLowerCase()
      : '';

  const populateCV = () => {
    const skillLimit = 10;
    const topTenSkills: ResumeSkillType[] = [];
    const resumeSkills: ResumeSkillType[] | null = cvData;
    if (resumeSkills) {
      if (skills) {
        const sortedArray = [...resumeSkills].sort(
          (a: ResumeSkillType, b: ResumeSkillType) =>
            b.Level - a.Level
        );
        const existingSkills = skills.map((item) => cleanSkillName(item.name));
        sortedArray.map((skillItem: ResumeSkillType) => {
          if (
            topTenSkills.length < skillLimit &&
            existingSkills.includes(cleanSkillName(skillItem.Skill))
          ) {
            topTenSkills.push(skillItem);
          }
        });
        if (topTenSkills.length > 0) {
          topTenSkills.map((item) => {
            const { Level, Skill } = item;
            const updatedName = cleanSkillName(Skill);
            const skillItem = skills.find(
              (i) => cleanSkillName(i.name) === updatedName
            );
            if (skillItem) {
              const isExisting = getValues('skillLevel').find(
                (field) => field.skillId === skillItem.id
              );
              if (!isExisting) {
                const newSkills = [...getValues('skills'), skillItem.id];
                const skillProficiency = Level;
                onSkillsListChange(newSkills, {
                  skillProficiency,
                  id: skillItem.id,
                });
              }
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    if (cvData) {
      populateCV();
    }
  }, [cvData, skills, roles]);

  const rolesReactHookForm = useForm<RolesFormType>({
    resolver: yupResolver(rolesFormSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  const { control, setValue, getValues, trigger } = rolesReactHookForm;

  const rolesFiledArray = useFieldArray({
    control,
    name: 'skillLevel',
  });

  const certificationsArray = useFieldArray({
    control,
    name: 'certifications',
  });

  const onChangeField = (name: any, value: any) => {
    setValue(name, value);
    trigger(name);
};

  const { replace } = rolesFiledArray;

  const updateApplicant = async (data: RolesFormType) => {
    setApplicantUpdateSubmitting(true);
    setApplicantSubmitError('');
    const {
      preferredRate,
      preferredContractLength,
      preferredHoursPerWeek,
      preferredFieldOfWork,
      preferredStartDate,
      preferredNoticePeriod,
      preferredRateCurrency,
    } = data;
    const values = {
      preferredRate,
      preferredContractLength: Number(preferredContractLength),
      preferredHoursPerWeek,
      preferredRateCurrency,
      preferredRateType: 1, // Preferred Rate Type Day = 1
      preferredStartDate: moment(preferredStartDate).toISOString(true),
      preferredFieldOfWork: Number(preferredFieldOfWork),
      preferredNoticePeriod: Number(preferredNoticePeriod),
    };
    try {
      await apiClient.put('/user/new-applicant', values);
      setApplicantUpdateSubmitting(false);
      setUserDataSubmitted(true);

      if (profile) {
        await MembersService.UpsertCandidateCv(profile.id ?? '');
      }
    } catch (error) {
      setApplicantUpdateSubmitting(false);
      setApplicantSubmitError('Something went wrong! Please Try Again.');
    }
  };

  const onSubmit = (data: RolesFormType) => {
    if (formSubmitting) return;
    createUserRole(data.recentRole);
    createUserSkillProficiencies();
    updateApplicant(data);
  };

  const getRoles = async () => {
    try {
      const req = await apiClient.get<RolesResponseType>(
        '/roles?page=0&limit=1000'
      );
      const defaultRoles = req.data.items.sort((a, b) =>
        a?.name.localeCompare(b?.name)
      );
      setRoles(defaultRoles);
      setRolesLoading(false);
    } catch (error) {
      setRolesLoading(false);
    }
  };

  const roleOptions = useMemo(
    () =>
      roles.map((role) => ({
        label: role?.name,
        value: role.id,
      })),
    [roles]
  );

  const onRoleChange = (roleId: number) => {
    setValue('recentRole', roleId);
    trigger('recentRole');
  };

  const onPreferredStartDateChange = (preferredStartDate: string) => {
    setValue('preferredStartDate', preferredStartDate);
    trigger('preferredStartDate');
  };

  const createUserRole = async (role_id?: number) => {
    setRolesSubmitting(false);
    setRolesSubmitError('');
    const roleId = Number(role_id);
    try {
      if (profile?.jobRole?.id) {
        await apiClient.put<UserRoleProps>(`/user-role/${profile?.jobRole.id}`, {
          proficiency: 100,
          roleId: roleId,
        });
      } else {
        await apiClient.post<UserRoleProps>('/user-role', {
          proficiency: 100,
          roleId: roleId,
        });
      }
      setRolesSubmitting(false);
      setRolesSubmitted(true);
    } catch (error) {
      setRolesSubmitting(false);
      setRolesSubmitError('Something went wrong! Please Try Again.');
    }
    return;
  };

  const getSkills = async () => {
    try {
      const req = await apiClient.get<SkillsResponseType>(
        '/skills?page=0&limit=1200'
      );
      const defaultSkills = req.data.items.sort((a, b) =>
        a?.name.localeCompare(b?.name)
      );
      setSkills(defaultSkills);
      setSkillsLoading(false);
    } catch (error) {
      setSkillsLoading(false);
    }
  };

  const skillOptions = useMemo(
    () =>
      skills.map((skill) => ({
        label: skill?.name,
        value: skill.id,
      })),
    [skills]
  );

  const onSkillsListChange = (values: number[], cvSkill?: any) => {
    const skillIds = values.filter((i, index, ids) => ids.indexOf(i) === index);
    setValue('skills', skillIds);
    trigger('skills');
    const skillSets = skillIds.map((skillId) => {
      const proficiency = getValues('skillLevel').find(
        (field) => field.skillId === skillId
      )?.proficiency;
      if (cvSkill && cvSkill.id && cvSkill.id === skillId) {
        return {
          skillId: skillId,
          proficiency: cvSkill.skillProficiency,
        };
      }
      return {
        skillId: skillId,
        proficiency: proficiency ?? 3,
      };
    });
    replace(skillSets);
  };

  const createUserSkillProficiencies = async () => {
    setSkillsSubmitting(true);
    setSkillsSubmitError('');
    const skillProficiencies = getValues('skillLevel');
    const createRequests: Promise<AxiosResponse<any, any>>[] = [];

    const source = await apiClient.get(`/skill-source/by-name/Engineer`);
    skillProficiencies.forEach((skill) => {
      const existingItem = profile?.userSkills?.find(i => i.skillId === skill.skillId);
      let request;
      if (existingItem) {
        request = apiClient.put(`/skill-proficiency/${existingItem.id}`, {
          proficiency: skill.proficiency,
          sourceId: 0,
        });
      } else {
        request = apiClient.post(`/skill-proficiency`, {
          skillId: skill.skillId,
          proficiency: skill.proficiency,
          sourceId: source.data?.id ?? 0,
        });
      }
      createRequests.push(request);
    });

    try {
      await axios.all([...createRequests]);
      setSkillsSubmitting(false);
      setSkillsSubmitted(true);
    } catch (error) {
      setSkillsSubmitting(false);
      setSkillsSubmitError('Something went wrong! Please Try Again.');
    }
  };

  const formLoading = useMemo(
    () => rolesLoading || skillsLoading,
    [rolesLoading, skillsLoading]
  );

  const formSubmitting = useMemo(
    () => rolesSubmitting || skillsSubmitting || applicantUpdateSubmitting,
    [applicantUpdateSubmitting, rolesSubmitting, skillsSubmitting]
  );

  useEffect(() => {
    getRoles();
    getSkills();
  }, []);

  const formSubmitted = useMemo(
    () => rolesSubmitted && skillsSubmitted && userDataSubmitted,
    [rolesSubmitted, skillsSubmitted, userDataSubmitted]
  );

  return {
    ...rolesReactHookForm,
    ...rolesFiledArray,
    certificationsArray,
    roleOptions,
    onRoleChange,
    onPreferredStartDateChange,
    skillOptions,
    onSkillsListChange,
    formLoading,
    formSubmitted,
    formSubmitting,
    onSubmit,
    rolesSubmitError,
    skillsSubmitError,
    applicantSubmitError,
    onChangeField,
  };
};
