export default () => {
  return (
    <div className="max-w-[832px]">
      <p className="c39"><span className="font-poppins text-[18px] md:text-[24px] text-cool-900 leading-8 font-semibold">Distributed Community Member Agreement</span></p>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">01. Introduction</h1>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5">Dstbtd Limited (company registered number 10552489) whose registered office is at<span
          className="font-bold">&nbsp;</span><span>Wsm, Connect House 133-137 Alexandra Road, Wimbledon, London, United Kingdom,
            SW19 7JY (&ldquo;</span><span className="font-semibold">Distributed</span><span>&rdquo;) has a global community of
              freelance software developers (&ldquo;</span><span className="font-bold">Community</span><span>&rdquo;). The success
                of the Community depends on each member of it (&ldquo;</span><span className="font-bold">Member</span><span
                  className="c2">&rdquo;) adhering to a standard set of rules, which are set out in this Community Member
            Agreement. </span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>Members of our Community comprise: (i) each individual who agrees to be a Member; and (ii) each
          legal entity who agrees to be a Member. Each individual of a legal entity
          Member who works with us in relation to one or more Assignments (as defined in
          Section 05 of this Community Member Agreement) or otherwise interacts with us
          (each an &ldquo;</span><span className="font-bold">Individual</span><span className="c2">&rdquo;) is, for the purpose of
            this Community Member Agreement, acting as a Member of our Community. The legal entity Member is responsible
            for ensuring that each Individual has read, understood and agrees to and comply with this Community Member
            Agreement as if the Individual were a Member.</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Unless expressly stated otherwise, references in this Community Member Agreement to
          &ldquo;you&rdquo; include each individual who agrees to become a Member, each legal entity who agrees to
          become a Member and each Individual who a legal entity Member agrees to make available to us in relation to
          one or more Assignments.</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">If you or your employer agree to be a Member, you must comply with this Community
          Member Agreement. If you are not able or are unwilling to comply with this Community Member Agreement, you
          must not agree to become a Member or conduct any work in relation to an Assignment.</span></p>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">02. About this Community Member Agreement</h1>

        <ul className="flex flex-col gap-4 list-disc ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">This Community Member Agreement sets out the legally binding
            rules which apply when you or your employer are a Member of our Community.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="font-bold">You must ensure you have read, understood and accept all of the
            terms of this Community Member Agreement before agreeing to become a Member or commencing work in
            relation to an Assignment</span><span>. If you have any questions about this Community Member Agreement,
              please contact </span><span className=""><a className="underline underline-offset-1"
                href="mailto:community@distributed.com">community@distributed.com</a></span><span>.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>By signing up to become or otherwise acting as a Member of our Community or
            working on any Assignment on behalf of your employer Member, you are agreeing to the most recent version
            of this Community Member Agreement and any other terms that are applicable to your being a Member or
            conducting work in relation to an Assignment, as described in section 0.3 below (&ldquo;</span><span
              className="font-bold">Other Relevant Terms</span><span className="c2">&rdquo;). For more information about updates to
                this Community Member Agreement and your responsibilities in relation to updated versions, please see
                section 0.4 below. </span></li>
        </ul>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">03. Other terms that are applicable to you as a Member</h1>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>The following documents also contain terms that relate to your being a Member of our Community
          or conducting work in relation to an Assignment on behalf of your employer (&ldquo;</span><span
            className="font-bold">Other Relevant Terms</span><span className="c2">&rdquo;). By signing up to become or otherwise
              acting as a Member of our Community or conducting work in relation to an Assignment on behalf of your
              employer, you are agreeing to the most recent version of the Other Relevant Terms, which can be accessed
              here:</span></p>
        <ul className="flex flex-col gap-4 list-disc ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>Our </span><span className="font-semibold font-bold"><a className="underline underline-offset-1"
            href="https://www.google.com/url?q=https://distributed.com/privacy-policy/&amp;sa=D&amp;source=editors&amp;ust=1684784165928424&amp;usg=AOvVaw3-8P1k7TK9-X1yJhDm_0W9">Privacy
            Policy</a></span><span className="c2">&nbsp;explains what information we collect about you and how we
              protect it.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>Our </span><span className="font-bold font-semibold"><a className="underline underline-offset-1"
            href="https://www.google.com/url?q=https://distributed.com/privacy-policy/%23:~:text%3DYou%2520can%2520block%2520cookies%2520by,or%2520parts%2520of%2520the%2520Site.&amp;sa=D&amp;source=editors&amp;ust=1684784165929010&amp;usg=AOvVaw2OFgXtoR6ckY1d_WZFstic">Cookies
            Policy</a></span><span className="c2">&nbsp;which explains what cookies are, how we use them and how you
              can control what cookies we use.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>Any </span><span className="font-bold">Independent Contractor Service
            Agreement</span><span className="c2">&nbsp;agreed between you or your employer and Distributed, including
              any Work Orders issued under that Independent Contractor Service Agreement.</span></li>
        </ul>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">04. Updates to this Community Member Agreement</h1>

        <ul className="flex flex-col gap-4 list-disc ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">We may update this Community Member Agreement from time to time,
            for example to reflect changes in the way we provide services to our clients and changes in applicable
            laws or best practice. If we do, we will make the updated version of this Community
            Member Agreement available on this page and, in the case of material changes, we will notify you of the
            changes that we have made. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>The most recent version of this Community Member Agreement will become
            legally binding on you as soon as we upload it or, in the case of material changes, as soon as we notify
            you of those changes. You are welcome to contact us at </span><span className=""><a className="underline underline-offset-1"
              href="mailto:community@distributed.com">community@distributed.com</a></span><span
                className="c2">&nbsp;if you have specific questions about the changes. </span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>If you do not agree to the most recent version of this Community Member
            Agreement or the Other Relevant Terms (regardless of whether you </span><span className=""><a className="underline underline-offset-1"
              href="mailto:community@distributed.com">email</a></span><span className="c2">&nbsp;us), then
                unfortunately you must immediately cease being and acting as a Member of our Community or, where you are
                an Individual, conducting work in relation to any Assignments. </span></li>
        </ul>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">05. Assignments</h1>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>If Distributed would like you (or, where you are an Individual, your employer) to undertake
          development work (an &ldquo;</span><span className="font-bold">Assignment</span><span>&rdquo;) on behalf of any of its
            clients (&ldquo;</span><span className="font-bold">Clients</span><span className="c2">&rdquo;), Distributed will issue you
              (or, where you are an Individual, your employer) with a work order in accordance with the Independent
              Contractor Service Agreement, setting out the terms (including the specifications, performance schedules and
              fees) for the Assignment (the &ldquo;Work Order&rdquo;).</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">If you (or, where you are an Individual, your employer) accept the Work Order,
          including if you/your employer commence the work under the Work Order, a legally binding contract is formed
          between you (or, where you are an Individual, your employer) and Distributed pursuant to which Distributed
          agrees to appoint you (or, where you are an Individual, your employer) to provide, and you (or, where you
          are an Individual, your employer) agree to provide, services in relation to the Assignment in accordance
          with this Community Member Agreement, the Independent Contractor Service Agreement and the Work
          Order.</span></p>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">06. Confidential Information and Client</h1>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You acknowledge and accept that, for the purpose of enabling you to undertake each
          Assignment on behalf of Distributed and its Clients in accordance with this Community Member Agreement, the
          Independent Contractor Service Agreement and each Work Order (the &ldquo;Purpose&rdquo;), Distributed and/or
          its Clients may disclose to you, or you may otherwise receive, create or have access to, Confidential
          Information and Client Property (each as defined in the Independent Contractor Service Agreement),</span>
        </p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You confirm that you have read, understood and agree to comply with the provisions of
          the Independent Contractor Service Agreement which relate to Confidential Information and Client Property.
        </span></p>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">07. Important terms about anything you create in the course of an Assignment
          or that you upload to the Systems and/or Email Accounts (as defined in
          section 10 of this Community Member Agreement)</h1>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>Important terms about anything you create in the course of an
        </span><span> Assignment</span><span className="c2">:</span></p>
        <p className="c0"><span className="c2"></span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">When you are working on an Assignment, it is crucially important and a condition of
          the Independent Contractor Service Agreement and Work Order relating to each Assignment that:</span></p>
        <p className="c0"><span className="c2"></span></p>
        <ul className="flex flex-col gap-4 list-disc ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c24">anything you create (whether documents, information, software,
            source code, object code or other material) (&ldquo;</span><span className="font-bold c33">Work</span><span
              className="c24">&rdquo;) is your own work and is not copied wholly or partly from another source, other than
              a resource provided to you by Distributed;</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c24">you do not talk about or share the Work with anyone other than
            Distributed or other members of the Community who are working with you on an Assignment; </span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c24">Distributed owns all intellectual property rights and other
            proprietary rights in the Work.</span><span className="c2">&nbsp;</span></li>
        </ul>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>Important terms about anything you upload to the System and/or Email
          Accounts (as defined in section 10 of this Community Member Agreement)
        </span><span className="c2">:</span></p>
        <p className="c0"><span className="c2"></span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">It is crucially important and a condition of you being a Member that (i) you own
          or have the right to use any information, documents or other materials you
          upload to any of the Systems and/or Email Accounts including any personal
          information (together referred to as “Information”); (ii) you have the right to
          provide the Information to Distributed and/or the Client and to grant Distributed
          and/or the Client (as appropriate) the right to use it within the Systems and/orEmail Accounts and for its business; (iii) the Information does not infringe the
          intellectual property or other proprietary rights of a third party and is not
          defamatory, offensive or illegal</span>
        </p>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You confirm that you have read, understood and agree to comply with
          the provisions of this section 07. and the provisions of the Independent Contractor Service Agreement which
          relate to Intellectual Property. </span></p>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">08. Data Protection and use of your name and likeness</h1>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="font-semibold">Information about you which is processed by Distributed</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>Distributed will collect and process personal information relating to you in accordance with the
          privacy notice that is </span><span className=""><a className="underline underline-offset-1"
            href="https://www.google.com/url?q=https://distributed.com/privacy-policy/&amp;sa=D&amp;source=editors&amp;ust=1684784165935525&amp;usg=AOvVaw0y5x995wDafz1lBBzeaHKR">available
            here</a></span><span>.</span><span>&nbsp;</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2"></span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="font-semibold">Information which you process in the course of undertaking an Assignment</span>
        </p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">The Independent Contractor Service Agreement sets out legally binding provisions
          relating to the processing of personal data by you. &nbsp;</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You confirm that you have read, understood and agree to comply with the provisions of
          this section 08. and the provisions in the Independent Contractor Service Agreement which relate to Data
          Protection. </span></p>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="font-semibold">Use of your name and likeness</span></p>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">When you or your employer sign up to become a Member, you or your employer
          will be asked to provide information about yourself or the individuals who may
          work with Distributed (as appropriate), including a picture which is a true
          representation of you/them. Distributed may also take pictures of you during
          one or more of its in person and virtual community events (“Event Pictures”).
          By becoming a Member and providing that information or taking part in a
          community event (as appropriate), you/your employer (on behalf of the
          individuals who may work with Distributed) grant Distributed the right to storethat information and the Event Pictures and to copy, upload to the Systems
          and/or Email Accounts and otherwise use that information and the Event
          Pictures as Distributed deems reasonably necessary for the purpose of
          managing the Community, enforcing this Community Member Agreement and
          the Other Relevant Terms and to conduct its business. &nbsp;</span></p>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">09. Insurance</h1>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You (or, where you are an Individual, your employer) must take out and maintain
          comprehensive insurance with a reputable insurer to cover any liability you (or, where you are an
          Individual, your employer) may have to Distributed under this Community Member Agreement and, in relation to
          that insurance, you (or, where you are an Individual, your employer) must:</span></p>
        <ul className="flex flex-col gap-4 list-disc ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Note Distributed&rsquo;s interest on the relevant insurance
            policy/ies.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Provide Distributed with a copy of the insurance policy/ies upon
            request.</span></li>
        </ul>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">If you (or, where you are an Individual, your employer) fail to take out insurance
          in accordance with this section .09 Distributed may do so on your (or, where you are an Individual, your
          employer&rsquo;s) behalf and deduct the cost of the insurance premiums from the sums due to you (or, where
          you are an Individual, your employer) in relation to one or more Assignments.</span></p>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">10. System Access</h1>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>To enable you to carry out one or more Assignments, including to enable you to work
          collaboratively with other Members, Distributed and/or its Clients may: (a) provide you with access to the
          Distributed platform (including any Client tools and environments within Distributed&rsquo;s platform)
          and/or Client IT systems (&ldquo;</span><span className="font-bold">System</span><span>&rdquo;); and (b) set up a
            Distributed email account and/or Client email accounts for you (including, if you are a legal entity, any
            individuals you agree to make available to us) (&ldquo;</span><span className="font-bold">Email Accounts</span><span
              className="c2">&rdquo;). </span></p>
        <p className="c21"><span className="c2"></span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>Distributed or its Clients own all rights in the Systems and Email Accounts. You will not
          acquire any rights in the System or Email Accounts other than the limited right toaccess and use them in accordance with this Community Member Agreement and
          the Other Relevant Terms.</span></p>
        <p className="c21"><span className="c2"></span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span>Distributed or its Client may (but is not under any obligation to) upgrade,
          modify or otherwise amend the whole or any part of the Systems and/or
          Email Accounts (&ldquo;</span><span className="font-bold">Updates</span><span
            className="c2">&rdquo;). You must comply with any instructions as to use
            of such Updates as Distributed or the Client provides.</span></p>
        <p className="c21"><span className="c2"></span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">To grant you access to the Systems and to create Email Accounts for you, you will
          need to provide Distributed with certain information about you, which Distributed will process in accordance
          with section .08. </span></p>
        <p className="c21"><span className="c2"></span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Distributed will provide you with a unique username and password for access to the
          Systems and Email Accounts. It is a condition of you accessing and using the Systems and Email Accounts
          that:</span></p>
        <ul className="flex flex-col gap-4 list-disc ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">you are at least 18 years old; </span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">you access and use the Systems only to the extent necessary to
            complete each Assignment and in accordance with this Community Member Agreement, the Independent
            Contractor Service Agreement and the relevant Work Order. Your access to the Systems may be restricted
            to certain parts which relate to the Assignments you are working on. You must not gain or attempt to
            gain access to any other part of the Systems;</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">you treat all documents, information and materials as
            confidential information and you do not share them with anyone else;</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You keep your username and password secure and you do not share
            them with anyone else, including any other Member;</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You do not hack, modify, merge, distribute, translate, reverse
            engineer, decompile or disassemble, or otherwise attempt to obtain or use the source code of, or
            interfere with the normal working of, any of the Systems or Email Accounts</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You do not take any action that imposes, or may impose (in our
            reasonable discretion) an unusual or excessive load on any of the Systems or Email Accounts and you do
            not bypass or circumvent any security or other measures we use to control access to any of the Systems
            or Email Accounts, or attempt to do so.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You do not upload to, or transmit through any of the Systems or
            Email Accounts, any documents, information or materials which infringe the intellectual property rights
            or other proprietary rights of any third party, are defamatory, libellous, offensive or otherwise
            illegal;</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You do not remove, delete, alter or obscure any trademarks or other proprietary
            notices from the Systems and/or Email Accounts, including any copy of them;</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You do not contest or challenge the ownership or validity of, or adopt, apply for,
            register or otherwise seek to legally protect any intellectual property rights of
            Distributed or any of its Clients in or related to the Systems or Email Accounts,
            or help others to do so; and</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You do not post or upload (or attempt to post or upload) to any
            of the Systems or Email Accounts any files that contain any malicious code, including viruses, spyware,
            Trojan horses, worms, time bombs, intentionally corrupted data, or any other files or code that may
            damage or interfere with the operation of any of the Systems or Email Accounts. </span></li>
        </ul>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">If Distributed deems it necessary to do so in order to protect any of the Systems or
          Email Accounts, Distributed may suspend or terminate your access, including if Distributed believes that you
          are or may be in breach of any applicable laws, this Community Member Agreement or any Other Relevant
          Terms.</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You may provide Distributed with suggestions, recommendations or feedback
          (“Feedback”) about the Systems and/or Email Accounts. If you do provide Feedback,
          you must ensure that you have the right to provide the Feedback to Distributed and
          that the Feedback, including Distributed’s receipt and use of it, does not infringe any
          third-party intellectual property or proprietary rights and is not illegal. If you provide
          Feedback to us, we may use it without restriction and you irrevocably assign to
          Distributed all right, title and interest in and to that Feedback.</span></p>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">11. Our Liability in relation to the Systems and/or Email Accounts</h1>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Unless otherwise expressly stated in the Other Relevant Terms:</span></p>
        <ul className="flex flex-col gap-4 list-disc ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You acknowledge that the Systems, Email Accounts and any other information,
            materials, technology, functionality and benefits made available to you by
            Distributed and/or any Client (together referred to as &ldquo;</span><span className="font-bold">Member Tools</span><span
              className="c2">&rdquo;) when
              you become a Member are provided to you “AS IS”. To the maximum extent
              permitted by applicable laws, Distributed and its Clients disclaim all express
              and implied warranties, conditions or other assurances in relation to the
              Member Tools, including any warranties, conditions or other assurances of
              satisfactory quality, title, non-infringement and fitness for a particular purpose;</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Neither Distributed or its Clients warrant or represent that your use of Member
            Tools will be uninterrupted or error free or that they will meet your needs, or that
            Distributed or its Clients will correct any defects which arise with any of the
            Member Tools;</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">To the maximum extent permitted by applicable laws, in no event will
            Distributed or any of its Clients be liable to you for any indirect, incidental,
            special, consequential or punitive losses, damages, costs, expenses or other
            liability you incur in connection with your being a Member and/or accessing
            and using any of the Member Tools including, without limitation, any lost profits,
            lost savings, lost data, property damage, unauthorized access to or alteration
            of your transmissions or data, lost revenue, business interruption or loss of data
            (together referred to as &ldquo;</span><span className="font-bold">Indirect Losses</span><span
              className="c2">&rdquo;), even if Distributed and/or its Clients
              have been advised of the possibility of such Indirect Losses;</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You acknowledge that Distributed’s Clients shall have no liability to you for any
            losses, damages, costs, expenses or other liability you incur in relation to your
            being a Member and/or accessing or using any of the Member Tools, whether
            Indirect Losses or otherwise; and</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Distributed’s liability for such direct losses, damages, costs, expenses and other
            liability shall be limited to £100.</span></li>
        </ul>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">12. Indemnity</h1>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You agree to indemnify Distributed and each of its Clients in full and on demand in
          respect of all losses, damages, costs, expenses and other liability they suffer or incur
          in connection with your being a Member and/or your use to and use of any of the
          Member Tools, including reasonable legal fees in connection with investigating,
          defending or settling any claim relating to or arising out of: (a) any breach by you of
          this Community Member Agreement; or (b) your access to or use of any of the
          Systems or Email Accounts in breach of this Community Member Agreement or in a
          manner which gives rise to a claim against Distributed and/or any of its Clients.</span></p>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">13. Code of Conduct Pledge</h1>

        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">As a Member of our Community or undertaking work in relation to one or more
          Assignments on behalf of your employer, you pledge to make participation in our Community a harassment-free
          experience for everyone, regardless of age, body size, visible or invisible disability, ethnicity, sex
          characteristics, gender identity and expression, level of experience, education, socio-economic status,
          nationality, personal appearance, race, caste, color, religion, sexual identity and orientation or other
          characteristics.</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You pledge to act and interact in ways that contribute to an open, welcoming, diverse, inclusive, and healthy community.</span>
        </p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="font-semibold c32">Our Standards</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Examples of behavior that contributes to a positive environment for our Community include:</span></p>
        <ul className="flex flex-col gap-4 list-disc ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Demonstrating empathy and kindness toward other people</span>
          </li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Being respectful of differing opinions, viewpoints, and
            experiences</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Giving and gracefully accepting constructive feedback</span>
          </li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Accepting responsibility and apologizing to those affected by
            our mistakes, and learning from the experience</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Focusing on what is best not just for us as individuals, but for
            the overall community.</span></li>
        </ul>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="font-semibold">Examples of unacceptable behavior include:</span></p>
        <ul className="flex flex-col gap-4 list-disc ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">The use of sexualized language or imagery, and sexual attention
            or advances of any kind</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Trolling, insulting or derogatory comments, and personal or
            political attacks</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Public or private harassment</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Publishing others&rsquo; private information, such as a physical
            or email address, without their explicit permission</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Other conduct which could reasonably be considered inappropriate
            in a professional setting.</span></li>
        </ul>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="font-semibold">Enforcement Responsibilities</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Distributed is responsible for clarifying and enforcing this Code of Conduct and will
          take appropriate and fair corrective action in response to any behavior that it deems to be in breach of
          this Code of Conduct.</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Distributed may remove, edit, or reject comments, commits, code, wiki edits, issues,
          and other contributions that it believes are in breach of this Code of Conduct, and will communicate reasons
          for doing so when appropriate.</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Distributed may also, in its absolute discretion:</span></p>
        <ul className="flex flex-col gap-4 list-disc ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Require any Member or Individual who it deems to be in breach of
            this Code of Conduct to take appropriate corrective action, as determined by Distributed; and/or</span>
          </li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Temporarily or permanently ban any Member or Individual from
            being or continuing to be a Member of the Community or undertaking work in relation to one or more
            Assignments, if Distributed believes (in its sole discretion) that person is in breach of this Code of
            Conduct.</span></li>
        </ul>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="font-semibold">Scope</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">This Code of Conduct applies when you are accessing and/or using the Systems and/or
          Email Accounts and it also applies when you are officially representing the Community or otherwise acting in
          your capacity as a Member of the Community or undertaking work in relation to one or more
          Assignments.</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Examples of representing our Community include using an official e-mail address,
          posting via an official social media account, or acting as an appointed representative of Distributed at an
          online or offline event.</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="font-semibold">Enforcement</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">If you believe a Member of the Community or any Individual is in breach of this Code
          of Conduct, you can report the behavior at </span><span className=""><a className="underline underline-offset-1"
            href="mailto:community@distributed.com">community@distributed.com</a></span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">All complaints will be reviewed and investigated promptly and fairly.</span></p>
        <p className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">Distributed will not disclose the details of any persons who reports behaviour which
          is or may be in breach of this Code of Conduct.</span></p>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <h1 className="font-poppins text-[18px] text-cool-900 leading-6 font-semibold">14. Other Important Legal Terms</h1>

        <ol className="flex flex-col gap-4 list-decimal ml-8">
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">This Community Member Agreement does not create any exclusive
            relationship between you and Distributed nor any partnership, joint venture, employment or agency
            relationship between you and Distributed.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">When you become a Member, you acknowledge and accept that
            Distributed is not obligated to use your services for any particular Assignment and there is no
            guarantee that Distributed will do so.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">No failure or delay by Distributed to exercise any right it has
            under this Community Member Agreement, the Other Relevant Terms or at law will be a waiver of that
            right.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">You must not assign, subcontract or transfer your rights and/or
            obligations under this Community Member Agreement or any Assignment.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">If any part of this Community Member Agreement is found to be
            invalid or unenforceable, that will not affect the rest of this Community Member Agreement.</span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">This Community Member Agreement and the Other Relevant Terms
            constitute the whole agreement between you and Distributed and supersede all previous agreements,
            arrangements and understandings between you and Distributed regarding their subject matter. &nbsp;
            &nbsp; </span></li>
          <li className="font-inter text-[14px] font-normal text-cool-900 leading-5"><span className="c2">This Community Member Agreement and the Other Relevant Terms are
            governed by the laws of England. You and Distributed submit to the exclusive jurisdiction of the courts
            of England if there is any dispute between us relating to them.</span></li>
        </ol>
      </div>
    </div>
  )
}