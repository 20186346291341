export const LogoIcon = require('./images/logo.png');
export const LogoIcon1 = require('./images/logo2.png');
export const BirdIcon = require('./images/bird1.png');
export const GoogleIcon = require('./images/Google.png');
export const GoBackIcon = require('./images/goBack.png');
export const tickIcon = require('./images/tickIcon.png');
export const closeIcon = require('./images/closeIcon.png');
export const backgroundImage = require('./images/background.png');
export const CheckIcon = require('./images/check-circle@2x.png');
export const BlueMail = require('./icons/BlueMail.png');

export const FaceBookIcon = require('./icons/facebook.png');
export const LinkedinIcon = require('./icons/linkedin.png');
export const TwitterIcon = require('./icons/twitter.png');
export const ShareIcon = require('./icons/share.png');
export const ChevronLeftIcon = require('./icons/chevron-left.png');

export const EditIcon = require('./icons/editIcon.png');
export const InformationIcon = require('./icons/informationIcon.png');
export const UploadIcon = require('./icons/uploadIcon.png');
export const UserIcon = require('./icons/userIcon.png');
export const PDFFile = require('./docs/Distributed - Member Agreement - Final- 13 June 2023.pdf');

export * from './icons/DocIcon';
export * from './icons/DocxIcon';
export * from './icons/RtfIcon';
export * from './icons/PDFIcon';
export * from './icons/CloseIcon';
export * from './icons/CommunityIcon';
export * from './icons/BadgeIcon';
export * from './icons/BigEditIcon';
export * from './icons/NewsIcon';
export * from './icons/EmailIcon';
export * from './icons/EmailOpenIcon';
export * from './icons/NotificationIcon';
export * from './icons/EnterIcon';
export * from './icons/ProfileIcon';
export * from './icons/DotIcon';
export * from './icons/PinkIcon';
export * from './icons/TwitterIcon';
export * from './icons/FacebookIcon';
export * from './icons/LinkedinIcon';
export * from './icons/LongPinkIcon';
export * from './icons/ShareIcon';
export * from './icons/HamburgerIcon';
export * from './icons/GreyLogoIcon';
export * from './icons/GreyShareIcon';
export * from './icons/GreyFacebookIcon';
export * from './icons/GreyLinkedinIcon';
export * from './icons/GreyTwitterIcon';
export * from './icons/CircleCloseIcon';
export * from './icons/SearchIcon';
export * from './icons/CookieIcon';
export * from './icons/PasswordCheckIcon';
export * from './icons/PasswordWrongIcon';
export * from './icons/ByeIcon';
export * from './icons/MortarboardIcon';
export * from './icons/CloseIcon1';
export * from './icons/BellIcon';
export * from './icons/SuccessIcon';
export * from './icons/FailureIcon';
export * from './icons/OpenIcon';
export * from './icons/DownloadIcon';
export * from './icons/ModalCloseIcon';
export * from './icons/CloseCircleIcon';
export * from './icons/AcceptIcon';
export * from './icons/DeclineIcon';
