import * as React from 'react';

export const PDFIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48.134"
    height="55.01"
    viewBox="0 0 48.134 55.01"
  >
    <path
      id="Path_42"
      data-name="Path 42"
      d="M48.134,15.472V48.134a6.874,6.874,0,0,1-6.876,6.876H37.82V51.572h3.438A3.439,3.439,0,0,0,44.7,48.134V15.472H37.82a5.159,5.159,0,0,1-5.157-5.157V3.438H13.753a3.439,3.439,0,0,0-3.438,3.438V37.82H6.876V6.876A6.874,6.874,0,0,1,13.753,0h18.91ZM5.5,40.742H0V54.491H2.72V49.877H5.48A4.957,4.957,0,0,0,8,49.282a4.031,4.031,0,0,0,1.592-1.63,4.891,4.891,0,0,0,.554-2.328A4.955,4.955,0,0,0,9.6,43a4.035,4.035,0,0,0-1.582-1.64A4.8,4.8,0,0,0,5.5,40.742Zm1.874,4.583a2.734,2.734,0,0,1-.292,1.306,1.98,1.98,0,0,1-.818.829,2.7,2.7,0,0,1-1.289.282H2.709V42.908H4.978a2.4,2.4,0,0,1,1.76.622A2.417,2.417,0,0,1,7.375,45.325Zm4.184-4.583V54.491h5.02a6.626,6.626,0,0,0,3.431-.815,4.98,4.98,0,0,0,2.046-2.369,9.351,9.351,0,0,0,.674-3.727,9.124,9.124,0,0,0-.674-3.7,4.9,4.9,0,0,0-2.025-2.338,6.71,6.71,0,0,0-3.455-.8Zm2.72,2.218h1.936a3.8,3.8,0,0,1,2.094.523,3.059,3.059,0,0,1,1.217,1.561,7.128,7.128,0,0,1,.406,2.589,7.975,7.975,0,0,1-.234,2.035,3.92,3.92,0,0,1-.674,1.451,2.752,2.752,0,0,1-1.148.866,4.409,4.409,0,0,1-1.661.282H14.279Zm12.869,6.061v5.47H24.431V40.742h8.76v2.245H27.148v3.84h5.522v2.194Z"
      fill="#144FE7"
      fillRule="evenodd"
    />
  </svg>
);
