import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import SimpleDialog from '../../components/SimpleDialog';
import { LogoIcon1, BirdIcon } from '../../assets';
import Styled from '../signup/signup.styles';
import FormItem from '../../components/FormItem';
import { Auth } from 'aws-amplify';
import {
  PasswordWrongIcon,
  PasswordCheckIcon,
} from 'assets';
import { theme } from 'styles/theme';
import {
  email_regex,
  password_regex,
  AccountRecoveryTitles,
  AccountRecoveryDescription,
  invalidPassword,
  wrongPasswordInput,
} from '../../constant';

export interface ModalProps {
  open: boolean;
  value?: string;
  error?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  onChange?: (otp: string) => void;
}

const inputStyle = {
  border: '1px solid #080078',
  borderRadius: '5px',
  width: '34px',
  height: '35px',
  outline: 'none',
  fontSize: '20px',
  fontFamily: 'Poppins-Medium',
  color: theme.colors.Blue,
  boxShadow: '0px 1px 5px -1px #4a4646'
};
const focusStyle = { border: '1px solid #080078' };
const Separator = () => <span style={{ width: '1rem' }}></span>;
 

const AccountRecovery = (props: ModalProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { open, onClose, value, onChange } = props;
  const [step, setStep] = useState('reset')
  const [code, setCode] = useState('')
    
  const requestForgotPassword = async () => {
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      // setError(error.toString())
    }
  }

  const onHandleError = (error) => {
    const errorTxt =
      error.toString().indexOf(':') > -1
        ? error.toString().split(':')[1].toString()
        : error.toString();
    setError(errorTxt);
  };

  const verifyForgotPassword = async () => {
    try {
      const res = await Auth.forgotPasswordSubmit(email, code, password);
      setStep('final');
    } catch (error: any) {
      onHandleError(error);
    }
  } 

  const onSubmit = async () => {
    setError('');
    if (step === "reset") {
      setError('');
      if (!email) {
        return setError('*Required fields');
      }
      if (email_regex.test(email) === false) {
        return setError('Invalid email address');
      }
      requestForgotPassword();
      setStep('verify');
    } else if (step === "verify") {
      if (password_regex.test(password) === false) {
        return setError(invalidPassword);
      }
      if (password !== confirmPassword) {
        return setError(wrongPasswordInput);
      }
      verifyForgotPassword();
    } else {
      onClose && onClose();
      setStep('reset');
    }
  }
  return (
    <SimpleDialog open={open} onClose={() => onClose}>
      <Styled.DialogContainer style={{ padding: "1rem"}}>
        <Styled.DialogLogo src={LogoIcon1} alt="logo" />
        <Styled.DialogBody>
          <Styled.DialogTitle>{AccountRecoveryTitles[step]}</Styled.DialogTitle>
          {step === 'reset' ? (
            <Styled.FlexContainer>
              <Styled.DialogBanner src={BirdIcon} alt="email" />
            </Styled.FlexContainer>
          ): null}
          <Styled.DialogDescription style={{ marginBottom: '1rem', textAlign: 'center', padding: '0 .5rem'}}>
            {step === 'verify' ? AccountRecoveryDescription[step].replace('[email]', email) : AccountRecoveryDescription[step]}
          </Styled.DialogDescription>
          {step === 'verify' ? (
            <div>
              <FormItem
                type="password"
                label="NEW PASSWORD"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e)}
                icon={password ? (password_regex.test(password) ? <PasswordCheckIcon /> : <PasswordWrongIcon />) : null}
              />
              <div className='mt-4'/>
              <FormItem
                type="password"
                label="CONFIRM NEW PASSWORD"
                placeholder="Confirm Password"
                value={confirmPassword}
                icon={confirmPassword ? (password === confirmPassword ? <PasswordCheckIcon /> : <PasswordWrongIcon />) : null}
                onChange={(e) => setConfirmPassword(e)}
              />
              <Styled.DialogDescription style={{ marginBottom: '1rem', textAlign: 'center', padding: '0 .5rem'}}>{AccountRecoveryDescription['password']}</Styled.DialogDescription>
              <Styled.OtpContainer>
                <OtpInput
                  value={code}
                  onChange={(e) => setCode(e)}
                  numInputs={6}
                  separator={<Separator />}
                  inputStyle={inputStyle}
                  focusStyle={focusStyle}
                />
              </Styled.OtpContainer>
            
            </div>
          ) : null}
          {step === 'reset' ? (
            <FormItem
              labelClassName="!text-[14px] !text-primary-blue"
              inputClassName="!text-[14px] !text-primary-blue"
              label="ENTER YOUR EMAIL"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e)}
            />
          ) : null}
          {error && <Styled.ErrorText style={{ marginTop: '1rem'}}>{error}</Styled.ErrorText>}
          <Styled.SubmitButton
            style={{ marginTop: step === 'final' ? '6rem' : (error ? '1rem' : '2rem')}}
            onClick={onSubmit}
            disabled={step === 'reset' ? !email : (!password || !code || code.length < 6)}
          >{step === 'final' ? 'GO TO LOGIN' : 'SUBMIT'}</Styled.SubmitButton>
        </Styled.DialogBody>
      </Styled.DialogContainer>
    </SimpleDialog>
  );
};
export default AccountRecovery;
