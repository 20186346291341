import OtpInput from 'react-otp-input';
import { Button } from 'components';
import SimpleDialog from '../../components/SimpleDialog';
import { LogoIcon1, BirdIcon } from '../../assets';
import Styled from './signup.styles';

export interface ModalProps {
  open: boolean;
  value: string;
  error: string;
  isLoading?: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  onChange: (otp: string) => void;
}

const inputStyle = {
  border: '1px solid #080078',
  borderRadius: '5px',
  width: '34px',
  height: '35px',
  outline: 'none',
  fontSize: '15px',
};
const focusStyle = { border: '1px solid #080078' };
const Separator = () => <span style={{ width: '.5rem' }}></span>;

const title = '6 digit verification code';
const description =
  'A 6 digit verification was sent to your email. Please input the 6 digit code below to complete your email verification.';

const OtpModal = (props: ModalProps) => {
  const { open, handleClose, onSubmit, value, onChange, isLoading, error } = props;
  return (
    <SimpleDialog open={open} onClose={handleClose}>
      <Styled.DialogContainer>
        <Styled.DialogLogo src={LogoIcon1} alt="logo" />
        <Styled.DialogBody>
          <Styled.DialogTitle>{title}</Styled.DialogTitle>
          <Styled.FlexContainer>
            <Styled.DialogBanner src={BirdIcon} alt="email" />
          </Styled.FlexContainer>
          <Styled.DialogDescription>{description}</Styled.DialogDescription>
          <Styled.OtpContainer>
            <OtpInput
              value={value}
              onChange={onChange}
              numInputs={6}
              separator={<Separator />}
              inputStyle={inputStyle}
              focusStyle={focusStyle}
            />
          </Styled.OtpContainer>
          {error && <Styled.ErrorText>{error}</Styled.ErrorText>}
          <Button
            className='h-[38.57px] mt-8 w-full !text-[14px]'
            style={{ marginTop: error ? '1rem' : '2rem'}}
            disabled={value.length !== 6}
            onClick={onSubmit}
            text={'Submit'}
            isLoading={isLoading}
          />
        </Styled.DialogBody>
      </Styled.DialogContainer>
    </SimpleDialog>
  );
};
export default OtpModal;
