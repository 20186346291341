export const currencyList = [
    {
      value: 'GBP',
      title: 'GBP',
      unit: '£',
    },
    {
      value: 'EUR',
      title: 'EUR',
      unit: '€',
    },
    {
      value: 'USD',
      title: 'USD',
      unit: '$',
    },
];
  