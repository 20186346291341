import Button from 'components/Button';
import { ModalCloseIcon, DeclineIcon } from 'assets';

export interface DeclineAgreementProps {
    onClose: () => void;
    onDecline: () => void;
}

export default function(props: DeclineAgreementProps) {
    const { onClose, onDecline } = props;
    return (
        <div className='flex flex-col gap-4'>
            <div className='flex flex-row gap-6 justify-between'>
                <div className='flex flex-row items-center gap-2'>
                    <DeclineIcon />
                    <p className="text-cool-900 font-normal leading-6 font-poppins text-[18px]">Decline Acceptance?</p>
                </div>
                <span className='-mt-2 -mr-2 cursor-pointer' onClick={onClose}><ModalCloseIcon /></span>
            </div>
            <div className='flex flex-col gap-4'>
                <p className="text-cool-700 font-normal leading-5 font-poppins text-[14px]">
                    Are you sure you want to decline acceptance?
                </p>
                <p className="text-cool-700 font-normal leading-5 font-poppins text-[14px]">
                    If you decline the agreement, you will not be able to seek opportunities and new roles.
                </p>
            </div>
            <div className='flex flex-row justify-end gap-4 items-center pt-4'>
                <Button
                    label="Cancel"
                    className='!bg-grey-100 hover:!bg-grey-200 active:!bg-grey-400 !text-[#666666] text-[14px] !px-6 !py-2 !h-10'
                    type="submit"
                    onClick={onClose}
                />
                <Button
                    label="Decline"
                    className='!bg-error-500 hover:!bg-error-600 active:!bg-error-800 !text-[white] !text-[14px] !px-6 !py-2 !h-10'
                    type="submit"
                    onClick={onDecline}
                />
            </div>
        </div>
    )
  }