import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxItem(props) {
  const { label, value, onChange, ...rest } = props;
  return (
    <FormControlLabel
      control={<Checkbox checked={value} onChange={onChange} {...rest}/>}
      label={label}
    />
  );
}
