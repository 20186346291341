import * as React from 'react';

export const RtfIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 117.8 126.7"
    {...props}
  >
    <g>
      <g>
        <path
          d="M39.2,100.3c1.5-2.5,1.5-5.6,0-8c-0.8-1.2-1.9-2.1-3.1-2.7c-1.5-0.6-3.2-1-4.8-0.9h-9.1v20.9H27v-5.8h4.4l4.1,5.8h5.1
                    l-4.7-6.7C37.3,102.4,38.4,101.5,39.2,100.3z M34.2,99c-0.9,0.7-2.1,1-3.3,0.9H27v-7.3h3.9c1.2-0.1,2.3,0.3,3.3,1
                    c0.8,0.6,1.2,1.6,1.1,2.7C35.4,97.3,35,98.3,34.2,99z"
          fill="#144FE7"
          fillRule="evenodd"
        />
        <polygon
          points="41.4,92.7 48.1,92.7 48.1,109.6 52.9,109.6 52.9,92.7 59.7,92.7 59.7,88.7 41.4,88.7"
          fill="#144FE7"
          fillRule="evenodd"
        />
        <polygon
          points="62.2,109.6 67.1,109.6 67.1,102 76.7,102 76.7,98.1 67.1,98.1 67.1,92.6 78,92.6 78,88.7 62.2,88.7"
          fill="#144FE7"
          fillRule="evenodd"
        />
      </g>
      <path
        d="M95.8,46.3v52.6c0,6.1-5,11.1-11.1,11.1v-5.5c3.1,0,5.5-2.5,5.5-5.5V46.3H79.2c-4.6,0-8.3-3.7-8.3-8.3V26.9
                H40.4c-3.1,0-5.5,2.5-5.5,5.5v49.8h-5.5V32.5c0-6.1,5-11.1,11.1-11.1h30.4L95.8,46.3z"
        fill="#144FE7"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
