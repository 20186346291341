import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltips, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { theme } from 'styles/theme';

const defaultToolTipStyle = {
  color: theme.colors.DarkBlue,
  backgroundColor: theme.colors.White,
  padding: '1rem',
  boxShadow: '1px 1px 4px #c7c7c7',
  fontSize: '12px',
  fontWeight: 'Poppins-Light',
  maxWidth: 179,
  lineHeight: '15px',
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltips {...props} placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: defaultToolTipStyle,
}));

export default function Tooltip(props: any) {
  return (
    <div>
      <BootstrapTooltip title={props.title}>{props.children}</BootstrapTooltip>
    </div>
  );
}
