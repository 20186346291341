import styled from 'styled-components';
import { devices } from 'styles/screens';
import { theme } from 'styles/theme';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
`;

const LogoIcon = styled.img`
  height: 71px;
`;
const LogoIcon1 = styled.img`
  height: 53px;
  object-fit: contain;
`;
const GoogleLogoIcon = styled.img`
  height: 20px;
  object-fit: contain;
  margin-right: 1rem;
`;

const SignupForm = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const LeftLineSpace = styled.span`
  height: 1px;
  background: rgb(155, 159, 161);
  width: 45%;
  position: absolute;
  left: 0;
  top: 6px;
`;

const RightLineSpace = styled.span`
  height: 1px;
  background: rgb(155, 159, 161);
  width: 45%;
  position: absolute;
  right: 0;
  top: 6px;
`;

const SeparatorContainer = styled.div`
  display: flex;
  position: relative;
`;

const SeparatorTitle = styled.p`
  color: ${theme.colors.DarkBlue};
  font-size: 13px;
  margin: auto;
`;

const HeaderTextContainer = styled.div`
  max-width: 343px;
  @media ${devices.md} {
    max-width: 440px;
  }
`;

const HeaderTitle = styled.div`
  color: ${theme.colors.DarkBlue};
  font-size: 28px;
  font-family: Poppins-Black;
  line-height: 34px;
  @media ${devices.md} {
    font-size: 36px;
    line-height: 40px;
    font-family: Poppins-ExtraBold;
  }
`;

const AnswerTitle = styled.p`
  color: ${theme.colors.DarkBlue};
  font-size: 28px;
  font-family: Poppins-Medium;

  @media (max-width: 720px) {
    font-size: 18px;
  }
`;

const SubmitButton = styled.button`
  background: ${theme.colors.Blue};
  color: white;
  border: 0;
  border-radius: 50px;
  height: 38.57px;
  margin-top: 2rem;
  width: 100%;
`;

const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const DialogContainer = styled.div`
  padding: 1.5rem;
  max-width: 372px;
  height: 469px;
  text-align: center;
  box-sizing: border-box;
`;
const DialogBody = styled.div`
  padding: 1rem;
`;
const DialogLogo = styled.img`
  margin-top: 1rem;
  object-fit: contain;
  width: 312px;
  max-width: 100%;
`;

const DialogTitle = styled.p`
  color: ${theme.colors.DarkBlue};
  font-family: Poppins-SemiBold;
  font-size: 20px;
  text-align: center;
  margin-bottom: 1rem;
`;

const DialogDescription = styled.p`
  font-family: Poppins-Regular;
  text-align: left;
  font-size: 15px;
  line-height: 1.1rem;
  padding: 0 1.5rem;
  color: ${theme.colors.DarkBlue};
`;

const DialogBanner = styled.img`
  height: 32px;
  margin-top: 1rem;
  object-fit: contain;
`;

const PrivacyPolicy = styled.p`
  color: ${theme.colors.DarkBlue};
  font-size: 13px;
  margin-bottom: 1rem;
  text-align: center;
`;

const GoBackButton = styled.div`
  background: transparent;
  border: 0;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
  color: ${theme.colors.DarkBlue};
  font-size: 14px;
`;

const Styled = {
  Wrapper,
  Container,
  LogoIcon,
  LogoIcon1,
  GoogleLogoIcon,
  SignupForm,
  LeftLineSpace,
  RightLineSpace,
  SeparatorContainer,
  SeparatorTitle,
  HeaderTextContainer,
  HeaderTitle,
  SubmitButton,
  OtpContainer,
  AnswerTitle,
  DialogContainer,
  DialogBody,
  DialogLogo,
  DialogTitle,
  DialogDescription,
  DialogBanner,
  PrivacyPolicy,
  GoBackButton,
};

export default Styled;
