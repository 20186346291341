import useMediaQuery from '@mui/material/useMediaQuery';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { useDropzone } from 'react-dropzone';
import {
  useLocation,
} from 'react-router-dom';
import { get } from 'lodash';
import {
  HamburgerIcon,
  LogoIcon,
} from '../assets';
import { CloseIcon, UserIcon, MortarboardIcon, EditIcon } from 'assets';
import { useEffect, useCallback, useState } from 'react';
import { getProfile } from 'api/services/user';
import { useAppSelector, useAppDispatch } from 'store';
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Logout from './Logout';

const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 20px;
  background-color: #F5F9FD;
  border-width: 4px;
  border-color: transparent;
  &:hover {
    border-color: #144fe7;
    border-width: 4px;
  }
`;
const UserAvatarPhoto = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  border-width: 4px;
  border-color: transparent;
  &:hover {
    border-color: #144fe7;
    border-width: 4px;
  }
  @media (max-width: 1024px) {
    width: 42px;
    height: 42px;
  }
`;

const AcademyBadge = styled.span`
  background: #FF007A;
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  position: absolute;
  justify-content: center;
  right: -4px;
  bottom: -3px;
  objectfit: contain;
  cursor: pointer;
  @media (max-width: 1024px) {
    right: -4px;
    bottom: -3px;
  }
`;
const AcademySVGContainer = styled.span`
  width: 10px;
  height: 9px;
`;
const OptionSelectedIcon = styled.span`
  width: 12px;
  height: 12px;
  border: 1px solid #6d93f2;
  border-radius: 50%;
  background-color: #6d93f2;
  @media (max-width: 1024px) {
  }
`;

const OptionIcon = styled.span`
  width: 12px;
  height: 12px;
  border: 1px solid #6d93f2;
  border-radius: 50%;
  background-color: white;
  @media (max-width: 1024px) {
  }
`;
const SelectedOptionTitle = styled.p`
  font-size: 12px;
  font-family: Poppins-Bold;
  color: #6d93f2;
  margin-left: 5px;
  @media (max-width: 1024px) {
  }
`;
const OptionTitle = styled.p`
  font-size: 12px;
  font-family: Poppins-Regular;
  color: #6d93f2;
  margin-left: 5px;
  @media (max-width: 1024px) {
  }
`;

interface HeaderProps {
  style?: object;
  socialIcon?: boolean;
  userInfo?: any;
}
type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const SelectOption = (props) => {
  const { title, selected } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '.5rem',
        minWidth: '125px',
      }}
    >
      {selected ? <OptionSelectedIcon /> : <OptionIcon />}
      {selected ? (
        <SelectedOptionTitle>{title}</SelectedOptionTitle>
      ) : (
        <OptionTitle>{title}</OptionTitle>
      )}
    </div>
  );
};

const settings = ['Logout'];

export const Header = (props: HeaderProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [userImage, setUserImage] = useState('');
  const [userName, setUserName] = useState('');
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const profile = useAppSelector((state) => state.user?.profile);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (option) => {
    setAnchorElUser(null);
    if (option === "Logout") {
      setLogoutOpen(true);
    }
  };

  const getUserName = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userName = get(user, 'signInUserSession.idToken.payload.name');
      if (userName) {
        setUserName(userName);
      }
    } catch {
      console.log('Not authenticated')
    }
  }

  useEffect(() => {
    if (profile) {
      if (profile?.firstName && profile?.lastName) {
        const userName = `${profile?.firstName} ${profile?.lastName}`;
        setUserName(userName);
      }
      if (profile?.jobRole) {
        setUserRole(profile.jobRole.name)
      }
    } else {
      getUserName();
    }
  }, [profile]);

  const isMobile = useMediaQuery('(max-width:768px)');
  const currentRoute = location.pathname;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };
      const buffer = reader.readAsArrayBuffer(file);
      setUserImage(URL.createObjectURL(file));
    });
  }, []);

  const onLogout = async () => {
    setIsLoading(true);
    await Auth.signOut();
    setIsLoading(false);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      event.stopPropagation();
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.bmp'],
    },
    multiple: false,
    onDrop,
  });

  return (
    <div className="h-[68px] min-h-[68px] w-full md:h-[56px] md:min-h-[56px] bg-white border-b-2 border-[#F0F3F7] flex justify-between items-center px-6 md:px-12 relative">
      <img
        src={LogoIcon}
        alt="distributed-logo"
        className="w-[133px] h-6"
      />

      {profile && profile?.firstName ? (
        <div className="flex justity-around items-center pr-4 hidden md:flex">
          <div className='flex flex-col items-center relative'>
            <Link to={'/dashboard'}>
              <p className={currentRoute === '/dashboard' ? "text-[#D70064] font-poppins-bold text-[12px] mx-4 md:mx-8 lg:mx-8" :
                "text-[#3D3D3D] font-poppins-regular hover:font-poppins-bold text-[12px] mx-4 md:mx-8 lg:mx-8"}>
                My Dashboard
              </p>
            </Link>
            {currentRoute === '/dashboard' && <span className='bg-[#FF007A] w-[100px] h-1 absolute top-8' />}
          </div>
          <div className='flex flex-col items-center relative'>
            <Link to={'/new-applicant'}>
              <p className={(currentRoute === '/new-applicant' || currentRoute === '/skills') ? "flex justify-center text-[#D70064] w-[15px] font-poppins-bold text-[12px] mx-4 md:mx-8 lg:mx-8" :
              "text-[#3D3D3D] flex justify-center font-poppins-regular hover:font-poppins-bold text-[12px] mx-4 md:mx-8 lg:mx-8"}>
                Profile
              </p>
            </Link>
            {(currentRoute === '/new-applicant' || currentRoute === '/skills') && <span className='bg-[#FF007A] w-[100px] h-1 absolute top-8' />}
          </div>
        </div>
      ) : null}
        <div className="flex flex-row items-center justify-center lg:justify-start" onClick={handleOpenUserMenu}>
          {!isMobile && 
            <div>
              <p className="text-[#3D3D3D] font-bold text-right font-poppins-regular text-[11px] mx-2">
                {userName}
              </p>
              {userRole ? (
                <p className="text-[#3D3D3D] font-normal font-poppins-medium text-[10px] mx-2">
                  {userRole}
                </p>
              ) : null}
            </div>
          }
          {userImage ? (
             <div className='flex items-center gap-x-6'>
              <div className="relative">
                <UserAvatarPhoto src={userImage} style={{ border: Boolean(anchorElUser) ? '4px solid #FF007A' : ''}} />
                {profile?.academyUserId ? (
                  <AcademyBadge>
                    <AcademySVGContainer>
                      <MortarboardIcon />
                    </AcademySVGContainer>
                  </AcademyBadge>
                ) : null}
              </div>
              <span
                className="block md:hidden cursor-pointer"
                onClick={toggleDrawer('left', true)}
              >
                <HamburgerIcon />
              </span>
            </div>
          ) : (
            <div className='flex items-center gap-x-6'>
              <div className="relative">
                <UserAvatar src={UserIcon} style={{ border: Boolean(anchorElUser) ? '4px solid #FF007A' : ''}} />
                {profile?.academyUserId ? (
                  <AcademyBadge>
                    <AcademySVGContainer>
                      <MortarboardIcon />
                    </AcademySVGContainer>
                  </AcademyBadge>
                ) : null}
              </div>
              <span
                className="block md:hidden cursor-pointer"
                onClick={toggleDrawer('left', true)}
              >
                <HamburgerIcon />
              </span>
          </div>
          )}
        </div>
      <Menu
        sx={{ mt: '55px', ml: '0'}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
            <p className="font-poppins-regular text-[13px] text-primary-blue w-[130px] text-center">{setting}</p>
          </MenuItem>
        ))}
      </Menu>
    
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <div className="h-[68px] min-h-[68px] w-full md:h-[56px] md:min-h-[56px] bg-white border-b-2 border-[#F0F3F7] flex justify-between items-center px-6 md:px-12 relative">
          <img
            src={LogoIcon}
            alt="distributed-logo"
            className="w-[133px] h-6"
          />
            <div className='flex items-center gap-x-6'>
              <div className="relative">
                {userImage ? <UserAvatarPhoto src={userImage} /> :
                <UserAvatar src={UserIcon} />
                }
                {profile?.academyUserId ? (
                  <AcademyBadge>
                    <AcademySVGContainer>
                      <MortarboardIcon />
                    </AcademySVGContainer>
                  </AcademyBadge>
                ) : null}
              </div>
            <span
              className="cursor-pointer"
              onClick={toggleDrawer('left', false)}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <div className="max-w-[768px] w-[100%] h-[100%] w-screen flex items-center">
          <div className="flex flex-col flex-start text-left">
            <Link to={'/dashboard'} className="flex items-center h-[56px]">
              {currentRoute === '/dashboard' ? <span className='bg-[#FF007A] w-1 h-[56px] block' /> : <span className='bg-transparent w-1 h-[56px] block' />}
              <p className="font-poppins-bold text-[16px] ml-6 text-[#3D3D3D] cursor-pointer" style={{ color: currentRoute === '/dashboard' ? '#D70064' : ''}}>
                Dashboard
              </p>
            </Link>
            <Link to={'/new-applicant'} className="flex items-center h-[56px]">
              {currentRoute === '/new-applicant' || currentRoute === '/skills' ? <span className='bg-[#FF007A] w-1 h-[56px] block' /> : <span className='bg-transparent w-1 h-[56px] block' />}
              <p className="font-poppins-bold text-[16px] ml-6 text-[#3D3D3D] cursor-pointer" style={{ color: currentRoute === '/new-applicant' || currentRoute === '/skills' ? '#D70064' : ''}}>
                Profile
              </p>
            </Link>
            <div className="flex items-center h-[56px]">
              <a href='https://www.distributed.com/privacy-policy/' className="font-poppins-bold ml-6 text-[16px] text-[#3D3D3D] cursor-pointer" target={'_blank'}>Privacy Policy</a>
            </div>
            <div className="flex items-center h-[56px]">
              <p
                className="font-poppins-bold text-[16px] text-[#3D3D3D] ml-6 cursor-pointer"
                onClick={() => setLogoutOpen(true)}
              >
                Logout
              </p>
            </div>
          </div>
        </div>
      </Drawer>
      <Logout open={logoutOpen} handleClose={() => setLogoutOpen(false)} isLoading={isLoading} onSubmit={onLogout} />
    </div>
  );
};
