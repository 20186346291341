import { Skeleton } from '@mui/material';
import styled from 'styled-components';

const LoadingQuizContainer = styled.div`
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
  max-width: 80%;
  overflow: hidden;
`;

const LoadingTitle = styled(Skeleton)`
  font-size: 28px;
  width: 100%;
`;

const LoadingQuiz = styled(Skeleton)`
  border-radius: 10px;
  min-height: 291px;
  width: 100%;
  margin-top: 20px;
`;

const AnswersSectionContainer = styled.div`
  display: flex;
  padding-top: 2rem;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
`;

const LoadingText = styled(Skeleton)`
  font-size: 2rem;
  width: 80%;
  max-width: 100%;
  border-radius: 20px;
`;

const AnswersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 22px;
  column-gap: 16px;
`;

const LoadingAnswer = styled(Skeleton)`
  border-radius: 36px;
  width: 120px;
  height: 22px !important;
`;

export {
  LoadingQuizContainer,
  LoadingTitle,
  LoadingQuiz,
  AnswersSectionContainer,
  LoadingText,
  AnswersContainer,
  LoadingAnswer,
};
