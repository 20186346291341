import { FullUserProfileModel } from "../models/fullUserProfileModel";
import { UserCvSkillsModel } from "../models/userCvSkillsModel";
import SphereApiInvoker from "./sphereApiInvoker";

export class MembersService {
  public static async GetFullProfile(userId: string) : Promise<FullUserProfileModel> {
    return await new SphereApiInvoker().Fetch(`/members/${userId}/full-profile`);
  }

  public static async UpsertCandidateCv(userId: string) {
    await new SphereApiInvoker().Post('/members/cv-search', { UserId: userId });
  }

  public static async UpdateCandidateAgreement(payload: any) {
    await new SphereApiInvoker().Put('/members/my-profile', payload);
  }

  public static async UserTestAppeal(payload: any) {
    await new SphereApiInvoker().Put('/members/my-profile', payload);
  }

  public static async UploadCv(userId: string, cv: File) : Promise<UserCvSkillsModel> {
    const formData = new FormData();
    formData.append('file', cv);    
    return await new SphereApiInvoker().PostFormData(`/members/${userId}/cv`, formData);
  }
}