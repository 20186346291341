import { ChevronLeftIcon } from 'assets';
import { Header } from 'components/UserHeader';
import { Link } from 'react-router-dom';
import Fade from './components/Fade';
import RolesForms from './components/RolesForm';
import UserProfileTag from './components/UserProfileTag';

const Skills = () => {
  return (
    <div>
      <Header />
      <div className="flex flex-col md:flex-row justify-center px-4 md:max-w-[690px] md:mx-auto mt-20 lg:mt-32 xl:mt-20 md:gap-x-4">
        <div>
          <Link to={'/new-applicant'}>
            <button className="flex items-center gap-4 md:mx-auto">
              <span className="border rounded-full border-gray-2 w-[30px] h-[30px] flex items-center justify-center">
                <img src={ChevronLeftIcon} alt="back" className="" />
              </span>
              <p className="font-poppins-extrabold text-[24px] text-primary-blue">
                Basic Information
              </p>
            </button>
          </Link>
          <div className="custom-scroll md:mt-8 flex flex-col max-h-[calc(100vh-250px)] overflow-auto relative md:pr-4">
            <Fade position="top" />
            <RolesForms />
            <Fade position="bottom" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
