
import React from 'react';

export const MortarboardIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 0C10.7188 0 10.4688 0.0625 10.25 0.15625L1.46875 3.3125C1.1875 3.40625 1 3.6875 1 4C1 4.34375 1.1875 4.625 1.46875 4.71875L3.28125 5.375C2.78125 6.1875 2.5 7.125 2.5 8.125V9C2.5 9.90625 2.15625 10.8125 1.78125 11.5312C1.59375 11.9375 1.34375 12.3438 1.09375 12.7188C1 12.8438 0.96875 13.0312 1 13.1875C1.0625 13.3438 1.1875 13.4688 1.375 13.5L3.375 14C3.5 14.0312 3.625 14 3.75 13.9375C3.875 13.875 3.9375 13.75 3.96875 13.625C4.25 12.2812 4.09375 11.0625 3.90625 10.2188C3.8125 9.78125 3.6875 9.3125 3.5 8.90625V8.125C3.5 7.1875 3.8125 6.3125 4.34375 5.59375C4.75 5.09375 5.28125 4.71875 5.90625 4.46875L10.8125 2.53125C11.0625 2.4375 11.3438 2.5625 11.4375 2.8125C11.5625 3.09375 11.4375 3.375 11.1562 3.46875L6.25 5.40625C5.875 5.5625 5.53125 5.78125 5.25 6.0625L10.25 7.875C10.4688 7.96875 10.7188 8 10.9688 8C11.25 8 11.5 7.96875 11.7188 7.875L20.5 4.71875C20.7812 4.625 21 4.34375 21 4C21 3.6875 20.7812 3.40625 20.5 3.3125L11.7188 0.15625C11.5 0.0625 11.25 0 11 0ZM5 11.75C5 12.875 7.6875 14 11 14C14.3125 14 17 12.875 17 11.75L16.5 7.21875L12.0625 8.8125C11.7188 8.9375 11.3438 9 11 9C10.625 9 10.25 8.9375 9.90625 8.8125L5.46875 7.21875L5 11.75Z" fill="white"/>
    </svg>
  );
};
