import styled from 'styled-components';

import { ElasticText } from './ElasticText';
import CheckItem from './CheckItem';
import { checkStrings } from '../constant';

const ETMissionTitle = styled.p`
  font-size: 36px;
  font-family: Poppins-ExtraBold;
  color: #ff007a;

  @media (max-width: 720px) {
    margin-top: 1rem;
    font-size: 30px;
    font-family: Poppins-ExtraBold;
  }
`;
const ETMissionDescription = styled.p`
  font-size: 36px;
  font-family: Poppins-ExtraBold;
  color: #080078;
  padding: 0 4rem;
  @media (max-width: 1400px) {
    font-size: 33px;
    padding: 0;
  }
  @media (max-width: 1024px) {
    font-size: 34px;
    padding: 0 10rem;
  }
  @media (max-width: 860px) {
    padding: 0 5rem;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    padding: 0 4rem;
  }
  @media (max-width: 618px) {
    font-size: 28px;
    padding: 0;
  }
  @media (max-width: 460px) {
    font-size: 25px;
  }
  @media (max-width: 380px) {
    font-size: 22px;
  }
`;

export const CommunityView = () => {
  return (
    <div className="signup-bg-container">
      <div style={{ textAlign: 'center' }}>
        <ETMissionTitle>Our Mission:</ETMissionTitle>
        <ETMissionDescription>
          Freelance tech careers powering<br /> enterprise businesses
        </ETMissionDescription>
      </div>

      <div>
        <div className="community-title">
          Elastic Community
        </div>
        {checkStrings.map((item, index) => {
          return <CheckItem text={item} key={index} />;
        })}
      </div>
    </div>
  );
};
