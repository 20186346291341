import { FirebaseApp, initializeApp } from "firebase/app";
import { getAnalytics,
  logEvent as logEventAnalytics,
  setUserId,
  setAnalyticsCollectionEnabled,
  CustomParams} from "firebase/analytics";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const firebaseConfig = {
  apiKey: process.env["FIREBASE_API_KEY"] ?? "AIzaSyD4AT-NPI13j4bMkdvrQeN2Gz3L5OWf5TM",
  authDomain: process.env["FIREBASE_AUTH_DOMAIN"] ?? "command-33edb.firebaseapp.com",
  projectId: process.env["FIREBASE_PROJECT_ID"] ?? "command-33edb",
  storageBucket: process.env["FIREBASE_STORAGE_BUCKET"] ?? "command-33edb.appspot.com",
  messagingSenderId: process.env["FIREBASE_MESSAGING_SENDER_ID"] ?? "454675475863",
  appId: process.env["FIREBASE_APP_ID"] ?? "1:454675475863:web:52dd7c54d85d8fdaf0a8d2",
  measurementId: process.env["FIREBASE_MEASUREMENT_ID"] ?? "G-MEHRX1B18S"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

setAnalyticsCollectionEnabled(analytics, true)

export function logEvent(eventType: EventType, customParams?: CustomParams ) : void {
  const page_title = `engineer${window.location.pathname}`;
  logEventAnalytics(analytics, eventType.toString(), {...customParams, page_title});
}

export enum EventType {
  login = "login",
  search = "search",
  sign_up = "sign_up",
  logout = "logout",
  page_view = "page_view",
  save_profile = "save_profile",
  update_profile = "update_profile",
  cv_uploaded = "cv_uploaded"
}

export function setLogin(sub: string, groups: string[]) : void {
  setUserId(analytics, sub, { global: true });
  logEvent(EventType.login);
}

export function Analytics() : JSX.Element {
  let location = useLocation();
  useEffect(() => {
    logEvent(EventType.page_view);
  }, [location]);
  return (<></>);
}
