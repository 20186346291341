import SimpleDialog from 'components/SimpleDialog';
import { LogoIcon1, CircleCloseIcon } from 'assets';
import Styled from 'pages/code-test/code-test.styles';
import { Button } from 'components';

export interface ModalProps {
  open: boolean;
  isLoading?: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}

const title = 'Logout?';

const Logout = (props: ModalProps) => {
  const { open, handleClose, onSubmit } = props;
  return (
    <SimpleDialog open={open} onClose={handleClose}>
      <Styled.DialogContainer>
        <div className='flex justify-end' ><span className='cursor-pointer' onClick={handleClose}><CircleCloseIcon /></span></div>
        <img src={LogoIcon1} alt="logo" className='w-[312px] max-w-[100%] object-contain' />
        <Styled.DialogBody>
          <div>
            <Styled.DialogTitle>{title}</Styled.DialogTitle>
            <div className='text-center text-primary-blue text-[15px] font-poppins-regular'>
              Are you sure you want to logout?
            </div>
          </div>
          <div className='mt-40'>
            <Button
              text="Yes, Logout"
              className="w-full px-2 font-poppins-bold text-[13px]"
              onClick={onSubmit}
              isLoading={props.isLoading}
            />
            <p
              className="w-full mt-4 px-2 font-poppins-regular text-light-blue text-[13px] bg-transparent cursor-pointer"
              onClick={handleClose}
            >Cancel</p>
          </div>
        </Styled.DialogBody>
      </Styled.DialogContainer>
    </SimpleDialog>
  );
};
export default Logout;
