import * as React from 'react';

export const DocxIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 117.8 126.7"
    {...props}
  >
    <g>
      <path
        d="M44.8,100.2v-2.7c0-1.1-0.2-2.3-0.6-3.4c-0.3-0.8-0.9-1.5-1.7-2c-0.7-0.5-1.6-0.7-2.5-0.7
		c-0.9,0-1.7,0.2-2.5,0.7c-0.7,0.5-1.3,1.2-1.7,2c-0.4,1.1-0.6,2.2-0.6,3.4v2.7c0,1.3,0.2,2.5,0.6,3.4c0.3,0.8,0.9,1.5,1.7,2
		c0.7,0.4,1.6,0.7,2.5,0.7c0.9,0,1.7-0.2,2.5-0.7c0.8-0.5,1.3-1.2,1.7-2C44.6,102.5,44.9,101.3,44.8,100.2z M49,97.5v2.7
		c0,2-0.4,3.7-1.1,5.1c-0.7,1.4-1.8,2.5-3.1,3.3c-1.3,0.8-2.9,1.1-4.8,1.1c-1.7,0-3.3-0.3-4.8-1.1c-1.3-0.8-2.4-1.9-3.1-3.3
		c-0.8-1.6-1.1-3.3-1.1-5.1v-2.7c0-2,0.4-3.7,1.1-5.1c0.7-1.4,1.7-2.5,3.1-3.3c1.3-0.8,3-1.2,4.8-1.2c1.9,0,3.5,0.4,4.8,1.2
		c1.3,0.8,2.4,1.9,3.1,3.3C48.7,93.8,49,95.5,49,97.5z M12.6,109.3V88.4h7.6c2.1,0,3.9,0.4,5.3,1.2c1.4,0.8,2.4,2,3.1,3.6
		c0.7,1.5,1,3.4,1,5.6c0,2.2-0.3,4.1-1,5.7c-0.7,1.6-1.7,2.8-3.1,3.6c-1.4,0.8-3.1,1.2-5.2,1.2C20.2,109.3,12.6,109.3,12.6,109.3z
		 M19.7,91.7h-3v14.2h2.9c1,0,1.8-0.1,2.5-0.4c0.7-0.3,1.3-0.7,1.7-1.3c0.5-0.6,0.8-1.3,1-2.2c0.3-1,0.4-2.1,0.4-3.1
		c0-1.6-0.2-2.9-0.6-3.9c-0.3-1-1-1.8-1.9-2.4C22,92,20.9,91.7,19.7,91.7L19.7,91.7z M55,97.6c0-1.3,0.2-2.4,0.5-3.3
		c0.3-0.8,0.9-1.6,1.6-2.1c0.7-0.5,1.6-0.7,2.5-0.7c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.4,1.4c0.4,0.6,0.6,1.3,0.6,2h4v-0.4
		c-0.1-1.9-1-3.8-2.4-5c-0.8-0.6-1.6-1.1-2.6-1.4c-1-0.3-2.1-0.5-3.2-0.5c-1.9,0-3.5,0.4-4.8,1.2c-1.3,0.8-2.3,1.9-3,3.3
		c-0.7,1.4-1,3.1-1,5.1v2.6c0,2,0.3,3.7,1,5.1c0.7,1.4,1.7,2.5,3,3.3c1.3,0.8,2.9,1.1,4.8,1.1c1.5,0,2.9-0.3,4.1-0.9
		c1.2-0.6,2.2-1.4,2.9-2.4c0.7-1,1.1-2.3,1.2-3.5v-0.4h-4c0,0.7-0.3,1.3-0.6,1.9c-0.4,0.6-0.8,1-1.4,1.3c-0.7,0.3-1.4,0.5-2.1,0.5
		c-0.9,0-1.8-0.2-2.5-0.7c-0.7-0.5-1.3-1.2-1.6-2.1c-0.4-1.1-0.6-2.2-0.5-3.3V97.6z M81.7,88.4h4.7l-6.7,10.5l6.6,10.4h-4.8
		l-4.4-7.4h-0.2l-4.5,7.4h-4.5l6.5-10.5l-6.4-10.4h4.9l4.4,7.5h0.2L81.7,88.4L81.7,88.4z"
        fill="#144FE7"
        fillRule="evenodd"
      />
      <g>
        <path
          d="M100.7,46.1v52.6c0,6.1-5,11.1-11.1,11.1v-5.5c3.1,0,5.5-2.5,5.5-5.5V46.1H84.1c-4.6,0-8.3-3.7-8.3-8.3V26.7
			H45.4c-3.1,0-5.5,2.5-5.5,5.5V82h-5.5V32.2c0-6.1,5-11.1,11.1-11.1h30.4L100.7,46.1z"
          fill="#144FE7"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
